import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import "@fortawesome/fontawesome-free/css/all.min.css";
import useApiAxios from "../../api/useApiAxios";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import ReactPaginate from "react-paginate";

const ViewDate = () => {
	document.title = "Eventease360 | View Date Details";
	const { marketId } = useParams();
	const navigate = useNavigate();
	const [myEvents, setEvents] = useState([]);

	const { getMarketById, deleteDateApi, updateDateDataApi } = useApiAxios();
	const [marketData, setMarketData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [detailsModalOpen, setDetailsModalOpen] = useState(false);
	const [dateToDelete, setDateToDelete] = useState(null);
	const [formData, setFormData] = useState({
		totalStall: "",
		price: "",
		date: "",
		market: marketId,
		_id: "",
	});
	const [detailsData, setDetailsData] = useState([]);
	const localizer = momentLocalizer(moment);

	// Pagination state
	const [currentPage, setCurrentPage] = useState(0);
	const itemsPerPage = 10; // Number of items per page

	const fetchMarketData = async () => {
		try {
			setIsLoading(true);
			const { error, data } = await getMarketById(marketId);
			if (!error) {
				setMarketData(data.data);
				const events = data.data.marketDate.map((element) => ({
					id: element._id,
					start: new Date(element.date),
					end: new Date(element.date),
					allDay: true,
					title: "Market Day",
				}));
				setEvents(events);
			} else {
				console.error("Error fetching market data:", data);
			}
		} catch (error) {
			console.error("Error fetching market data:", error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (marketId) {
			fetchMarketData();
		}
		document.title = "View Dates - Eventease360";
	}, [marketId]);

	const handleOpenModal = (date) => {
		setFormData({ ...date, market: marketId });
		setModalOpen(true);
		const backdrop = document.createElement("div");
		backdrop.classList.add("modal-backdrop", "fade", "show");
		document.body.appendChild(backdrop);
	};

	const handleCloseModal = () => {
		setModalOpen(false);
		const backdrop = document.querySelector(".modal-backdrop");
		if (backdrop) {
			document.body.removeChild(backdrop);
		}
	};

	const handleOpenDeleteModal = (dateId) => {
		setDateToDelete(dateId);
		setDeleteModalOpen(true);
		const backdrop = document.createElement("div");
		backdrop.classList.add("modal-backdrop", "fade", "show");
		document.body.appendChild(backdrop);
	};

	const handleCloseDeleteModal = () => {
		setDeleteModalOpen(false);
		setDateToDelete(null);
		const backdrop = document.querySelector(".modal-backdrop");
		if (backdrop) {
			document.body.removeChild(backdrop);
		}
	};

	const handleDeleteDate = async () => {
		if (dateToDelete) {
			await deleteDateApi(dateToDelete);
			await fetchMarketData();
			handleCloseDeleteModal();
		}
	};

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = async () => {
		await updateDateDataApi(formData._id, formData);
		handleCloseModal();
		const { error, data } = await getMarketById(marketId);
		if (!error) {
			setMarketData(data.data);
		} else {
			console.error("Error updating market data:", data);
		}
	};

	const handleSelectSlot = useCallback(
		({ start, end }) => {
			if (!moment(start).isSame(moment(end), "day")) {
				alert("Please select only a single date.");
				return;
			}

			const eventExists = myEvents.some((event) => moment(event.start).isSame(moment(start), "day"));

			if (eventExists) {
				alert("There is already an event on this date.");
				return;
			}

			const title = window.prompt("New Event name");
			if (title) {
				setEvents((prev) => [...prev, { start, end, title, allDay: true }]);
			}
		},
		[myEvents]
	);

	const handleViewDetails = (dateId) => {
		navigate(`/viewdatedetail/${dateId}`);
	};

	// Pagination logic
	const indexOfLastItem = (currentPage + 1) * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = marketData ? marketData.marketDate.slice(indexOfFirstItem, indexOfLastItem) : [];

	const totalPages = Math.ceil((marketData?.marketDate.length || 0) / itemsPerPage);

	const handlePageChange = (selectedPage) => {
		setCurrentPage(selectedPage.selected);
	};

	return (
		<>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Market Name: {marketData?.marketContent?.mainTitle}</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<Link to="/marketdate">Date & Content</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<Link to="#">View Date</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="page-inner">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								{marketData ? (
									<>
										<div style={{ height: "80vh" }}>
											<Calendar
												localizer={localizer}
												defaultDate={new Date()}
												defaultView={Views.MONTH}
												events={myEvents}
												components={{
													toolbar: CustomToolbar,
												}}
												selectable
												onSelectSlot={handleSelectSlot}
											/>
										</div>

										<div className="card-title my-4">Date List</div>
										<table className="table table-hover">
											<thead>
												<tr>
													<th>Date</th>
													<th>Total Stall</th>
													<th>Actions</th>
												</tr>
											</thead>
											<tbody>
												{currentItems.map((date) => (
													<tr key={date._id}>
														<td>{moment(date.date).format("MMMM Do YYYY")}</td>
														<td>{date.totalStall}</td>
														<td>
															<div className="form-button-action">
																<button
																	type="button"
																	className="btn btn-primary btn-sm mr-1"
																	onClick={() => handleOpenModal(date)}
																	data-tooltip-id="edit-tooltip"
																	data-tooltip-content="Edit"
																>
																	<i className="fas fa-edit" />
																</button>

																<button
																	type="button"
																	className="btn btn-danger btn-sm"
																	onClick={() => handleOpenDeleteModal(date._id)}
																	data-tooltip-id="delete-tooltip"
																	data-tooltip-content="Delete"
																>
																	<i className="fas fa-trash" />
																</button>
																<Tooltip id="edit-tooltip" />
																<Tooltip id="delete-tooltip" />
															</div>
														</td>
													</tr>
												))}
											</tbody>
										</table>
										<div className="d-flex justify-content-between align-items-center pagination-container">
											<ReactPaginate
												previousLabel={"Previous"}
												nextLabel={"Next"}
												breakLabel={"..."}
												pageCount={totalPages}
												marginPagesDisplayed={2}
												pageRangeDisplayed={5}
												onPageChange={handlePageChange}
												containerClassName={"pagination m-0"}
												pageClassName={"page-item"}
												pageLinkClassName={"page-link"}
												previousClassName={"page-item"}
												previousLinkClassName={"page-link"}
												nextClassName={"page-item"}
												nextLinkClassName={"page-link"}
												breakClassName={"page-item"}
												breakLinkClassName={"page-link"}
												activeClassName={"active"}
											/>
										</div>
									</>
								) : (
									<p>No data available</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			{modalOpen && (
				<div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Edit Date</h5>
								<button type="button" className="close" onClick={handleCloseModal}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<h5>Market: {formData.market}</h5>
								<form>
									<div className="form-group">
										<label>Total Stall</label>
										<input type="text" className="form-control" name="totalStall" value={formData.totalStall} onChange={handleChange} />
									</div>
									<div className="form-group">
										<label>Date</label>
										<input type="text" className="form-control" name="date" value={moment(formData.date).format("MMMM Do YYYY")} disabled />
									</div>
								</form>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
									Close
								</button>
								<button type="button" className="btn btn-primary" onClick={handleSubmit}>
									Save changes
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{deleteModalOpen && (
				<div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Delete Date</h5>
								<button type="button" className="close" onClick={handleCloseDeleteModal}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p>Are you sure you want to delete this date?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-danger" onClick={handleDeleteDate}>
									Yes
								</button>
								<button type="button" className="btn btn-secondary" onClick={handleCloseDeleteModal}>
									No
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const CustomToolbar = (toolbar) => {
	return (
		<div className="rbc-toolbar">
			<span className="rbc-toolbar-label">{`${toolbar.label}`}</span>
			<span className="rbc-btn-group">
				<button onClick={() => toolbar.onNavigate("PREV")}>Back</button>
				<button onClick={() => toolbar.onNavigate("TODAY")}>Today</button>
				<button onClick={() => toolbar.onNavigate("NEXT")}>Next</button>
			</span>
		</div>
	);
};

export default ViewDate;
