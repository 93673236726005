// import React from "react";
// import PropTypes from "prop-types";
// import { Button, Modal, Card, Container, Row, Col } from "react-bootstrap";

// const VendorMapModal = ({ vendor, isOpen, onClose }) => {
//   if (!isOpen) return null;

//   const {
//     businessName,
//     businessDescription,
//     contactPerson,
//     contactNumber,
//     addProductMessage,
//     productImage1,
//     productImage2,
//     productImage3,
//     foodRegistrationImage,
//     liquorLicenseImage,
//     otherLicenseImage,
//     publicLiabilityInsurance,
//     instagram,
//     facebook,
//     twitter,
//     tikTok,
//     insuranceExpiryDate,
//     expireLicenseDate,
//     liquorLicenseExpiryDate,
//     otherLicenseExpiryDate,
//   } = vendor || {};

//   return (
//     <Modal
//       show={isOpen}
//       onHide={onClose}
//       size="lg"
//       aria-labelledby="profileDetailsModalLabel"
//       centered
//     >
//       <Modal.Header>
//         <Modal.Title id="profileDetailsModalLabel">
//           {businessName || "Vendor Details"}
//         </Modal.Title>
//         <button
//           type="button"
//           className="close"
//           onClick={onClose}
//         >
//           <span aria-hidden="true">&times;</span>
//         </button>
//       </Modal.Header>
//       <Modal.Body>
//         <Container>
//           <Row>
//             <Col>
//               {businessDescription && <p><strong>Description:</strong> {businessDescription}</p>}
//               {contactPerson && <p><strong>Contact Person:</strong> {contactPerson}</p>}
//               {contactNumber && <p><strong>Contact Number:</strong> {contactNumber}</p>}
//               {addProductMessage && <p><strong>Additional Product Message:</strong> {addProductMessage}</p>}
//               {/* Assuming subCategory and category are arrays */}
//               {vendor?.subCategory?.length > 0 && <p><strong>Category:</strong> {vendor.subCategory[0]?.name}</p>}
//               {vendor?.category?.length > 0 && <p><strong>SubCategory:</strong> {vendor.category[0]?.name}</p>}
//             </Col>
//           </Row>
//           <Row className="mb-3">
//             <Col>
//               <Card>
//                 <Card.Body>
//                   <Card.Title>Images</Card.Title>
//                   <Row>
//                     {productImage1 && (
//                       <Col xs={6} md={4} lg={3} className="mb-3">
//                         <Card.Img
//                           variant="top"
//                           src={`${process.env.REACT_APP_IMG_URL}/${productImage1}`}
//                           alt="Product Image 1"
//                           style={styles.image}
//                         />
//                         <Card.Text style={styles.caption}>Product Image 1</Card.Text>
//                       </Col>
//                     )}
//                     {productImage2 && (
//                       <Col xs={6} md={4} lg={3} className="mb-3">
//                         <Card.Img
//                           variant="top"
//                           src={`${process.env.REACT_APP_IMG_URL}/${productImage2}`}
//                           alt="Product Image 2"
//                           style={styles.image}
//                         />
//                         <Card.Text style={styles.caption}>Product Image 2</Card.Text>
//                       </Col>
//                     )}
//                     {productImage3 && (
//                       <Col xs={6} md={4} lg={3} className="mb-3">
//                         <Card.Img
//                           variant="top"
//                           src={`${process.env.REACT_APP_IMG_URL}/${productImage3}`}
//                           alt="Product Image 3"
//                           style={styles.image}
//                         />
//                         <Card.Text style={styles.caption}>Product Image 3</Card.Text>
//                       </Col>
//                     )}
//                     {foodRegistrationImage && (
//                       <Col xs={6} md={4} lg={3} className="mb-3">
//                         <Card.Img
//                           variant="top"
//                           src={`${process.env.REACT_APP_IMG_URL}/${foodRegistrationImage}`}
//                           alt="Food Registration Image"
//                           style={styles.image}
//                         />
//                         <Card.Text style={styles.caption}>Food Registration Image</Card.Text>
//                       </Col>
//                     )}
//                     {liquorLicenseImage && (
//                       <Col xs={6} md={4} lg={3} className="mb-3">
//                         <Card.Img
//                           variant="top"
//                           src={`${process.env.REACT_APP_IMG_URL}/${liquorLicenseImage}`}
//                           alt="Liquor License Image"
//                           style={styles.image}
//                         />
//                         <Card.Text style={styles.caption}>Liquor License Image</Card.Text>
//                       </Col>
//                     )}
//                     {otherLicenseImage && (
//                       <Col xs={6} md={4} lg={3} className="mb-3">
//                         <Card.Img
//                           variant="top"
//                           src={`${process.env.REACT_APP_IMG_URL}/${otherLicenseImage}`}
//                           alt="Other License Image"
//                           style={styles.image}
//                         />
//                         <Card.Text style={styles.caption}>Other License Image</Card.Text>
//                       </Col>
//                     )}
//                     {publicLiabilityInsurance && (
//                       <Col xs={6} md={4} lg={3} className="mb-3">
//                         <Card.Img
//                           variant="top"
//                           src={`${process.env.REACT_APP_IMG_URL}/${publicLiabilityInsurance}`}
//                           alt="Public Liability Insurance"
//                           style={styles.image}
//                         />
//                         <Card.Text style={styles.caption}>Public Liability Insurance</Card.Text>
//                       </Col>
//                     )}
//                   </Row>
//                 </Card.Body>
//               </Card>
//             </Col>
//           </Row>
//           <Row>
//             <Col>
//               {instagram && (
//                 <p><strong>Instagram:</strong> <a href={instagram} target="_blank" rel="noopener noreferrer" style={styles.link}>{instagram}</a></p>
//               )}
//               {facebook && (
//                 <p><strong>Facebook:</strong> <a href={facebook} target="_blank" rel="noopener noreferrer" style={styles.link}>{facebook}</a></p>
//               )}
//               {twitter && (
//                 <p><strong>Twitter:</strong> <a href={twitter} target="_blank" rel="noopener noreferrer" style={styles.link}>{twitter}</a></p>
//               )}
//               {tikTok && (
//                 <p><strong>TikTok:</strong> <a href={tikTok} target="_blank" rel="noopener noreferrer" style={styles.link}>{tikTok}</a></p>
//               )}
//               {insuranceExpiryDate && (
//                 <p><strong>Insurance Expiry Date:</strong> {new Date(insuranceExpiryDate).toLocaleDateString()}</p>
//               )}
//               {expireLicenseDate && (
//                 <p><strong>License Expiry Date:</strong> {new Date(expireLicenseDate).toLocaleDateString()}</p>
//               )}
//               {liquorLicenseExpiryDate && (
//                 <p><strong>Liquor License Expiry Date:</strong> {new Date(liquorLicenseExpiryDate).toLocaleDateString()}</p>
//               )}
//               {otherLicenseExpiryDate && (
//                 <p><strong>Other License Expiry Date:</strong> {new Date(otherLicenseExpiryDate).toLocaleDateString()}</p>
//               )}
//             </Col>
//           </Row>
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={onClose}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// const styles = {
//   image: {
//     width: '100%',
//     height: 'auto',
//     borderRadius: '0.5rem',
//   },
//   caption: {
//     textAlign: 'center',
//     marginTop: '0.5rem',
//     fontSize: '0.875rem',
//     color: '#6c757d',
//   },
//   link: {
//     color: '#007bff',
//     textDecoration: 'none',
//   }
// };

// VendorMapModal.propTypes = {
//   vendor: PropTypes.shape({
//     businessName: PropTypes.string,
//     businessDescription: PropTypes.string,
//     contactPerson: PropTypes.string,
//     contactNumber: PropTypes.string,
//     addProductMessage: PropTypes.string,
//     productImage1: PropTypes.string,
//     productImage2: PropTypes.string,
//     productImage3: PropTypes.string,
//     foodRegistrationImage: PropTypes.string,
//     liquorLicenseImage: PropTypes.string,
//     otherLicenseImage: PropTypes.string,
//     publicLiabilityInsurance: PropTypes.string,
//     instagram: PropTypes.string,
//     facebook: PropTypes.string,
//     twitter: PropTypes.string,
//     tikTok: PropTypes.string,
//     insuranceExpiryDate: PropTypes.string,
//     expireLicenseDate: PropTypes.string,
//     liquorLicenseExpiryDate: PropTypes.string,
//     otherLicenseExpiryDate: PropTypes.string,
//     subCategory: PropTypes.arrayOf(PropTypes.shape({
//       name: PropTypes.string
//     })),
//     category: PropTypes.arrayOf(PropTypes.shape({
//       name: PropTypes.string
//     }))
//   }),
//   isOpen: PropTypes.bool.isRequired,
//   onClose: PropTypes.func.isRequired,
// };

// export default VendorMapModal;



import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, Card, Container, Row, Col } from "react-bootstrap";

const isValidValue = (value) => value !== null && value !== undefined && value !== "" && value !== "removed";

const VendorMapModal = ({ vendor, isOpen, onClose }) => {
  if (!isOpen) return null;

  const {
    businessName,
    businessDescription,
    contactPerson,
    contactNumber,
    addProductMessage,
    productImage1,
    productImage2,
    productImage3,
    foodRegistrationImage,
    liquorLicenseImage,
    otherLicenseImage,
    publicLiabilityInsurance,
    instagram,
    facebook,
    twitter,
    tikTok,
    insuranceExpiryDate,
    expireLicenseDate,
    liquorLicenseExpiryDate,
    otherLicenseExpiryDate,
    subCategory,
    category,
  } = vendor || {};

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      size="lg"
      aria-labelledby="profileDetailsModalLabel"
      centered
    >
      <Modal.Header>
        <Modal.Title id="profileDetailsModalLabel">
          {businessName || "Vendor Details"}
        </Modal.Title>
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              {isValidValue(businessDescription) && (
                <p><strong>Description:</strong> {businessDescription}</p>
              )}
              {isValidValue(contactPerson) && (
                <p><strong>Contact Person:</strong> {contactPerson}</p>
              )}
              {isValidValue(contactNumber) && (
                <p><strong>Contact Number:</strong> {contactNumber}</p>
              )}
              {isValidValue(addProductMessage) && (
                <p><strong>Additional Product Message:</strong> {addProductMessage}</p>
              )}
              {subCategory?.length > 0 && isValidValue(subCategory[0]?.name) && (
                <p><strong>SubCategory:</strong> {subCategory[0].name}</p>
              )}
              {category?.length > 0 && isValidValue(category[0]?.name) && (
                <p><strong>Category:</strong> {category[0].name}</p>
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Images</Card.Title>
                  <Row>
                    {isValidValue(productImage1) && (
                      <Col xs={6} md={4} lg={3} className="mb-3">
                        <Card.Img
                          variant="top"
                          src={`${process.env.REACT_APP_IMG_URL}/${productImage1}`}
                          alt="Product Image 1"
                          style={styles.image}
                        />
                        <Card.Text style={styles.caption}>Product Image 1</Card.Text>
                      </Col>
                    )}
                    {isValidValue(productImage2) && (
                      <Col xs={6} md={4} lg={3} className="mb-3">
                        <Card.Img
                          variant="top"
                          src={`${process.env.REACT_APP_IMG_URL}/${productImage2}`}
                          alt="Product Image 2"
                          style={styles.image}
                        />
                        <Card.Text style={styles.caption}>Product Image 2</Card.Text>
                      </Col>
                    )}
                    {isValidValue(productImage3) && (
                      <Col xs={6} md={4} lg={3} className="mb-3">
                        <Card.Img
                          variant="top"
                          src={`${process.env.REACT_APP_IMG_URL}/${productImage3}`}
                          alt="Product Image 3"
                          style={styles.image}
                        />
                        <Card.Text style={styles.caption}>Product Image 3</Card.Text>
                      </Col>
                    )}
                    {isValidValue(foodRegistrationImage) && (
                      <Col xs={6} md={4} lg={3} className="mb-3">
                        <Card.Img
                          variant="top"
                          src={`${process.env.REACT_APP_IMG_URL}/${foodRegistrationImage}`}
                          alt="Food Registration Image"
                          style={styles.image}
                        />
                        <Card.Text style={styles.caption}>Food Registration Image</Card.Text>
                      </Col>
                    )}
                    {isValidValue(liquorLicenseImage) && (
                      <Col xs={6} md={4} lg={3} className="mb-3">
                        <Card.Img
                          variant="top"
                          src={`${process.env.REACT_APP_IMG_URL}/${liquorLicenseImage}`}
                          alt="Liquor License Image"
                          style={styles.image}
                        />
                        <Card.Text style={styles.caption}>Liquor License Image</Card.Text>
                      </Col>
                    )}
                    {isValidValue(otherLicenseImage) && (
                      <Col xs={6} md={4} lg={3} className="mb-3">
                        <Card.Img
                          variant="top"
                          src={`${process.env.REACT_APP_IMG_URL}/${otherLicenseImage}`}
                          alt="Other License Image"
                          style={styles.image}
                        />
                        <Card.Text style={styles.caption}>Other License Image</Card.Text>
                      </Col>
                    )}
                    {isValidValue(publicLiabilityInsurance) && (
                      <Col xs={6} md={4} lg={3} className="mb-3">
                        <Card.Img
                          variant="top"
                          src={`${process.env.REACT_APP_IMG_URL}/${publicLiabilityInsurance}`}
                          alt="Public Liability Insurance"
                          style={styles.image}
                        />
                        <Card.Text style={styles.caption}>Public Liability Insurance</Card.Text>
                      </Col>
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              {isValidValue(instagram) && (
                <p>
                  <strong>Instagram:</strong> <a href={instagram} target="_blank" rel="noopener noreferrer" style={styles.link}>{instagram}</a>
                </p>
              )}
              {isValidValue(facebook) && (
                <p>
                  <strong>Facebook:</strong> <a href={facebook} target="_blank" rel="noopener noreferrer" style={styles.link}>{facebook}</a>
                </p>
              )}
              {isValidValue(twitter) && (
                <p>
                  <strong>Twitter:</strong> <a href={twitter} target="_blank" rel="noopener noreferrer" style={styles.link}>{twitter}</a>
                </p>
              )}
              {isValidValue(tikTok) && (
                <p>
                  <strong>TikTok:</strong> <a href={tikTok} target="_blank" rel="noopener noreferrer" style={styles.link}>{tikTok}</a>
                </p>
              )}
              {isValidValue(insuranceExpiryDate) && (
                <p><strong>Insurance Expiry Date:</strong> {new Date(insuranceExpiryDate).toLocaleDateString()}</p>
              )}
              {isValidValue(expireLicenseDate) && (
                <p><strong>License Expiry Date:</strong> {new Date(expireLicenseDate).toLocaleDateString()}</p>
              )}
              {isValidValue(liquorLicenseExpiryDate) && (
                <p><strong>Liquor License Expiry Date:</strong> {new Date(liquorLicenseExpiryDate).toLocaleDateString()}</p>
              )}
              {isValidValue(otherLicenseExpiryDate) && (
                <p><strong>Other License Expiry Date:</strong> {new Date(otherLicenseExpiryDate).toLocaleDateString()}</p>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const styles = {
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '0.5rem',
  },
  caption: {
    textAlign: 'center',
    marginTop: '0.5rem',
    fontSize: '0.875rem',
    color: '#6c757d',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
  }
};

VendorMapModal.propTypes = {
  vendor: PropTypes.shape({
    businessName: PropTypes.string,
    businessDescription: PropTypes.string,
    contactPerson: PropTypes.string,
    contactNumber: PropTypes.string,
    addProductMessage: PropTypes.string,
    productImage1: PropTypes.string,
    productImage2: PropTypes.string,
    productImage3: PropTypes.string,
    foodRegistrationImage: PropTypes.string,
    liquorLicenseImage: PropTypes.string,
    otherLicenseImage: PropTypes.string,
    publicLiabilityInsurance: PropTypes.string,
    instagram: PropTypes.string,
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    tikTok: PropTypes.string,
    insuranceExpiryDate: PropTypes.string,
    expireLicenseDate: PropTypes.string,
    liquorLicenseExpiryDate: PropTypes.string,
    otherLicenseExpiryDate: PropTypes.string,
    subCategory: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string
    })),
    category: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string
    }))
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default VendorMapModal;
