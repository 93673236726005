import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link } from "react-router-dom";
import "./ChangeVendorDate.css";

const ChangeVendorDate = () => {
  const [checkedDates, setCheckedDates] = useState({});
  const [initialCheckedDates, setInitialCheckedDates] = useState({});
  const [selectedMarket, setSelectedMarket] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [marketData, setMarketData] = useState([]);
  const [marketNames, setMarketNames] = useState([]);
  const [reason, setReason] = useState("");

  const { getAllVendorDate, changeVendorDateApi } = useApiAxios();

  const fetchMarketData = async () => {
    try {
      const { error, data } = await getAllVendorDate();
      if (!error) {
        setMarketData(data.data);
        setMarketNames(
          data.data.map((market) => ({
            id: market.marketdetails._id,
            name: market.marketdetails.name
          }))
        );
      } else {
        console.error("Error fetching market data:", data);
      }
    } catch (error) {
      console.error("Error fetching market data:", error);
    }
  };

  useEffect(() => {
    document.title = "Change Vendor Date - Eventease360";
    fetchMarketData();
  }, []);

  useEffect(() => {
    if (marketData.length > 0) {
      const initialCheckedDates = marketData.reduce((acc, market) => {
        market.marketDate.forEach((dateItem) => {
          acc[dateItem._id] = dateItem.applied;
        });
        return acc;
      }, {});
      setCheckedDates(initialCheckedDates);
      setInitialCheckedDates(initialCheckedDates);
    }
  }, [marketData]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const getPaymentStatusClass = (status) => {
    switch (status) {
      case "paymentDone":
        return "green-underline"; // Green underline for 'paymentDone'
      case "applied":
        return "yellow-underline"; // Yellow underline for 'applied'
      case "notApplied":
        return "blue-underline"; // Blue underline for 'notApplied'
      case "approved":
        return "peach-underline"; // Peach underline for 'approved'
      case "removeRequest":
        return "red-underline"; // Red underline for 'removeRequest'
      case "reject":
        return "rejected-underline";
      case "InvoiceSend":
        return "invoice-underline";
      case "present":
        return "present-underline";
      default:
        return ""; // No underline for undefined status
        
    }
  };

  const handleCheckboxChange = (dateId) => {
    setCheckedDates((prevState) => ({
      ...prevState,
      [dateId]: !prevState[dateId]
    }));
  };

  const handleMarketChange = (event) => {
    setSelectedMarket(event.target.value);
    setSelectedYear("");
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleSave = async () => {
    const addMarketDateIds = [];
    const removeVendorDateIds = [];

    Object.keys(checkedDates).forEach((dateId) => {
      const isChecked = checkedDates[dateId];
      const wasChecked = initialCheckedDates[dateId];

      if (isChecked && !wasChecked) {
        addMarketDateIds.push(dateId);
      } else if (!isChecked && wasChecked) {
        removeVendorDateIds.push(dateId);
      }
    });

    if (
      addMarketDateIds.length > 0 ||
      removeVendorDateIds.length > 0 ||
      reason.trim()
    ) {
      try {
        const { error, data } = await changeVendorDateApi(
          addMarketDateIds,
          removeVendorDateIds,
          reason
        );
        if (!error) {
          console.log("API Response:", data);
        } else {
          console.error("Error:", data.message);
        }
      } catch (error) {
        alert("An unexpected error occurred while updating vendor dates.");
      }
    } else {
      alert("No changes were made.");
    }

    setInitialCheckedDates({ ...checkedDates });
  };

  const filteredMarket = marketData.find(
    (market) => market.marketdetails._id === selectedMarket
  );

  const filterDatesByYear = (dates) => {
    if (!selectedYear) return [];
    return dates.filter((dateItem) => {
      const date = new Date(dateItem.date);
      return date.getFullYear() === parseInt(selectedYear);
    });
  };

  const availableYears = marketData
    .reduce((years, market) => {
      market.marketDate.forEach((dateItem) => {
        const year = new Date(dateItem.date).getFullYear();
        if (!years.includes(year)) years.push(year);
      });
      return years;
    }, [])
    .sort();

  return (
    <>
      <div className="panel-header">
        <div className="page-inner pb-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Market Dates</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    {" "}
                    <i className="flaticon-home" />{" "}
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="#">Market Dates</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="page-inner mt--5">
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-3">
                    <select
                      className="custom-select"
                      value={selectedMarket}
                      onChange={handleMarketChange}
                    >
                      <option value="">Select a Market</option>
                      {marketNames.map((market) => (
                        <option key={market.id} value={market.id}>
                          {market.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-3">
                    <select
                      className="custom-select"
                      value={selectedYear}
                      onChange={handleYearChange}
                    >
                      <option value="">Select a Year</option>
                      {availableYears.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* <div className="col-md-10 col-sm-8 mb-4">
										<label className="w-100">&nbsp;</label>
										<span className="py-2 px-3 mr-2 blue_bg text-white">Not Applied</span>
										<span className="py-2 px-3 mr-2 orange_bg text-dark">Applied</span>
										<span className="py-2 px-3 mr-2 peach_bg text-white">Approved</span>
										<span className="py-2 px-3 mr-2 rejected-bg text-white">Rejected</span>
										<span className="py-2 px-3 mr-2 invoice-bg text-white">Invoice Sent</span>
										<span className="py-2 px-3 mb-2 mr-2 green_bg text-white">Payment Done</span>
										<span className="py-2 px-3 mr-2 red_bg text-white">Remove Request</span>
										<span className="py-2 px-3 mr-2 present_bg text-white">Present</span>
									</div> */}
                  <div className="col-md-10 col-sm-8 mb-4">
                    <label className="w-100">&nbsp;</label>
                    <div className="status-container">
                      <span className="py-2 px-3 mr-2 blue_bg text-white">
                        Not Applied
                      </span>
                      <span className="py-2 px-3 mr-2 orange_bg text-dark">
                        Applied
                      </span>
                      <span className="py-2 px-3 mr-2 peach_bg text-white">
                        Approved
                      </span>
                      <span className="py-2 px-3 mr-2 rejected-bg text-white">
                        Rejected
                      </span>
                      <span className="py-2 px-3 mr-2 invoice-bg text-white">
                        Invoice Sent
                      </span>
                      <span className="py-2 px-3 mb-2 mr-2 green_bg text-white">
                        Payment Done
                      </span>
                      <span className="py-2 px-3 mr-2 red_bg text-white">
                        Remove Request
                      </span>
                      <span className="py-2 px-3 mr-2 present_bg text-white">
                        Present
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body month_scroll">
                        {selectedYear && selectedMarket && filteredMarket ? (
                          months.map((month, monthIndex) => {
                            const monthDates = filterDatesByYear(
                              filteredMarket.marketDate
                            ).filter((dateItem) => {
                              const date = new Date(dateItem.date);
                              return date.getMonth() === monthIndex;
                            });

                            return (
                              <div key={monthIndex} className="card mb-3">
                                <div className="card-body">
                                  <h5>{month}</h5>
                                  {monthDates.length > 0 ? (
                                    monthDates.map((dateItem) => {
                                      const date = new Date(dateItem.date);
                                      const status =
                                        filteredMarket.marketdetails.result.find(
                                          (result) =>
                                            result.dateId === dateItem._id
                                        )?.status || "notApplied"; // Get the payment status
                                      const isChecked =
                                        checkedDates[dateItem._id] !== undefined
                                          ? checkedDates[dateItem._id]
                                          : false;
                                      const statusClass =
                                        getPaymentStatusClass(status); // Get the status class

                                      // Disable the checkbox if the status is "present"
                                      const isDisabled = status === "present";

                                      return (
                                        <div
                                          key={dateItem._id}
                                          className="custom-control custom-checkbox"
                                        >
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={`month-${monthIndex}-${dateItem._id}`}
                                            checked={isChecked}
                                            onChange={() =>
                                              handleCheckboxChange(dateItem._id)
                                            }
                                            disabled={isDisabled} // Disable the checkbox if the status is "present"
                                          />
                                          <label
                                            className={`custom-control-label ${statusClass} ${
                                              isChecked ? "" : "unchecked"
                                            }`}
                                            htmlFor={`month-${monthIndex}-${dateItem._id}`}
                                          >
                                            {date.getDate()} ({month}) -{" "}
                                            {status.charAt(0).toUpperCase() +
                                              status.slice(1)}
                                          </label>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <p>Date not applied in this month</p> // Display message if no dates are found
                                  )}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p>Select a market and year to view dates.</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group mt-3">
                      <label htmlFor="reason">Reason for Change</label>
                      <textarea
                        id="reason"
                        className="form-control"
                        value={reason}
                        onChange={handleReasonChange}
                        rows="4"
                      />
                    </div>

                    <div className="mt-4">
                      <button onClick={handleSave} className="btn btn-primary">
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeVendorDate;
