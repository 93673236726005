import React, { useContext, useEffect, useRef, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link, useNavigate, useParams } from "react-router-dom";

import * as yup from "yup";
import { Context } from "../../context/Context";

import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Loader from "../loader/Loader";
import Modal from "react-modal";
import MapModal from "../Modal/imageModal";
import "./DatePickerStyles.css";
import ReactPaginate from "react-paginate"; // Import ReactPaginate

Modal.setAppElement("#root");

const validationSchema = yup.object().shape({
	totalStall: yup
		.number()
		.required("Total stall is required")
		.positive("Total stall must be a positive number")
		.typeError("Total stall must be a valid number")
		.integer("Total stall must be an integer"),

	date: yup.array().required("Date is required"), // Validate date is after today
	market: yup.string().required("Market is required"),
});

const MarketDate = () => {
	document.title = "Eventease360 | Date & Content";
	const { getAllMarket, postModalDate, getPrice, getPaymentUrl, searchMarketDateApi } = useApiAxios();
	const [adminData, setAdminData] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [priceData, setPriceDate] = useState({});
	const [loading, setLoading] = useState(false);
	const [imageLoaded, setImageLoaded] = useState(false);
	const canvasRef = useRef(null);
	const [isMapModalOpen, setIsMapModalOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState(""); // State for search query

	const [currentPage, setCurrentPage] = useState(0);
	const [marketPerPage, setMarketsPerPage] = useState(10);
	const marketPerPageOptions = [10, 20, 50, 100];
	const [total, setTotal] = useState(0);
	let { id } = useParams();
	const [formData, setFormData] = useState({
		totalStall: "",
		date: [],
		market: "",
	});

	const [formErrors, setFormErrors] = useState({
		totalStall: "",
		date: [],
		market: "",
	});
	const makePaymentHandler = async (market) => {
		const { data, error } = await getPaymentUrl({
			priceId: priceData.id,
			market,
		});
		if (!error) {
			window.location.href = data.data;
		}
	};

	const { trial } = useContext(Context);

	const navigate = useNavigate();
	const fun = async () => {
		getAllMarket()
			.then((response) => {
				setAdminData(response.data.data);
				setTotal(response.data.total);

				console.warn("::::::::::::::::::::::::::", response.data.data);
			})
			.catch((error) => {
				setAdminData([]);
				// Handle error, e.g., show an error message
			});
		const { data, error } = await getPrice();
		setPriceDate(data.data);
	};

	// const marketDates = marketDateData[0]?.marketDate?.map(date => new Date(date.date));
	// const marketDates = (marketDateData && marketDateData.marketDate)
	// ? marketDateData.marketDate.map(date => new Date(date.date))
	// : [];
	// console.warn("data which are present before", marketDates)

	useEffect(() => {
		fun();
	}, []);

	const handleClick = (id, marketName) => {
		// Generate the HTML content as a string
		const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>${marketName}</title>
        <style>
          body {
            margin: 0;
            padding: 0;
            overflow: hidden;
          }
        </style>
      </head>
      <body>
        <iframe
          src="${process.env.REACT_APP_LANDING_PAGE_URL ? process.env.REACT_APP_LANDING_PAGE_URL : `http://localhost:3001`}?id=${id}"
          style="width: 100vw; height: 100vh; border: none"
        ></iframe>
      </body>
    </html>
    `;

		// Create a Blob object from the HTML content
		const blob = new Blob([htmlContent], { type: "text/html" });

		// Create a URL for the Blob object
		const url = URL.createObjectURL(blob);

		// Create a temporary anchor element
		const a = document.createElement("a");
		a.href = url;
		a.download = "index.html";

		// Programmatically click the anchor element to trigger the download
		document.body.appendChild(a);
		a.click();

		// Clean up by removing the temporary anchor element and URL object
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	};
	const handleOpenModal = (marketId) => {
		const marketDetails = adminData.find((market) => market._id === marketId);
		// const marketDates = marketDetails.marketDate;
		const marketDates = marketDetails.marketDate.map((date) => new Date(date.date)); // Ensure dates are in Date object format

		console.warn("eeeeeeeeeeeeeeeeeeeee", marketDetails);

		console.warn("PPPPPPPPPPPPPPPPPPPPPPP", marketDates);
		setFormData({
			totalStall: marketDetails.totalstall || "",
			date: marketDates,
			market: marketId,
		});
		setModalOpen(true);
		console.warn("Form data after setting:", {
			totalStall: marketDetails.totalstall || "",
			date: marketDetails.dates,
			market: marketId,
		});
	};

	const handleCloseModal = async () => {
		setModalOpen(false);
		const backdrop = document.querySelector(".modal-backdrop");
		if (backdrop) {
			document.body.removeChild(backdrop);
		}

		try {
			const response = await getAllMarket();
			setAdminData(response.data.data);
		} catch (error) {
			console.error("Error fetching market data:", error);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
		setFormErrors({ ...formErrors, [name]: "" });
	};

	const handleViewDate = (marketId) => {
		navigate(`/viewDate/${marketId}`);
	};

	const handleSubmit = async () => {
		setLoading(true);

		try {
			// Validate the form data
			await validationSchema.validate(formData, { abortEarly: false });

			// Submit the form data
			const response = await postModalDate(formData);
			console.log("Response:", response.data);

			// Close the modal
			setModalOpen(false);
			const backdrop = document.querySelector(".modal-backdrop");
			if (backdrop) {
				document.body.removeChild(backdrop);
			}

			// Fetch and update market data
			try {
				const marketResponse = await getAllMarket();
				setAdminData(marketResponse.data.data);
			} catch (error) {
				console.error("Error fetching market data:", error);
			}
		} catch (error) {
			if (error.name === "ValidationError") {
				// Handle validation errors
				const newErrors = {};
				error.inner.forEach((err) => {
					newErrors[err.path] = err.message;
				});
				setFormErrors(newErrors);
			}
			console.error("Error:", error);
		}

		setLoading(false);
	};

	const handleAddDate = (user) => {
		// Handle adding date action here
		console.log("Add Date action for user: ", user);
	};

	const handleAddContent = (user) => {
		const { _id, marketContent } = user;

		if (marketContent) {
			navigate(`/editLandingPageDetails/${_id}`);
		} else {
			navigate(`/addLandingPageDetail/${_id}`);
		}
	};

	const handleCloseMapModal = () => {
		setIsMapModalOpen(false);
	};

	const handleSearch = async (event) => {
		try {
			const query = event.target.value;
			setSearchQuery(query);
			const response = await searchMarketDateApi(query);
			console.log("Searched data:", response.data.data);
			setAdminData(response.data.data);
		} catch (error) {
			console.error("Error searching:", error);
		}
	};

	const indexOfLastVendor = (currentPage + 1) * marketPerPage;
	const indexOfFirstVendor = indexOfLastVendor - marketPerPage;
	const currentMarket = adminData.slice(indexOfFirstVendor, indexOfLastVendor);

	const handlePageClick = (event) => {
		setCurrentPage(event.selected);
	};

	const handleVendorsPerPageChange = (event) => {
		setMarketsPerPage(Number(event.target.value));
		setCurrentPage(0);
	};

	return (
		<>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Date & Content</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<Link to="#">Date & Content</Link>
								</li>
							</ul>
						</div>
						<div className="ml-md-auto py-2 py-md-0">
							<div className="row">
								{/* Search Functionality */}
								<div className="col">
									<div className="row">
										<div class="collapse" id="search-nav">
											<form class="navbar-left navbar-form nav-search mr-md-3">
												<div className="input-group btn-round" style={{ position: "relative" }}>
													<input className="form-control" type="text" placeholder="Search ..." value={searchQuery} onChange={handleSearch} style={{ "padding-left": "30px" }} />
													<i
														className="fa fa-search"
														style={{
															position: "absolute",
															left: "10px",
															top: "50%",
															transform: "translateY(-50%)",
															color: "#aaa",
														}}
													></i>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="table-responsive">
									<table id="basic-datatables" className="table table-hover">
										<thead>
											<tr>
												<th>Name</th>
												<th>Total Stall</th>
												<th>Image</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{currentMarket.map((user, index) => (
												<tr key={index}>
													<td>{user.name || "N/A"}</td>
													<td>{user.totalstall || "N/A"}</td>
													<td>
														<span className="product-img">
															<img height={"100%"} src={`${process.env.REACT_APP_IMG_URL}/${user.image}`} alt="" />
														</span>
													</td>
													<td>
														<div className="form-button-action">
															{user.subscription ? (
																<>
																	<button type="button" className="btn btn-primary btn-sm mr-1" onClick={() => handleOpenModal(user._id)}>
																		Add Date
																	</button>
																	<button type="button" className="btn btn-danger btn-sm mr-1" onClick={() => handleAddContent(user)}>
																		{user.marketContent ? "Edit Content" : "Add Content"}
																	</button>
																	<button type="button" className="btn btn-primary btn-sm mr-1" onClick={() => handleViewDate(user._id)}>
																		View Date
																	</button>
																	{user.marketContent && (
																		<button type="button" className="btn btn-danger btn-sm mr-1" onClick={() => handleClick(user._id, user.name)}>
																			Download HTML
																		</button>
																	)}
																	{!user?.subscription[0] ? (
																		<button type="button" className="btn btn-success btn-sm" onClick={() => makePaymentHandler(user._id)}>
																			{`Make Payment of  $${priceData?.unit_amount / 100}`}
																		</button>
																	) : (
																		<></>
																	)}
																</>
															) : // Subscription key not present, hide these buttons
															trial ? (
																<>
																	<button type="button" className="btn btn-primary btn-sm mr-1" onClick={() => handleOpenModal(user._id)}>
																		Add Date
																	</button>
																	<button type="button" className="btn btn-danger btn-sm mr-1" onClick={() => handleAddContent(user)}>
																		{user.marketContent ? "Edit Content" : "Add Content"}
																	</button>
																	<button type="button" className="btn btn-primary btn-sm mr-1" onClick={() => handleViewDate(user._id)}>
																		View Date
																	</button>
																	<button type="button" className="btn btn-danger btn-sm mr-1" onClick={() => handleClick(user._id, user.name)}>
																		55
																		{user.marketContent ? "Download HTML" : ""}
																	</button>
																	<button type="button" className="btn btn-success btn-sm" onClick={() => makePaymentHandler(user._id)}>
																		{`Make Payment of  $${priceData?.unit_amount / 100}`}
																	</button>
																</>
															) : (
																<button type="button" className="btn btn-success btn-sm" onClick={() => makePaymentHandler(user._id)}>
																	{`Make Payment of ${priceData?.unit_amount / 100}`}
																</button>
															)}
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>

									<div className="d-flex justify-content-between align-items-center pagination-container">
										<ReactPaginate
											previousLabel={"Previous"}
											nextLabel={"Next"}
											breakLabel={"..."}
											// pageCount={Math.ceil(adminData.length / marketPerPage)}
											pageCount={Math.ceil(total / marketPerPage)}
											marginPagesDisplayed={2}
											pageRangeDisplayed={5}
											onPageChange={handlePageClick}
											containerClassName={"pagination m-0"}
											pageClassName={"page-item"}
											pageLinkClassName={"page-link"}
											previousClassName={"page-item"}
											previousLinkClassName={"page-link"}
											nextClassName={"page-item"}
											nextLinkClassName={"page-link"}
											breakClassName={"page-item"}
											breakLinkClassName={"page-link"}
											activeClassName={"active"}
										/>
										<div>
											<span>Records Per Page: </span>

											<select value={marketPerPage} onChange={handleVendorsPerPageChange} className="form-control m-2" style={{ width: "auto", display: "inline-block" }}>
												{marketPerPageOptions.map((option) => (
													<option key={option} value={option}>
														Show {option}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{modalOpen && (
				<div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Add Date</h5>
								<button type="button" className="close" onClick={handleCloseModal}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form>
									<div className="form-group">
										<label>Total Stall</label>
										<input type="text" className="form-control" name="totalStall" value={formData.totalStall} onChange={handleChange} />
										<div className="error" style={{ color: "red" }}>
											{formErrors.totalStall}
										</div>
									</div>
									<div className="form-group">
										<label>Date</label>
										<div className="form-group">
											<div className="myContainer">
												<Calendar
													className="form-control"
													placeholder="Add Date"
													name="date"
													format="DD MMMM YYYY"
													multiple
													plugins={[<DatePanel />]}
													minDate={new Date()}
													value={formData.date} // Set the value here
													onChange={(date) => {
														console.log("date", date);
														setFormData({ ...formData, date });
													}}
												/>
											</div>
										</div>
										<div className="error" style={{ color: "red" }}>
											{formErrors.date}
										</div>
									</div>
								</form>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
									Close
								</button>
								<button type="button" className="btn btn-primary" onClick={handleSubmit}>
									{loading ? "Saving..." : "Save changes"}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
			<MapModal isOpen={isMapModalOpen} onRequestClose={handleCloseMapModal} onClose={handleCloseMapModal} />
		</>
	);
};

export default MarketDate;
