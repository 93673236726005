import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate"; // Import ReactPaginate

const EmailTemplates = () => {
	document.title = "Eventease360 | Email Templates";
	const { getEmailTemplates, deleteEmailTemplates, copyEmailTemplates } = useApiAxios();
	const [emailTemplate, setEmailTemplate] = useState([]);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [previewModalOpen, setPreviewModalOpen] = useState(false);
	const [previewData, setPreviewData] = useState("");
	const [emailTemplateToDelete, setEmailTemplateToDelete] = useState(null);
	const [error, setError] = useState(null);

	const [currentPage, setCurrentPage] = useState(0);
	const [emailPerPage, setEmailPerPage] = useState(10);
	const emailPerPageOptions = [5, 10, 20, 50];
	const [total, setTotal] = useState(0);
	const navigate = useNavigate();

	const fetchEmailTemplates = async (page, limit) => {
		const response = await getEmailTemplates(page, limit, "marketOwner");
		if (!response.error) {
			setEmailTemplate(response.data.data);
			setTotal(response.data.total);
		} else {
			setError(response.data);
		}
	};

	useEffect(() => {
		fetchEmailTemplates(currentPage + 1, emailPerPage);
	}, [currentPage, emailPerPage]);

	if (error) {
		return <div>Error: {error}</div>;
	}

	const formatDate = (dateString) => {
		const options = { year: "numeric", month: "long", day: "numeric" };
		return new Date(dateString).toLocaleDateString(undefined, options);
	};

	const handlePageClick = (event) => {
		setCurrentPage(event.selected);
	};

	const handleEmailPerPageChange = (event) => {
		setEmailPerPage(Number(event.target.value));
		setCurrentPage(0);
	};

	const handleOpenDeleteModal = (userId) => {
		setEmailTemplateToDelete(userId);
		setDeleteModalOpen(true);
		const backdrop = document.createElement("div");
		backdrop.classList.add("modal-backdrop", "fade", "show");
		document.body.appendChild(backdrop);
	};

	const handleCloseDeleteModal = () => {
		setDeleteModalOpen(false);
		setEmailTemplateToDelete(null);
		const backdrop = document.querySelector(".modal-backdrop");
		if (backdrop) {
			document.body.removeChild(backdrop);
		}
	};

	const handleDeleteEmailTemplate = async () => {
		if (emailTemplateToDelete) {
			try {
				await deleteEmailTemplates(emailTemplateToDelete);
				await fetchEmailTemplates(currentPage + 1, emailPerPage);
				handleCloseDeleteModal();
			} catch (error) {
				console.error("Error deleting market:", error);
			}
		}
	};

	const handleEdit = (data) => {
		navigate(`/update-email-templates/${data._id}`, { state: { data } });
	};

	const handleCopy = async (data) => {
		const updatedContent = {
			templateId: data._id,
			userType: "marketOwner",
		};

		await copyEmailTemplates(updatedContent);
		await fetchEmailTemplates(currentPage + 1, emailPerPage);
	};

	const handleOpenPreviewModal = (data) => {
		setPreviewData(data.body);
		setPreviewModalOpen(true);
		const backdrop = document.createElement("div");
		backdrop.classList.add("modal-backdrop", "fade", "show");
		document.body.appendChild(backdrop);
	};

	const handleClosePreviewModal = () => {
		setPreviewData("");
		setPreviewModalOpen(false);
		const backdrop = document.querySelector(".modal-backdrop");
		if (backdrop) {
			document.body.removeChild(backdrop);
		}
	};

	return (
		<>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Email Templates</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<a href="#">Email Templates</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="table-responsive">
									<table id="add-row" className="display table table-striped table-hover">
										<thead>
											<tr>
												<th>Title</th>
												<th>Internal Title</th>
												<th>Last Modified</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{emailTemplate.map((item) => (
												<tr key={item._id}>
													<td>{item.title}</td>
													<td>{item.internalTitle}</td>
													<td>{formatDate(item.updatedAt)}</td>
													<td>
														<button className="btn btn-primary btn-sm mr-1" onClick={() => handleOpenPreviewModal(item)}>
															<i className="fa fa-eye"></i>
														</button>
														{item.isDefault ? (
															<>
																<button className="btn btn-primary btn-sm mr-1" onClick={() => handleCopy(item)}>
																	<i className="fa fa-copy"></i>
																</button>
															</>
														) : (
															<>
																<button className="btn btn-primary btn-sm mr-1" onClick={() => handleEdit(item)}>
																	<i className="fa fa-edit"></i>
																</button>
																<button className="btn btn-danger btn-sm" onClick={() => handleOpenDeleteModal(item._id)}>
																	<i className="fa fa-trash"></i>
																</button>
															</>
														)}
													</td>
												</tr>
											))}
										</tbody>
									</table>
									<div className="d-flex justify-content-between align-items-center pagination-container">
										<ReactPaginate
											previousLabel={"Previous"}
											nextLabel={"Next"}
											breakLabel={"..."}
											pageCount={Math.ceil(total / emailPerPage)} // Use pageCount here
											marginPagesDisplayed={2}
											pageRangeDisplayed={5}
											onPageChange={handlePageClick}
											containerClassName={"pagination m-0"}
											pageClassName={"page-item"}
											pageLinkClassName={"page-link"}
											previousClassName={"page-item"}
											previousLinkClassName={"page-link"}
											nextClassName={"page-item"}
											nextLinkClassName={"page-link"}
											breakClassName={"page-item"}
											breakLinkClassName={"page-link"}
											activeClassName={"active"}
										/>
										<div>
											<span>Records Per Page: </span>

											<select value={emailPerPage} onChange={handleEmailPerPageChange} className="form-control m-2" style={{ width: "auto", display: "inline-block" }}>
												{emailPerPageOptions.map((option) => (
													<option key={option} value={option}>
														Show {option}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{deleteModalOpen && (
				<div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Delete Email Template</h5>
								<button type="button" className="close" onClick={handleCloseDeleteModal}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p>Are you sure you want to delete this email template?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-danger" onClick={handleDeleteEmailTemplate}>
									Yes
								</button>
								<button type="button" className="btn btn-secondary" onClick={handleCloseDeleteModal}>
									No
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{previewModalOpen && (
				<div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Email Template Preview</h5>
								<button type="button" className="close" onClick={handleClosePreviewModal}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body" dangerouslySetInnerHTML={{ __html: previewData }}></div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={handleClosePreviewModal}>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default EmailTemplates;
