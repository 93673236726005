import React, { useEffect, useState } from "react";
import useApiAxios from "../api/useApiAxios.js";
import { Link, useNavigate } from "react-router-dom";

import EmailModal from "./Modal/emailSectionModal";
import ReactPaginate from "react-paginate";
import { Tooltip } from "react-tooltip";

const Dashboard = () => {
	document.title = "Eventease360 | Dashboard";
	const { getAllVendor, deleteVendorApi, updateApprovalStatus, searchVendorApi, getDashboardDataApi, declineVendorStatus } = useApiAxios();
	const [vendorData, setVendorData] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedRows, setSelectedRows] = useState([]);
	const [selectedRowsCount, setSelectedRowsCount] = useState(0);
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [dashBoardData, setDashBoardData] = useState();

	const [vendorsPerPage, setVendorsPerPage] = useState(10);
	const vendorsPerPageOptions = [5, 10, 20, 50];

	const [currentPage, setCurrentPage] = useState(0);

	const navigate = useNavigate();

	const fun = async () => {
		getAllVendor()
			.then((response) => {
				setVendorData(response.data.data);
			})
			.catch((error) => {
				setVendorData([]);
				console.error("Error fetching data:", error);
			});
	};
	useEffect(() => {
		fun();
	}, []);
	const handleEdit = (vendor) => {
		navigate(`/editvendor/${vendor._id}`, { state: { vendor } });
	};
	const handleApprove = async (vendorId) => {
		const data = { approvalStatus: "approved" }; // Define the status to update to
		try {
			const response = await updateApprovalStatus(vendorId, data);
			await fun();
		} catch (error) {
			console.error("Error approving vendor:", error);
		}
	};
	const handleDeclined = async (vendorId) => {
		const data = { approvalStatus: "decline" };
		try {
			const response = await declineVendorStatus(vendorId, data);
			await fun();
		} catch (error) {
			console.error("Error approving vendor:", error);
		}
	};

	const handleView = (vendor) => {
		navigate(`/viewvendor/${vendor._id}`, { state: { vendor } });
	};

	const handleSearch = async (event) => {
		try {
			const query = event.target.value;
			setSearchQuery(query);
			const response = await searchVendorApi(query);
			console.log("Searched data:", response.data.data);
			setVendorData(response.data.data);
		} catch (error) {
			console.error("Error searching:", error);
		}
	};

	const handleSelectAll = () => {
		const currentVendorIds = currentVendors.map((vendor) => vendor._id);
		const newSelectedRows = selectedRows.length === currentVendorIds.length ? [] : currentVendorIds;
		setSelectedRows(newSelectedRows);
		setSelectedRowsCount(newSelectedRows.length);
	};

	const handleSelectRow = (vendorId) => {
		setSelectedRows((prevSelectedRows) => {
			const newSelectedRows = prevSelectedRows.includes(vendorId) ? prevSelectedRows.filter((id) => id !== vendorId) : [...prevSelectedRows, vendorId];
			setSelectedRowsCount(newSelectedRows.length);
			return newSelectedRows;
		});
	};

	const openEmailModal = () => {
		setShowEmailModal(true);
	};

	const handleCloseModal = () => {
		setShowEmailModal(false);
	};

	const recipients = selectedRows.map((id) => {
		const vendor = vendorData.find((vendor) => vendor._id === id);
		return {
			email: vendor.email,
			name: vendor.contactPerson,
		};
	});
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getDashboardDataApi();
				setDashBoardData(response.data.data.dashboardData);
			} catch (error) {
				console.error("Error fetching dashboard data:", error);
			}
		};

		fetchData();
	}, []);

	const indexOfLastVendor = (currentPage + 1) * vendorsPerPage;
	const indexOfFirstVendor = indexOfLastVendor - vendorsPerPage;
	const currentVendors = vendorData.slice(indexOfFirstVendor, indexOfLastVendor);

	const handlePageClick = (event) => {
		setCurrentPage(event.selected);
	};

	const handleVendorsPerPageChange = (event) => {
		setVendorsPerPage(Number(event.target.value));
		setCurrentPage(0);
	};
	const isSelectAllChecked = currentVendors.length > 0 && currentVendors.every((vendor) => selectedRows.includes(vendor._id));

	return (
		<>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Dashboard</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<Link to="/">Dashboard</Link>
								</li>
							</ul>
						</div>
						<div className="ml-md-auto py-2 py-md-0">
							<div className="row">
								<div className="col">
									<div className="row">
										<div class="collapse" id="search-nav">
											<form class="navbar-left navbar-form nav-search mr-md-3">
												<div class="input-group btn-round">
													<div class="input-group-prepend">
														<button type="submit" class="btn btn-search pr-1" onClick={handleSearch}>
															<i class="fa fa-search search-icon"></i>
														</button>
													</div>
													<input className="form-control" type="text" placeholder="Search ..." value={searchQuery} onChange={handleSearch} />
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-sm-6 col-md-3">
						<div className="card card-stats card-primary card-round">
							<div className="card-body">
								<div className="row">
									<div className="col-5">
										<div className="icon-big text-center">
											<i className="flaticon-users" />
										</div>
									</div>
									<div className="col-7 col-stats">
										<div className="numbers">
											<p className="card-category">Total Vendors</p>
											<h4 className="card-title">{dashBoardData?.totalVendors}</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-sm-6 col-md-3">
						<div className="card card-stats card-success card-round">
							<div className="card-body ">
								<div className="row">
									<div className="col-5">
										<div className="icon-big text-center">
											<i className="flaticon-analytics" />
										</div>
									</div>
									<div className="col-7 col-stats">
										<div className="numbers">
											<p className="card-category"> Total Sales</p>
											<h4 className="card-title">${dashBoardData?.totalSales}</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-header">
								<div className="d-flex align-items-center">
									<h4 className="card-title my-1">Vendors</h4>
									{selectedRowsCount > 0 && (
										<div className="ml-auto">
											<div className="btn-group bulk-action">
												<button type="button" className="btn btn-primary dropdown-toggle btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													Bulk Actions ({selectedRowsCount})
												</button>
												<div className="dropdown-menu">
													<button className="dropdown-item" type="button" onClick={openEmailModal}>
														Send Email
													</button>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="ml-md-auto py-2 py-md-0"></div>
							</div>

							<div className="card-body">
								<div className="modal fade" id="addRowModal" tabIndex={-1} role="dialog" aria-hidden="true">
									<div className="modal-dialog" role="document">
										<div className="modal-content">
											<div className="modal-header no-bd">
												<h5 className="modal-title">
													<span className="fw-mediumbold">New</span>
													<span className="fw-light">Row</span>
												</h5>
												<button type="button" className="close" data-dismiss="modal" aria-label="Close">
													<span aria-hidden="true">×</span>
												</button>
											</div>
											<div className="modal-body">
												<p className="small">Create a new row using this form, make sure you fill them all</p>
												<form>
													<div className="row">
														<div className="col-sm-12">
															<div className="form-group form-group-default">
																<label>Name</label>
																<input id="addName" type="text" className="form-control" placeholder="fill name" />
															</div>
														</div>
														<div className="col-md-6 pr-0">
															<div className="form-group form-group-default">
																<label>Position</label>
																<input id="addPosition" type="text" className="form-control" placeholder="fill position" />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group form-group-default">
																<label>Office</label>
																<input id="addOffice" type="text" className="form-control" placeholder="fill office" />
															</div>
														</div>
													</div>
												</form>
											</div>
											<div className="modal-footer no-bd">
												<button type="button" id="addRowButton" className="btn btn-primary">
													Add
												</button>
												<button type="button" className="btn btn-danger" data-dismiss="modal">
													Close
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="table-responsive">
									<table id="add-row" className="table table-hover">
										<thead>
											<tr>
												<th>
													<input type="checkbox" onChange={handleSelectAll} checked={isSelectAllChecked} />
												</th>
												<th>Business Name</th>
												<th>Email</th>
												<th>Contact Person</th>
												<th>Status</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{currentVendors.map((vendor) => (
												<tr key={vendor._id}>
													<td>
														<input type="checkbox" checked={selectedRows.includes(vendor._id)} onChange={() => handleSelectRow(vendor._id)} />
													</td>
													<td>{vendor.businessName || "Not Available"}</td>
													<td>{vendor.email || "Not Available"}</td>
													<td>{vendor.contactPerson || "Not Available"}</td>
													<td>{vendor.approvalStatus || "Not Available"}</td>
													<td>
														<div className="form-button-action">
															<button
																type="button"
																data-toggle="tooltip"
																title="View Details"
																className="btn btn-info btn-sm mr-1"
																onClick={() => handleView(vendor)}
																data-tooltip-id="view-tooltip"
																data-tooltip-content="View"
															>
																<i className="fa fa-eye" />
															</button>

															<button
																type="button"
																data-toggle="tooltip"
																title=""
																className="btn btn-primary btn-sm mr-1"
																onClick={() => handleEdit(vendor)}
																data-original-title="Edit Task"
																data-tooltip-id="edit-tooltip"
																data-tooltip-content="Edit"
															>
																<i className="fa fa-edit" />
															</button>
															<button
																type="button"
																data-toggle="tooltip"
																title=""
																className="btn btn-danger btn-sm mr-1"
																data-original-title="Remove"
																data-tooltip-id="delete-tooltip"
																data-tooltip-content="Delete"
																onClick={async () => {
																	await deleteVendorApi(vendor._id);
																	await fun();
																}}
															>
																<i className="fa fa-trash" />
															</button>
															<button
																type="button"
																className={`btn btn-sm mr-1 ${vendor.approvalStatus === "Approved" ? "btn-success" : "btn-primary"}`}
																onClick={() => handleApprove(vendor._id)}
																disabled={vendor.approvalStatus === "Approved"}
															>
																{vendor.approvalStatus === "Approved" ? "Approved" : "Approve"}
															</button>
															<button
																type="button"
																className={`btn btn-sm ${vendor.approvalStatus === "decline" ? "btn-success" : "btn-danger"}`}
																onClick={() => handleDeclined(vendor._id)}
																disabled={vendor.approvalStatus === "Approved"}
															>
																{vendor.approvalStatus === "decline" ? "Declined" : "Decline"}
															</button>
															<Tooltip id="view-tooltip" />
															<Tooltip id="edit-tooltip" />
															<Tooltip id="delete-tooltip" />
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
									<div className="d-flex justify-content-between align-items-center pagination-container">
										<ReactPaginate
											previousLabel={"Previous"}
											nextLabel={"Next"}
											breakLabel={"..."}
											pageCount={Math.ceil(vendorData.length / vendorsPerPage)}
											marginPagesDisplayed={2}
											pageRangeDisplayed={5}
											onPageChange={handlePageClick}
											containerClassName={"pagination m-0"}
											pageClassName={"page-item"}
											pageLinkClassName={"page-link"}
											previousClassName={"page-item"}
											previousLinkClassName={"page-link"}
											nextClassName={"page-item"}
											nextLinkClassName={"page-link"}
											breakClassName={"page-item"}
											breakLinkClassName={"page-link"}
											activeClassName={"active"}
										/>
										<div>
											<span>Records Per Page: </span>
											<select value={vendorsPerPage} onChange={handleVendorsPerPageChange} className="form-control m-2" style={{ width: "auto", display: "inline-block" }}>
												{vendorsPerPageOptions.map((option) => (
													<option key={option} value={option}>
														Show {option}
													</option>
												))}
											</select>
										</div>
									</div>

									<EmailModal show={showEmailModal} handleClose={handleCloseModal} selectedVendorIds={selectedRows} recipients={recipients} />

									{/* Internal CSS */}
									<style jsx>{`
										.description-cell {
											max-width: 200px; /* Adjust this value as needed */
											white-space: nowrap;
											overflow: hidden;
											text-overflow: ellipsis;
										}
									`}</style>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Dashboard;
