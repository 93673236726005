import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import useApiAxios from "../../api/useApiAxios";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import Loader from "../loader/Loader";

const AddManagerAccount = () => {
	const { createMarketManagerApi, checkEmailApi, getAllMarket } = useApiAxios();
	const [loading, setLoading] = useState(false);
	const [isCheckingEmail, setIsCheckingEmail] = useState(false);
	const [isEmailValid, setIsEmailValid] = useState(false);

	const checkEmailOnChange = async (e) => {
		formik.handleChange(e);
		formik.setFieldTouched("email", true, true); // Mark email field as touched
		const emailValue = e.target.value;

		if (emailValue) {
			setIsCheckingEmail(true);
			const { error, data } = await checkEmailApi({ email: emailValue });
			setIsCheckingEmail(false);

			if (error) {
				formik.setErrors({ email: "Email already exists" });
				setIsEmailValid(false);
			} else {
				setIsEmailValid(true);
			}
		} else {
			setIsEmailValid(false); // Reset email validity if the input is empty
		}
	};
	const navigate = useNavigate();
	const initialValues = {
		name: "",
		totalstall: "",
		image: null,
		mapImage: null,
		category: [],
		subCategory: [],
	};

	const [selectedMarket, setSelectedMarket] = useState([]);

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Name is required"),
		phone: Yup.string()
			.required("Phone is required")
			.matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"), // Validation for exactly 10 digits
		email: Yup.string().required("Email is required"),
		managerMarket: Yup.array()
			.of(Yup.string().required("Market is required")) // Ensure each item in the array is a string
			.min(1, "At least one market is required"), // Ensure at least one item is present
	});

	const [marketList, setMarketList] = useState([]);

	const onSubmit = async (values, { setSubmitting }) => {
		const { error } = await checkEmailApi({ email: values.email });
		setIsCheckingEmail(false);

		if (error) {
			formik.setErrors({ email: "Email already exists" });
			return;
		}
		await createMarketManagerApi(values);
		setLoading(true);

		setSubmitting(false);
		setLoading(false);
		navigate("/manager_account");
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
		validateOnChange: false,
		validateOnBlur: false,
	});

	const handleCancel = () => {
		navigate("/manager_account");
	};
	useEffect(() => {
		const fetchMarketList = async () => {
			try {
				const response = await getAllMarket();
				const dataArray = response.data.data.map((element) => ({
					value: element._id,
					label: element.name,
				}));
				setMarketList(dataArray);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		fetchMarketList();
	}, []);

	return (
		<>
			<Loader open={loading} />
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Add Market Manager</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<Link to="/manager_account">Market Managers</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<Link to="#">Add Market Manager</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<form onSubmit={formik.handleSubmit}>
									<div className="col-md-12 col-xl-6">
										<div className="form-group">
											<label htmlFor="name">Name</label>
											<input
												type="text"
												className={`form-control ${formik.errors.name && formik.touched.name ? "is-invalid" : ""}`}
												id="name"
												name="name"
												placeholder="Name"
												value={formik.values.name}
												onChange={formik.handleChange}
												maxLength={50}
												required
											/>
											{formik.errors.name && formik.touched.name && (
												<div className="invalid-feedback" style={{ color: "red" }}>
													{formik.errors.name}
												</div>
											)}
										</div>
										<div className="form-group">
											<label htmlFor="name">Phone</label>
											<input
												type="text"
												className={`form-control ${formik.errors.phone && formik.touched.phone ? "is-invalid" : ""}`}
												id="phone"
												name="phone"
												placeholder="Phone"
												value={formik.values.phone}
												onChange={formik.handleChange}
												maxLength={10}
												required
											/>
											{formik.errors.phone && formik.touched.phone && (
												<div className="invalid-feedback" style={{ color: "red" }}>
													{formik.errors.phone}
												</div>
											)}
										</div>
										<div className="form-group">
											<label htmlFor="email">Email</label>
											<input
												type="email"
												className={`form-control ${formik.errors.email && formik.touched.email ? "is-invalid" : ""}`}
												id="email"
												name="email"
												placeholder="Email"
												value={formik.values.email}
												onChange={checkEmailOnChange} // Use the custom email check
												maxLength={50}
											/>
											{formik.errors.email && formik.touched.email && <div className="invalid-feedback">{formik.errors.email}</div>}
											{isCheckingEmail && <div className="form-text">Checking email...</div>}
										</div>

										<div className="form-group">
											<label htmlFor="market">Select Market</label>
											<Select
												isSearchable={false}
												isMulti
												name="managerMarket"
												className=""
												value={selectedMarket}
												onChange={(e) => {
													const dataToSet = e.map((element) => element.value);
													setSelectedMarket(
														e.map((element) => {
															return {
																label: element.label,
																value: element.value,
															};
														})
													);
													formik.setFieldValue("managerMarket", dataToSet);
												}}
												options={marketList}
											/>
											{formik.touched.managerMarket && formik.errors.managerMarket ? (
												<div className="error" style={{ color: "red" }}>
													{formik.errors.managerMarket}
												</div>
											) : null}
										</div>
									</div>

									<div className="card-action">
										<button className="btn btn-primary" type="submit" disabled={loading} style={{ marginRight: "5px" }}>
											{loading ? "Creating..." : "Create Manager"}
										</button>
										<button className="btn btn-danger" onClick={handleCancel}>
											Cancel
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddManagerAccount;
