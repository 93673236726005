import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import useApiAxios from "../../api/useApiAxios";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import Loader from "../loader/Loader";
import DragsAndDrop from "../../image/DragsAndDrop";

const CreateMarket = () => {
  const { createMarketApi, getAllCategorySubCategoryApi } = useApiAxios();
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [mapImagePreview, setMapImagePreview] = useState(null);
  const navigate = useNavigate();
  const initialValues = {
    name: "",
    totalstall: "",
    image: null,
    mapImage: null,
    category: [],
    subCategory: [],
  };

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [category, setCategory] = useState([]);

  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);

  const nameRef = useRef();
  const totalStallRef = useRef();
  const categoryRef = useRef();
  const subCategoryRef = useRef();
  const imageRef = useRef();
  const mapImageRef = useRef();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    totalstall: Yup.number()
      .required("Total Stall is required")
      .positive("Stall must be a positive number")
      .integer("Stall must be an integer")
      .typeError("Stall must be a valid number"),
    category: Yup.array()
      .required("At least one category must be selected.")
      .min(1, "At least one category must be selected"),
    // subCategory: Yup.array()
    //   .required("At least one Sub Category must be selected.")
    //   .min(1, "At least one SubCategory must be selected"),
    image: Yup.mixed()
      .required("Image is required")
      .test(
        "fileSize",
        "File size is too large ,Size should be less then 4 MB",
        (value) => {
          if (value) {
            return value.size <= 1024 * 1024 * 4; // 4MB
          }
          return true;
        }
      )
      .test("fileFormat", "Unsupported file format", (value) => {
        if (value) {
          return ["image/jpg", "image/jpeg", "image/png"].includes(value.type);
        }
        return true;
      }),
    mapImage: Yup.mixed()
      .required("Map Image is required")
      .test(
        "fileSize",
        "File size is too large ,Size should be less then 4 MB",
        (value) => {
          if (value) {
            return value.size <= 1024 * 1024 * 4; // 4MB
          }
          return true;
        }
      )
      .test("fileFormat", "Unsupported file format", (value) => {
        if (value) {
          return ["image/jpg", "image/jpeg", "image/png"].includes(value.type);
        }
        return true;
      }),
  });

  const getCategory = async () => {
    const { data, error } = await getAllCategorySubCategoryApi();
    if (!error) {
      const dataArray = data.data.categories.map((element) => ({
        value: element._id,
        label: element.name,
      }));
      setCategory(dataArray);
      const dataSubCategoryArray = data.data.subCategories.map((element) => ({
        value: element._id,
        label: element.name,
      }));
      setSubCategory(dataSubCategoryArray);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const onSubmit = async (values, { setSubmitting }) => {
    setLoading(true);

    if (formik.errors.image || formik.errors.mapImage) {
      setLoading(false);
      setSubmitting(false);
      return;
    }

    const { error } = await createMarketApi({
      ...values,
      category: selectedCategory,
      subCategory: selectedSubCategory,
    });
    setSubmitting(false);
    setLoading(false);

    if (!error) {
      navigate("/viewmarket");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (formik.errors.name) {
      nameRef.current.focus();
    } else if (formik.errors.totalstall) {
      totalStallRef.current.focus();
    } else if (formik.errors.category) {
      categoryRef.current.focus();
    } else if (formik.errors.subCategory) {
      subCategoryRef.current.focus();
    }
  }, [formik.errors]);

  const handleCancel = () => {
    navigate("/viewmarket");
  };

  const handleImageChange = (file) => {
    formik.setFieldValue("image", file);
  };

  const handleMapImageChange = (file) => {
    setMapImagePreview(URL.createObjectURL(file));
    formik.setFieldValue("mapImage", file);
  };
  console.log(formik.errors);
  return (
    <>
      <Loader open={loading} />

      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Create Market</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/viewmarket">Market</Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/createmarket">Create Market</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="card-title">Add New Market Details</div>
              </div>
              <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="col-md-12 col-xl-6">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.name && formik.touched.name
                            ? "is-invalid"
                            : ""
                        }`}
                        id="name"
                        name="name"
                        placeholder="Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        maxLength={25}
                        required
                        ref={nameRef}
                      />
                      {formik.errors.name && formik.touched.name && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {formik.errors.name}
                        </div>
                      )}
                    </div>

                    {/* <div className="form-group">
                      <label htmlFor="subCategory">Select Category</label>
                      <Select
                        isSearchable={false}
                        isMulti
                        name="subCategory"
                        className="form-control"
                        onChange={(e) => {
                          const dataToSet = e.map((element) => element.value);
                          setSelectedSubCategory(dataToSet);
                          formik.setFieldValue("subCategory", dataToSet);
                        }}
                        options={subCategory}
                        ref={subCategoryRef}
                      />
                      {formik.touched.subCategory &&
                      formik.errors.subCategory ? (
                        <div className="error" style={{ color: "red" }}>
                          {formik.errors.subCategory}
                        </div>
                      ) : null}
                    </div> */}
                    <div className="form-group">
                      <label htmlFor="category">Select Category</label>
                      <Select
                        isSearchable={false}
                        isMulti
                        name="category"
                        className="form-control"
                        onChange={(e) => {
                          const dataToSet = e.map((element) => element.value);
                          setSelectedCategory(dataToSet);
                          formik.setFieldValue("category", dataToSet);
                        }}
                        options={category}
                        ref={categoryRef}
                      />
                      {formik.touched.category && formik.errors.category ? (
                        <div className="error" style={{ color: "red" }}>
                          {formik.errors.category}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label htmlFor="totalstall">Total Stall</label>
                      <input
                        type="number"
                        className={`form-control ${
                          formik.errors.totalstall && formik.touched.totalstall
                            ? "is-invalid"
                            : ""
                        }`}
                        id="totalstall"
                        name="totalstall"
                        placeholder="Total Stall"
                        value={formik.values.totalstall}
                        onChange={formik.handleChange}
                        maxLength={25}
                        required
                        ref={totalStallRef}
                      />
                      {formik.errors.totalstall &&
                        formik.touched.totalstall && (
                          <div
                            className="invalid-feedback"
                            style={{ color: "red" }}
                          >
                            {formik.errors.totalstall}
                          </div>
                        )}
                    </div>

                    <div className="">
                      <label htmlFor="">Select Image</label>
                      {/* <input
                        type="file"
                        className={`form-control ${
                          formik.errors.image && formik.touched.image
                            ? "is-invalid"
                            : ""
                        }`}
                        accept=".jpg, .jpeg, .png"
                        onChange={handleImageChange}
                        required
                        ref={imageRef}
                      /> */}

                      <DragsAndDrop
                        heading="Upload Image"
                        inputName="Image"
                        aspect={1}
                        uploadFile={(x) => {
                          handleImageChange(x);
                        }}
                      />
                      {formik.errors.image && (
                        <div className="" style={{ color: "red" }}>
                          {formik.errors.image}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="">Select Map Image</label>
                      {/* <input
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        className={`form-control ${
                          formik.errors.mapImage && formik.touched.mapImage
                            ? "is-invalid"
                            : ""
                        }`}
                        onChange={handleMapImageChange}
                        required
                        ref={mapImageRef}
                      /> */}

                      <DragsAndDrop
                        heading="Upload Image"
                        inputName="Image"
                        
                        uploadFile={(x) => {
                          handleMapImageChange(x);
                        }}
                      />
                      {formik.errors.mapImage && (
                        <div className="" style={{ color: "red" }}>
                          {formik.errors.mapImage}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="card-action">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      style={{ marginRight: "5px" }}
                    >
                      {loading ? "Saving..." : "Save"}
                    </button>
                    <button className="btn btn-danger" onClick={handleCancel}>
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateMarket;
