import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios"; // Import your custom hook
import "./InvoiceSummary.css";

const InvoiceSummary = () => {
  const { getVendorDateInvoicePaymentUrl, getAllInvoiceByVendorInvoiceId } =
    useApiAxios();
  const { vendorInvoiceId } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [price, setPrice] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState("");
  const [vendorDates, setVendorDates] = useState([]);

  
  
  const staticImagePath = process.env.REACT_APP_IMG_URL;
 


  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await getAllInvoiceByVendorInvoiceId(vendorInvoiceId);
        if (response.error || !response.data) {
          setErrorMessage("Failed to fetch invoice data.");
          return;
        }
        console.warn("(((((((((((((())))))))))))))))", response)

        const invoiceData = response.data;
        setInvoice(invoiceData);
        setPrice(invoiceData.data.price);

        if (
          invoiceData.data.vendorDates &&
          invoiceData.data.vendorDates.length > 0
        ) {
          const dates = invoiceData.data.vendorDates.map((vd) => {
            const date = new Date(vd.dateId.date);
            return date.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            });
          });
          setVendorDates(dates);

          const firstDate = new Date(
            invoiceData.data.vendorDates[0].dateId.date
          );
          setInvoiceDate(
            firstDate.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })
          );
        }
      } catch (err) {
        setErrorMessage(
          "An unexpected error occurred while fetching invoice data."
        );
        console.error("Error fetching invoice data:", err);
      }
    };

    fetchInvoice();
  }, [vendorInvoiceId]);
  const logo = invoice?.data?.market?.image ;
  console.warn("SSSSSSSSSS:::::::::SSSSSSSSS",logo)

  const makePaymentHandler = async () => {
    if (price === null) return;
    const totalPrice = price * vendorDates.length;
    console.warn("prices", totalPrice)


    try {
      const { data, error } = await getVendorDateInvoicePaymentUrl({
        price: totalPrice,
        vendorInvoiceId,
      });

      if (error || !data || !data.data.sessionUrl) {
        setErrorMessage("Failed to initiate payment. Please try again.");
        return;
      }

      window.location.href = data.data.sessionUrl;
    } catch (err) {
      setErrorMessage("An unexpected error occurred. Please try again.");
      console.error("Error during payment initiation:", err);
    }
  };

  return (
    <div className="container">
      <div className="d-flex flex-column flex-grow-1">
        <div className="d-flex flex-column mb-3">
          <div className="d-flex flex-column border p-3">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <td
                      colSpan="3"
                      style={{
                        borderBottom: "solid 3px #8ec63f",
                        padding: "5px 30px 5px 30px",
                        background: "#f7f7f7",
                        textAlign: "center",
                      }}
                    >
                      <img
                        
                        src={`${staticImagePath}/${logo}`}
                        alt="Company Logo"
                        style={{
                          maxWidth: "300px",
                          height: "80px",
                          marginBottom: "20px",
                        }} // Adjust the logo size if needed
                        
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="3"
                      style={{
                        padding: "20px 30px",
                        background: "#f7f7f7",
                        textAlign: "center",
                      }}
                    >
                      <h3 style={{ margin: 0 }}>Invoice Summary</h3>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="3" style={{ padding: "15px 30px" }}>
                      <div className="d-flex">
                        <div className="company-info mt-3" >

                        <h2>Invoice To:</h2>
                          <p>
                            Business Name:{" "}
                            {invoice?.data.vendorId.businessName || "N/A"}
                          </p>
                          <p>Email: {invoice?.data.vendorId.email || "N/A"}</p>
                         
                          <p>
                            Contact Person:{" "}
                            {invoice?.data?.vendorId?.contactPerson}
                          </p>
                          <p>Phone: {invoice?.data?.vendorId?.contactNumber}</p>
                       
                        </div>
                        <div className="invoice-to-info mt-3">
                        <h2>From </h2>
                        <h3>Market Details:</h3>
                        <p>Market Name: {invoice?.data.market.name || "N/A"}</p>
                        <p>Market Dates:</p>
                      <ul>
                        {vendorDates.length > 0 ? (
                          vendorDates.map((date, index) => (
                            <li key={index}>{date}</li>
                          ))
                        ) : (
                          <li>N/A</li>
                        )}
                      </ul>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ padding: "15px 30px" }}>
                      {" "}
                      Market Charges: $
                      {price !== null ? price.toFixed(2) : "0.00"}
                    </td>
                    <td style={{ padding: "15px 30px" }}>Amount:</td>
                    <td
                      style={{
                        padding: "15px 30px",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                     
                      ${price !== null ? (price * vendorDates.length).toFixed(2) : "0.00"}

                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="3"
                      style={{
                        padding: "0",
                        height: "1px",
                        background: "#8ec63f",
                      }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{ padding: "15px 30px", textAlign: "right" }}
                    >
                      Subtotal
                    </td>
                    <td
                      style={{
                        padding: "15px 30px",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      ${price !== null ? (price * vendorDates.length).toFixed(2) : "0.00"}
                      </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{ padding: "15px 30px", textAlign: "right" }}
                    >
                      Taxes
                    </td>
                    <td
                      style={{
                        padding: "15px 30px",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      $0.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{ padding: "15px 30px", textAlign: "right" }}
                    >
                      <strong>Invoiced Total</strong>
                    </td>
                    <td
                      style={{
                        padding: "15px 30px",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      ${price !== null ? (price * vendorDates.length).toFixed(2) : "0.00"}
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-4" style={{ padding: "15px 30px" }}>
              <div className="d-flex justify-content-start">
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  onClick={makePaymentHandler}
                >
                 {`Make Payment of $${
                    price !== null ? (price * vendorDates.length).toFixed(2) : "0.00"
                  }`}
                </button>
              </div>
            </div>
            {errorMessage && (
              <div
                className="mt-3 alert alert-danger"
                style={{ padding: "15px 30px" }}
              >
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceSummary;
