import React, { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

const ApproveVendorChanges = (props) => {
	document.title = "Eventease360 | Approve Vendor Changes";
	const { getAllVendorApprovalChanges, searchVendorApi, updateApprovalChanges, updateDisApprovalChanges, waitListVendorListStatus } = useApiAxios();

	const [vendorData, setVendorData] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(0);
	const [vendorsPerPage, setVendorsPerPage] = useState(10);
	const vendorsPerPageOptions = [5, 10, 20, 50, 100];
	const [total, setTotal] = useState(0);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [selectedVendor, setSelectedVendor] = useState(null);
	const navigate = useNavigate();

	const fetchVendors = async (page, limit) => {
		try {
			const response = await getAllVendorApprovalChanges(page, limit);
			setVendorData(response.data.data);
			setTotal(response.data.total);
		} catch (error) {
			setVendorData([]);
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		fetchVendors(currentPage + 1, vendorsPerPage);
	}, [currentPage, vendorsPerPage]);

	const handleApprove = async (vendorId) => {
		try {
			await updateApprovalChanges(vendorId);
			fetchVendors(currentPage + 1, vendorsPerPage);
		} catch (error) {
			console.error("Error approving vendor:", error);
		}
	};

	const handleDisApprove = async (vendorId) => {
		try {
			await updateDisApprovalChanges(vendorId);
			fetchVendors(currentPage + 1, vendorsPerPage);
		} catch (error) {
			console.error("Error approving vendor:", error);
		}
	};

	const handleSearch = async (event) => {
		const query = event.target.value;
		setSearchQuery(query);
		if (query) {
			const response = await searchVendorApi(query);
			setVendorData(response.data.data);
		} else {
			fetchVendors(currentPage + 1, vendorsPerPage);
		}
	};

	const handlePageClick = (event) => {
		setCurrentPage(event.selected);
	};

	const handleVendorsPerPageChange = (event) => {
		setVendorsPerPage(Number(event.target.value));
		setCurrentPage(0);
	};

	const compareVendorData = (original, updated) => {
		const changes = {};
		for (const key in updated) {
			if (updated[key] !== original[key]) {
				changes[key] = { original: original[key], updated: updated[key] };
			}
		}
		return changes;
	};

	const indexOfLastVendor = (currentPage + 1) * vendorsPerPage;
	const indexOfFirstVendor = indexOfLastVendor - vendorsPerPage;
	const currentVendors = vendorData.slice(indexOfFirstVendor, indexOfLastVendor);

	const viewChanges = (vendor) => {
		navigate("/approvevendorchangesdetails", { state: { vendor } });
	};
	return (
		<>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Approve Vendor Changes</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<Link to="#">Approve Vendor Changes</Link>
								</li>
							</ul>
						</div>
						<div className="ml-md-auto py-2 py-md-0">
							<div className="row">
								<div className="col-12">
									<div className="row">
										<form className="navbar-left navbar-form nav-search mr-md-3">
											<div className="input-group btn-round" style={{ position: "relative" }}>
												<input className="form-control" type="text" placeholder="Search ..." value={searchQuery} onChange={handleSearch} style={{ paddingLeft: "30px" }} />
												<i
													className="fa fa-search"
													style={{
														position: "absolute",
														left: "10px",
														top: "50%",
														transform: "translateY(-50%)",
														color: "#aaa",
													}}
												></i>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="table-responsive">
									<table className="table table-hover">
										<thead>
											<tr>
												<th>Business Name</th>
												<th>Contact Person</th>
												<th>Contact Number</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{currentVendors.map((vendor) => (
												<tr key={vendor._id}>
													<td>{vendor.businessName || "Not Available"}</td>

													<td>{vendor.contactPerson || "Not Available"}</td>
													<td>{vendor.contactNumber || "Not Available"}</td>
													<td>
														<button className="btn btn-info btn-sm mr-1" onClick={() => viewChanges(vendor)}>
															View Changes
														</button>
														<button className="btn btn-success btn-sm mr-1" onClick={() => handleApprove(vendor._id)}>
															Approve
														</button>
														<button className="btn btn-danger btn-sm" onClick={() => handleDisApprove(vendor._id)}>
															Disapprove
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
									<div className="d-flex justify-content-between align-items-center pagination-container">
										<ReactPaginate
											previousLabel={"Previous"}
											nextLabel={"Next"}
											pageCount={Math.ceil(total / vendorsPerPage)}
											onPageChange={handlePageClick}
											containerClassName={"pagination m-0"}
											pageClassName={"page-item"}
											pageLinkClassName={"page-link"}
											previousClassName={"page-item"}
											previousLinkClassName={"page-link"}
											nextClassName={"page-item"}
											nextLinkClassName={"page-link"}
											breakClassName={"page-item"}
											breakLinkClassName={"page-link"}
											activeClassName={"active"}
										/>
										<div>
											<span>Records Per Page: </span>
											<select value={vendorsPerPage} onChange={handleVendorsPerPageChange} className="form-control m-2" style={{ width: "auto", display: "inline-block" }}>
												{vendorsPerPageOptions.map((option) => (
													<option key={option} value={option}>
														Show {option}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ApproveVendorChanges;
