import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import useApiAxios from "../../api/useApiAxios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import DragsAndDrop from "../../image/DragsAndDrop.jsx";

const VendorSetting = () => {
  const { updateProfileSettingApi } = useApiAxios();
  const { userData } = useContext(Context);
  const [showPassword, setShowPassword] = useState(false);
  const [imageFile, setImageFile] = useState(null)
  const { homeApi } = useApiAxios();

  const imageEndPoint = process.env.REACT_APP_IMG_URL; // Adjust according to your setup
  

  const [userInfo, setUserInfo] = useState({
    profileImage: null
  });

  useEffect(() => {
    document.title = "Settings - Eventease360";

    if (userData) {
      setUserInfo({
        profileImage: userData.vendor.profileImage || null // Set the initial profile image if exists
      });
    }
  }, [userData]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      if (imageFile) {
        const formData = new FormData();
        formData.append("profileImage", imageFile);

        const response = await updateProfileSettingApi(
          userData.vendor._id,
          formData
        );
        setImageFile(null)
        await homeApi()
      }

    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleImageUpload = (file) => {
    setImageFile(file)
  };

  return (
    <>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Update Profile Image</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/vendorsettings">Update Profile Image</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="col-md-8 col-xl-9">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Bussiness Name : {userData.vendor?.businessName}</h5>
            </div>
            <div className="card-body">
              <Formik initialValues={userInfo} onSubmit={handleSubmit}>
                {({ setFieldValue }) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label>Profile Image</label>
                          <DragsAndDrop
                            imgKey={
                              userInfo?.profileImage
                            }
                            uploadFile={handleImageUpload}
                            heading="Upload Profile Image"
                          />


                        </div>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Update Image
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorSetting;
