import React, { useEffect } from "react";
import { useFormik } from "formik";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import useApiAxios from "../../api/useApiAxios";
import { useNavigate, Link, useLocation } from "react-router-dom";
import * as Yup from "yup";

const EditEmailTemplates = () => {
	document.title = "Eventease360 | Create Email Templates";
	const MAX_CHAR_COUNT = 2000;
	const location = useLocation();
	const navigate = useNavigate();
	const { updateEmailTemplates } = useApiAxios();
	const templateData = location.state?.data;

	useEffect(() => {
		if (templateData) {
			formik.setValues({
				internalTitle: templateData.internalTitle || "",
				title: templateData.title || "",
				description: templateData.body || "",
				userType: "marketOwner",
			});
		}
	}, [templateData]);

	const validationSchema = Yup.object({
		internalTitle: Yup.string().required("Main Title is required").max(100, "Main Title must be at most 100 characters"),
		title: Yup.string().required("Title is required").max(100, "Title must be at most 100 characters"),
	});

	const initialValues = {
		internalTitle: templateData?.internalTitle || "",
		title: templateData?.title || "",
		description: templateData?.body || "",
		userType: "marketOwner",
	};

	const onSubmit = async (values) => {
		const updatedContent = {
			internalTitle: values.internalTitle,
			title: values.title,
			description: values.description,
			userType: "marketOwner",
		};

		await updateEmailTemplates(templateData?._id, updatedContent);
		navigate("/email-templates");
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
	});

	const handleDescriptionChange = (content) => {
		formik.setFieldValue("description", content);
	};

	return (
		<>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Create Email Template</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<Link to="/email-templates">Email Templates</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<Link to="#">Create Email Template</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<form onSubmit={formik.handleSubmit}>
									<div className="col-md-12">
										<div className="form-row">
											<div className="form-group col-md-6">
												<label htmlFor="title">Title</label>
												<input
													type="text"
													className={`form-control ${formik.errors.title && formik.touched.title ? "is-invalid" : ""}`}
													id="title"
													name="title"
													placeholder="Title"
													value={formik.values.title}
													onChange={formik.handleChange}
													maxLength={100}
												/>
												{formik.errors.title && formik.touched.title && (
													<div className="" style={{ color: "red" }}>
														{formik.errors.title}
													</div>
												)}
											</div>
										</div>
										<div className="form-row">
											<div className="form-group col-md-6">
												<label htmlFor="internalTitle">Internal Title</label>
												<input
													type="text"
													className={`form-control ${formik.errors.title && formik.touched.internalTitle ? "is-invalid" : ""}`}
													id="internalTitle"
													name="internalTitle"
													placeholder="Internal Title"
													value={formik.values.internalTitle}
													onChange={formik.handleChange}
													maxLength={100}
												/>
												{formik.errors.internalTitle && formik.touched.internalTitle && (
													<div className="" style={{ color: "red" }}>
														{formik.errors.internalTitle}
													</div>
												)}
											</div>
										</div>
										<div className="form-group">
											<label htmlFor="description">Email Content</label>
											<SunEditor
												setOptions={{
													buttonList: [
														["undo", "redo"],
														["font", "fontSize", "formatBlock"],
														["bold", "underline", "italic", "strike", "subscript", "superscript"],
														["fontColor", "hiliteColor"],
														["indent", "outdent"],
														["align", "horizontalRule", "list", "table"],
														["link", "image", "video"],
														["fullScreen", "showBlocks", "codeView"],
													],
													charCounterLabel: "Character Count:",
													maxCharCount: MAX_CHAR_COUNT, // Set max characters allowed
												}}
												height="100px"
												accept=".jpg, .jpeg, .png" // Add this line
												onChange={handleDescriptionChange}
												setContents={formik.values.description}
											/>
											{formik.errors.description && formik.touched.description && (
												<div className="" style={{ color: "red" }}>
													{formik.errors.description}
												</div>
											)}
										</div>
										<div className="form-row">
											<button className="btn btn-primary m-2" type="submit">
												Save
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditEmailTemplates;
