import React, { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link, useNavigate } from "react-router-dom";
import SendEmailModal from "../Modal/emailSectionModal";
import EmailModal from "../Modal/emailSectionModal";
import ReactPaginate from "react-paginate"; // Import ReactPaginate
import { Tooltip } from "react-tooltip";

import Footer from "../footer/Footer";
import { Button, Modal } from "react-bootstrap";

const VendorList = () => {
  document.title = "Eventease360 | Vendors List";
  const {
    getAllVendor,
    deleteVendorApi,
    updateApprovalStatus,
    searchVendorApi,
    getDashboardDataApi,
    declineVendorStatus,
    waitListVendorListStatus,
    getAllMarket,
    getVendorDetailsfilterApi,
    getAllCategorySubCategoryApi
  } = useApiAxios();
  const [vendorData, setVendorData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [dashBoardData, setDashBoardData] = useState();

  const [currentPage, setCurrentPage] = useState(0);
  const [vendorsPerPage, setVendorsPerPage] = useState(10);
  const vendorsPerPageOptions = [1, 5, 10, 20, 50, 100];
  const [total, setTotal] = useState(0);
  const [marketList, setMarketList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [selectedMarketId, setSelectedMarketId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState("");

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMarketList = async () => {
      try {
        const response = await getAllMarket();
        setMarketList(response.data.data);
        const categoryResponse = await getAllCategorySubCategoryApi();
        console.warn("::::::::::::::::::::::::::", categoryResponse);
        setCategories(categoryResponse.data.data.categories);
        setSubCategories(categoryResponse.data.data.subCategories);

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchMarketList();
  }, []);

  const fun = async (page, limit) => {
    try {
      const response = await getAllVendor(page, limit);
      setVendorData(response.data.data);
      setTotal(response.data.total);
      console.log("Data fetched:", response.data.data);
    } catch (error) {
      setVendorData([]);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fun(currentPage + 1, vendorsPerPage);
  }, [currentPage, vendorsPerPage]);

  const handleEdit = (vendor) => {
    navigate(`/editvendor/${vendor._id}`, { state: { vendor } });
  };
  const handleApprove = async (vendorId) => {
    const data = { approvalStatus: "approved" }; // Define the status to update to
    try {
      const response = await updateApprovalStatus(vendorId, data);
      await fun();
    } catch (error) {
      console.error("Error approving vendor:", error);
    }
  };
  const handleDeclined = async (vendorId) => {
    const data = { approvalStatus: "decline" };
    try {
      const response = await declineVendorStatus(vendorId, data);
      await fun();
    } catch (error) {
      console.error("Error approving vendor:", error);
    }
  };

  const handleWaitList = async (vendorId) => {
    try {
      const response = await waitListVendorListStatus(vendorId);
      await fun();
    } catch (error) {
      console.error("Error approving vendor:", error);
    }
  };

  const handleView = (vendor) => {
    navigate(`/viewvendor/${vendor._id}`, { state: { vendor } });
  };

  const handleSearch = async (event) => {
    try {
      const query = event.target.value;
      setSearchQuery(query);
      const response = await searchVendorApi(query);
      console.log("Searched data:", response.data.data);
      setVendorData(response.data.data);
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  const handleSelectAll = () => {
    const currentVendorIds = currentVendors.map((vendor) => vendor._id);
    const newSelectedRows =
      selectedRows.length === currentVendorIds.length ? [] : currentVendorIds;
    setSelectedRows(newSelectedRows);
    setSelectedRowsCount(newSelectedRows.length);
  };

  const handleSelectRow = (vendorId) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = prevSelectedRows.includes(vendorId)
        ? prevSelectedRows.filter((id) => id !== vendorId)
        : [...prevSelectedRows, vendorId];
      setSelectedRowsCount(newSelectedRows.length);
      return newSelectedRows;
    });
  };
  const openEmailModal = () => {
    setShowEmailModal(true);
  };

  const handleCloseModal = () => {
    setShowEmailModal(false);
  };

  const recipients = selectedRows.map((id) => {
    const vendor = vendorData.find((vendor) => vendor._id === id);
    return {
      email: vendor.email,
      name: vendor.contactPerson
    };
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardDataApi();
        setDashBoardData(response.data.data.dashboardData);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };
    fetchData();
  }, []);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected); // Make sure this updates correctly
  };

  const handleVendorsPerPageChange = (event) => {
    setVendorsPerPage(Number(event.target.value));
    setCurrentPage(0); // Reset to the first page when changing the number of vendors per page
  };
  const indexOfLastVendor = (currentPage + 1) * vendorsPerPage;
  const indexOfFirstVendor = indexOfLastVendor - vendorsPerPage;
  const currentVendors = vendorData.slice(
    indexOfFirstVendor,
    indexOfLastVendor
  );

  const handleMarketChange = async (event) => {
    const marketId = event.target.value;
    setSelectedMarketId(marketId);

    // Create the params object with the selected market ID
    const params = { market: marketId };

    // Add other filters if they are set
    if (selectedCategory) {
      params.category = selectedCategory;
    }
    if (selectedSubCategory) {
      params.subCategory = selectedSubCategory;
    }
    if (selectedApprovalStatus) {
      params.approvalStatus = selectedApprovalStatus;
    }

    // Call the API with all selected filters
    const { error, data } = await getVendorDetailsfilterApi(params);
    if (!error) {
      console.warn(data); // Handle the data as needed
      setVendorData(data.data);
    } else {
      console.error(data); // Handle the error accordingly
    }
  };

  const handleCategoryChange = async (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);

    // Create the params object with the selected category ID
    const params = { category: categoryId };

    // Add other filters if they are set
    if (selectedMarketId) {
      params.market = selectedMarketId;
    }
    if (selectedSubCategory) {
      params.subCategory = selectedSubCategory;
    }
    if (selectedApprovalStatus) {
      params.approvalStatus = selectedApprovalStatus;
    }

    // Call the API with all selected filters
    const { error, data } = await getVendorDetailsfilterApi(params);
    if (!error) {
      console.warn(data); // Handle the data as needed
      setVendorData(data.data);
    } else {
      console.error(data); // Handle the error accordingly
    }
  };

  const handleSubCategoryChange = async (event) => {
    const subCategoryId = event.target.value;
    setSelectedSubCategory(subCategoryId);

    // Create the params object with the selected subcategory ID
    const params = { subCategory: subCategoryId };

    // Add other filters if they are set
    if (selectedMarketId) {
      params.market = selectedMarketId;
    }
    if (selectedCategory) {
      params.category = selectedCategory;
    }
    if (selectedApprovalStatus) {
      params.approvalStatus = selectedApprovalStatus;
    }

    // Call the API with all selected filters
    const { error, data } = await getVendorDetailsfilterApi(params);
    if (!error) {
      console.warn(data); // Handle the data as needed
      setVendorData(data.data);
    } else {
      console.error(data); // Handle the error accordingly
    }
  };

  const handleApprovalStatusChange = async (selectedStatus) => {
    setSelectedApprovalStatus(selectedStatus);

    // Create the params object with the selected approval status
    const params = { approvalStatus: selectedStatus };

    // Add other filters if they are set
    if (selectedMarketId) {
      params.market = selectedMarketId;
    }
    if (selectedCategory) {
      params.category = selectedCategory;
    }
    if (selectedSubCategory) {
      params.subCategory = selectedSubCategory;
    }

    // Call the API with all selected filters
    const { error, data } = await getVendorDetailsfilterApi(params);
    if (!error) {
      console.warn(data); // Handle the data as needed
      setVendorData(data.data);
    } else {
      console.error(data); // Handle the error accordingly
    }
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [vendorToDelete, setVendorToDelete] = useState(null);

  const handleDeleteVendor = async () => {
    if (vendorToDelete) {
      setShowDeleteModal(false);
      setVendorToDelete(null);
      await deleteVendorApi(vendorToDelete);
      await fun();
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setVendorToDelete(null);
  };
  const ConfirmationModal = ({ show, onHide, onConfirm }) => (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Confirm Deletion</Modal.Title>
        <button type="button" className="close" onClick={onHide}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this vendor?</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onConfirm}>
          Yes
        </Button>
        <Button variant="secondary" onClick={onHide}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      <div className="panel-header">
        <div className="page-inner pb-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Vendors</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/vendorlist">Vendors</Link>
                </li>
              </ul>
            </div>
            <div className="ml-md-auto py-2 py-md-0">
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div class="collapse" id="search-nav">
                      <form class="navbar-left navbar-form nav-search mr-md-3">
                        <div class="input-group btn-round">
                          <div class="input-group-prepend">
                            <button
                              type="submit"
                              class="btn btn-search pr-1"
                              onClick={handleSearch}
                            >
                              <i class="fa fa-search search-icon"></i>
                            </button>
                          </div>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search ..."
                            value={searchQuery}
                            onChange={handleSearch}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <div className="pr-1">
                    {/* Dropdown for selecting market */}
                    <select
                      onChange={handleMarketChange}
                      value={selectedMarketId}
                      className="custom-select"
                    >
                      <option value="" disabled>
                        Select a market
                      </option>
                      {marketList.map((market) => (
                        <option key={market._id} value={market._id}>
                          {market.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* <div className="pl-0 pr-1">
                    <select
                      onChange={handleSubCategoryChange}
                      value={selectedSubCategory}
                      className="custom-select"
                    >
                      <option value="" disabled>
                        Select a Category
                      </option>
                      {subCategories.map((subCategory) => (
                        <option key={subCategory._id} value={subCategory._id}>
                          {subCategory.name}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  <div className="pl-0 pr-1">
                    {/* Dropdown for selecting category */}
                    <select
                      onChange={handleCategoryChange}
                      value={selectedCategory}
                      className="custom-select"
                    >
                      <option value="" disabled>
                        Select a Category
                      </option>
                      {categories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="pl-0 pr-1">
                    {/* Dropdown for selecting approval status */}
                    <select
                      onChange={(e) => {
                        setSelectedApprovalStatus(e.target.value);
                        handleApprovalStatusChange(e.target.value); // Call the function to handle API call
                      }}
                      value={selectedApprovalStatus}
                      className="custom-select"
                    >
                      <option value="" disabled>
                        Select approval status
                      </option>
                      <option value="pending">Pending</option>
                      <option value="Approved">Approved</option>
                    </select>
                  </div>

                  {selectedRowsCount > 0 && (
                    <div className="ml-auto">
                      <div className="btn-group bulk-action">
                        <button
                          type="button"
                          className="btn btn-primary dropdown-toggle btn-sm"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Bulk Actions ({selectedRowsCount})
                        </button>
                        <div className="dropdown-menu">
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={openEmailModal}
                          >
                            Send Email
                          </button>
                          {/* Add more bulk actions here if needed */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="card-body">
                <div
                  className="modal fade"
                  id="addRowModal"
                  tabIndex={-1}
                  role="dialog"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header no-bd">
                        <h5 className="modal-title">
                          <span className="fw-mediumbold">New</span>
                          <span className="fw-light">Row</span>
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="small">
                          Create a new row using this form, make sure you fill
                          them all
                        </p>
                        <form>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group form-group-default">
                                <label>Name</label>
                                <input
                                  id="addName"
                                  type="text"
                                  className="form-control"
                                  placeholder="fill name"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 pr-0">
                              <div className="form-group form-group-default">
                                <label>Position</label>
                                <input
                                  id="addPosition"
                                  type="text"
                                  className="form-control"
                                  placeholder="fill position"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group form-group-default">
                                <label>Office</label>
                                <input
                                  id="addOffice"
                                  type="text"
                                  className="form-control"
                                  placeholder="fill office"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="modal-footer no-bd">
                        <button
                          type="button"
                          id="addRowButton"
                          className="btn btn-primary"
                        >
                          Add
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table id="add-row" className="table table-hover">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            onChange={handleSelectAll}
                            checked={selectedRows.length === vendorData.length}
                          />
                        </th>
                        <th>Business Name</th>
                        <th>Email</th>
                        <th>Contact Person</th>
                        <th>Category</th>
                        {/* <th>Sub Category</th> */}
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentVendors?.map((vendor) => (
                        <tr key={vendor._id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(vendor._id)}
                              onChange={() => handleSelectRow(vendor._id)}
                            />
                          </td>
                          <td>{vendor.businessName || "Not Available"}</td>
                          <td>{vendor.email || "Not Available"}</td>

                          <td>{vendor.contactPerson || "Not Available"}</td>
                          {/* <td>
                            {vendor?.subCategory[0]?.name || "Not Available"}
                          </td> */}
                          <td>
                            {vendor?.category[0]?.name || "Not Available"}
                          </td>

                          <td>{vendor.approvalStatus || "Not Available"}</td>
                          <td>
                            <div className="form-button-action">
                              <button
                                type="button"
                                data-toggle="tooltip"
                                title="View Details"
                                className="btn btn-info btn-sm mr-1"
                                onClick={() => handleView(vendor)}
                                data-tooltip-id="view-tooltip"
                                data-tooltip-content="View"
                              >
                                <i className="fa fa-eye" />
                              </button>

                              <button
                                type="button"
                                data-toggle="tooltip"
                                title=""
                                className="btn btn-primary btn-sm mr-1"
                                onClick={() => handleEdit(vendor)}
                                data-original-title="Edit Task"
                                data-tooltip-id="edit-tooltip"
                                data-tooltip-content="Edit"
                              >
                                <i className="fa fa-edit" />
                              </button>
                              <button
                                type="button"
                                data-toggle="tooltip"
                                title=""
                                className="btn btn-danger btn-sm mr-1"
                                data-original-title="Remove"
                                data-tooltip-id="delete-tooltip"
                                data-tooltip-content="Delete"
                                onClick={() => {
                                  setVendorToDelete(vendor._id);
                                  setShowDeleteModal(true);
                                }}
                              >
                                <i className="fa fa-trash" />
                              </button>
                              <button
                                className={`btn btn-sm mr-1 ${
                                  vendor.approvalStatus === "Approved"
                                    ? "btn-success"
                                    : "btn-primary"
                                }`}
                                onClick={() => handleApprove(vendor._id)}
                                disabled={
                                  vendor.approvalStatus === "reject" ||
                                  vendor.approvalStatus === "Approved"
                                }
                                style={{ width: "100px" }} // Set the desired width
                              >
                                {vendor.approvalStatus === "Approved"
                                  ? "Approved"
                                  : "Approve"}
                              </button>
                              <button
                                className={`btn btn-sm mr-1 ${
                                  vendor.approvalStatus === "reject"
                                    ? "btn-danger"
                                    : "btn-danger"
                                }`}
                                onClick={() => handleDeclined(vendor._id)}
                                disabled={
                                  vendor.approvalStatus === "reject" ||
                                  vendor.approvalStatus === "Approved"
                                }
                                style={{ width: "100px" }} // Set the desired width
                              >
                                {vendor.approvalStatus === "reject"
                                  ? "Declined"
                                  : "Decline"}
                              </button>

                              <button
                                className={`btn btn-sm ${
                                  vendor.waitList === true
                                    ? "btn-warning" // Yellow for waitlisted
                                    : "btn-warning" // Grey for waitlist
                                }`}
                                onClick={() => handleWaitList(vendor._id)}
                                disabled={
                                  vendor.approvalStatus === "Approved" ||
                                  vendor.approvalStatus === "reject" ||
                                  vendor.waitList === true
                                }
                                style={{ width: "100px" }} // Set the desired width
                              >
                                {vendor.waitList === true
                                  ? "WaitListed"
                                  : "WaitList"}
                              </button>

                              <Tooltip id="view-tooltip" />
                              <Tooltip id="edit-tooltip" />
                              <Tooltip id="delete-tooltip" />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <ConfirmationModal
                    show={showDeleteModal}
                    onHide={handleCloseDeleteModal}
                    onConfirm={handleDeleteVendor}
                  />

                  <EmailModal
                    show={showEmailModal}
                    handleClose={handleCloseModal}
                    selectedVendorIds={selectedRows}
                    recipients={recipients}
                  />
                  <div className="d-flex justify-content-between align-items-center pagination-container">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(total / vendorsPerPage)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination m-0"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                    <div>
                      <span>Records Per Page: </span>

                      <select
                        value={vendorsPerPage}
                        onChange={handleVendorsPerPageChange}
                        className="form-control m-2"
                        style={{ width: "auto", display: "inline-block" }}
                      >
                        {vendorsPerPageOptions.map((option) => (
                          <option key={option} value={option}>
                            Show {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VendorList;
