import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FormGroup, FormLabel, FormControl, Button } from "react-bootstrap";
import * as Yup from "yup";

const PasswordResetForm = ({ changePasswordOTP, email }) => {
  const initialValues = {
    otp: "",
    password: "",
    passwordConfirm: "",
    email: email,
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "passwordConfirm") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const passwordFieldType = showPassword ? "text" : "password";
  const confirmPasswordFieldType = showConfirmPassword ? "text" : "password";

  const validationSchema = Yup.object({
    otp: Yup.string().required("OTP is required"),
    password: Yup.string()
      .required("New Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least 8 characters, including uppercase, lowercase, number, and special character."
      ),
    passwordConfirm: Yup.string()
      .required("Confirm New Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords do not match."),
  });

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Send PATCH request to the API
      const response = await changePasswordOTP({
        otp: values.otp,
        password: values.password,
        passwordConfirm: values.passwordConfirm,
        email,
      });
      console.log("response", response);

      // Handle the response
      resetForm();
    } catch (error) {
      // Handle errors
      alert("Error resetting password");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <style>
        {`
          .form-control-border {
            border: 25px solid #01120c;
            border-radius: 4px;
          }
          .form-group {
            position: relative;
          }
          .password-toggle {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }
          .text-danger {
            color: #dc3545;
          }
        `}
      </style>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <FormGroup className="mb-3">
            <FormLabel htmlFor="otp">OTP</FormLabel>
            <div className="form-group">
            <Field
              type="text"
              id="otp"
              name="otp"
              as={FormControl}
              className="form-control-border"
               style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "4px"
            }}
            />
            </div>
            <ErrorMessage name="otp" component="div" className="text-danger" />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel htmlFor="password">New Password</FormLabel>
            <div className="form-group">
              <Field
                type={passwordFieldType}
                id="password"
                name="password"
                as={FormControl}
                className="form-control-border"
              />
              <i
                className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"} password-toggle`}
                onClick={() => togglePasswordVisibility("password")}
              />
            </div>
            <ErrorMessage name="password" component="div" className="text-danger" />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel htmlFor="passwordConfirm">Confirm New Password</FormLabel>
            <div className="form-group">
              <Field
                type={confirmPasswordFieldType}
                id="passwordConfirm"
                name="passwordConfirm"
                as={FormControl}
                className="form-control-border"
              />
              <i
                className={`fa ${showConfirmPassword ? "fa-eye-slash" : "fa-eye"} password-toggle`}
                onClick={() => togglePasswordVisibility("passwordConfirm")}
              />
            </div>
            <ErrorMessage name="passwordConfirm" component="div" className="text-danger" />
          </FormGroup>

          <Button type="submit" className="btn btn-block btn_login w-100">
            Submit
          </Button>
        </Form>
      </Formik>
    </>
  );
};

export default PasswordResetForm;
