import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useApiAxios from "../../api/useApiAxios";
import { Link, useParams } from "react-router-dom";
import DragsAndDrop from "../../image/DragsAndDrop";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

const EditVendor = () => {
	document.title = "Eventease360 | Edit Vendor";
	let { id } = useParams();

	const { updateVendorApi, getAllCategoryApi, getAllSubCategoryApi, getVendorByIdApi, removeVendorImageApiByMarketOwner } = useApiAxios();
	const [subCategories, setSubCategories] = useState([]);
	const [categories, setCategories] = useState([]);
	const [vendor, setVendor] = useState();
	const initialValues = {
		businessName: "",
		email: "",
		_id: "",
		category: "",
		subCategory: "",
		businessDescription: "",
		contactPerson: "",
		contactNumber: "",
		addProductMessage: "",
		productImage1: "",
		productImage2: "",
		productImage3: "",
		publicLiabilityInsurance: "",
		insuranceExpiryDate: "",
		foodRegistrationImage: "",
		expireLicenseDate: "",
		liquorLicenseImage: "",
		liquorLicenseExpiryDate: "",
		otherLicenseImage: "",
		otherLicenseExpiryDate: "",
	};
	const validationSchema = Yup.object().shape({
		businessName: Yup.string().required("Business Name is required"),
		businessDescription: Yup.string().required("Business Description is required"),
		contactPerson: Yup.string().required("Contact Person is required"),
		contactNumber: Yup.string()
			.required("Contact Number is required")
			.matches(/^[0-9]+$/, "Contact Number must be numeric")
			.min(10, "Contact Number must be at least 10 digits")
			.max(15, "Contact Number must not exceed 15 digits"),
		addProductMessage: Yup.string().required("Product Message is required"),
		productImage1: Yup.string().required("Product Image 1 is required"),
		publicLiabilityInsurance: Yup.string().required("Public Liability Insurance is required"),
		foodRegistrationImage: Yup.string().nullable(), // Make optional
		liquorLicenseImage: Yup.string().nullable(), // Make optional
		otherLicenseImage: Yup.string().nullable(), // Make optional
		insuranceExpiryDate: Yup.date().nullable().required("Public Liability Insurance Date is required"),
		expireLicenseDate: Yup.date().nullable(), // Made optional
		liquorLicenseExpiryDate: Yup.date().nullable(), // Made optional
		otherLicenseExpiryDate: Yup.date().nullable(),
	});
	const handleImageUpload = (event, field) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				formik.setFieldValue(field, reader.result); // Set Base64 string to Formik field
			};
			reader.readAsDataURL(file); // Convert file to Base64
		}
	};

	const onSubmit = async (values, { setSubmitting }) => {
		console.warn("Form Submitted with values:", values);

		if (Object.keys(formik.errors).length > 0) {
			console.warn("Validation errors:", formik.errors);
			return;
		}
		try {
			const formData = new FormData();
			// Append all values to the FormData object
			for (const key in values) {
				if (Array.isArray(values[key])) {
					values[key].forEach((item) => formData.append(key, item));
				} else if (values[key] && values[key] instanceof File) {
					formData.append(key, values[key]); // Append file objects directly
				} else if (values[key]) {
					formData.append(key, values[key]); // Append other values
				}
			}
			await updateVendorApi(vendor._id, formData); // Pass FormData to API
			fetchData();
			console.log("Vendor updated successfully");
		} catch (error) {
			console.warn("Error updating vendor:", error);
		}
		setSubmitting(false);
	};
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
	});
	const fetchData = async () => {
		try {
			const categoriesData = await getAllCategoryApi();
			const subCategoriesData = await getAllSubCategoryApi();
			const vendorData = await getVendorByIdApi(id);
			const fetchedVendor = vendorData.data.data;

			setVendor(fetchedVendor);

			const formattedValues = {
				...initialValues,
				...fetchedVendor,
				insuranceExpiryDate: fetchedVendor?.insuranceExpiryDate,
				expireLicenseDate: fetchedVendor?.expireLicenseDate,
				liquorLicenseExpiryDate: fetchedVendor?.liquorLicenseExpiryDate,
				otherLicenseExpiryDate: fetchedVendor?.otherLicenseExpiryDate,
			};
			formik.setValues(formattedValues);
			setCategories(categoriesData.data.data);
			setSubCategories(subCategoriesData.data.data);
		} catch (error) {
			console.error("Error fetching categories or subcategories:", error);
		}
	};
	const handleRemoveImage = async (imageName, dateField) => {
		await removeVendorImageApiByMarketOwner(imageName, dateField, vendor._id);
		// fetchData()
	};

	useEffect(() => {
		fetchData();
	}, []);

	const styles = {
		imageContainer: {
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "space-between",
			margin: "10px 0",
		},
		imageWrapper: {
			flex: "0 1 calc(33.33% - 10px)",
			boxSizing: "border-box",
			textAlign: "center",
			marginBottom: "10px",
		},
		imgStyle: {
			width: "100%",
			height: "auto",
			borderRadius: "5px",
			border: "1px solid #ddd",
		},
	};

	// Handle category and subcategory changes

	const handleCategoryChange = (e) => {
		const value = e.target.value;
		formik.setFieldValue("category", [value]); // Set as array
	};

	const handleSubCategoryChange = (e) => {
		const value = e.target.value;
		formik.setFieldValue("subCategory", [value]); // Set as array
	};

	const today = new Date(); // Get today's date

	return (
		<>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Edit Vendor</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<Link to="/vendorlist">Vendors</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<a href="#">Edit Vendor</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<form onSubmit={formik.handleSubmit}>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="businessName">Business Name</label>
												<input
													type="text"
													id="businessName"
													name="businessName"
													className={`form-control ${formik.errors.businessName && formik.touched.businessName ? "is-invalid" : ""}`}
													value={formik.values.businessName}
													onChange={formik.handleChange}
												/>
												{formik.errors.businessName && formik.touched.businessName && <div className="invalid-feedback">{formik.errors.businessName}</div>}
											</div>
											<div className="form-group">
												<label htmlFor="businessDescription">Business Description</label>
												<input
													type="text"
													id="businessDescription"
													name="businessDescription"
													className={`form-control ${formik.errors.businessDescription && formik.touched.businessDescription ? "is-invalid" : ""}`}
													value={formik.values.businessDescription}
													onChange={formik.handleChange}
												/>
												{formik.errors.businessDescription && formik.touched.businessDescription && <div className="invalid-feedback">{formik.errors.businessDescription}</div>}
											</div>
											<div className="form-group">
												<label htmlFor="contactPerson">Contact Person</label>
												<input
													type="text"
													id="contactPerson"
													name="contactPerson"
													className={`form-control ${formik.errors.contactPerson && formik.touched.contactPerson ? "is-invalid" : ""}`}
													value={formik.values.contactPerson}
													onChange={formik.handleChange}
												/>
												{formik.errors.contactPerson && formik.touched.contactPerson && <div className="invalid-feedback">{formik.errors.contactPerson}</div>}
											</div>
											<div className="form-group">
												<label htmlFor="contactNumber">Contact Number</label>
												<input
													type="text"
													id="contactNumber"
													name="contactNumber"
													className={`form-control ${formik.errors.contactNumber && formik.touched.contactNumber ? "is-invalid" : ""}`}
													value={formik.values.contactNumber}
													onChange={formik.handleChange}
												/>
												{formik.errors.contactNumber && formik.touched.contactNumber && <div className="invalid-feedback">{formik.errors.contactNumber}</div>}
											</div>
											<div className="form-group">
												<label htmlFor="email">Email</label>
												<input
													type="email"
													id="email"
													name="email"
													className={`form-control ${formik.errors.email && formik.touched.email ? "is-invalid" : ""}`}
													value={formik.values.email}
													disabled
												/>
												{formik.errors.email && formik.touched.email && <div className="invalid-feedback">{formik.errors.email}</div>}
											</div>

											{/* Subcategory Dropdown */}
											{/* <div className="form-group">
												<label htmlFor="subCategory">Category</label>
												<select id="subCategory" name="subCategory" value={formik.values.subCategory} className="form-control" onChange={handleSubCategoryChange}>
													{Array.isArray(subCategories) &&
														subCategories.map((subCategory) => (
															<option key={subCategory._id} value={subCategory._id}>
																{subCategory.name}
															</option>
														))}
												</select>
												{formik.errors.subCategory && formik.touched.subCategory && <div className="invalid-feedback">{formik.errors.subCategory}</div>}
											</div> */}
											<div className="form-group">
												<label htmlFor="category">Category</label>
												<select id="category" name="category" className="form-control" value={formik.values.category} onChange={handleCategoryChange}>
													{categories.map((category) => (
														<option key={category._id} value={category._id}>
															{category.name}
														</option>
													))}
												</select>
												{formik.errors.category && formik.touched.category && <div className="invalid-feedback">{formik.errors.category}</div>}
											</div>
											<div className="form-group">
												<label htmlFor="addProductMessage">Product Message</label>
												<input
													type="text"
													id="addProductMessage"
													name="addProductMessage"
													className={`form-control ${formik.errors.addProductMessage && formik.touched.addProductMessage ? "is-invalid" : ""}`}
													value={formik.values.addProductMessage}
													onChange={formik.handleChange}
												/>
												{formik.errors.addProductMessage && formik.touched.addProductMessage && <div className="invalid-feedback">{formik.errors.addProductMessage}</div>}
											</div>
										</div>

										<div className="col-md-12">
											<h4>Product and License Images</h4>
											<div style={styles.imageContainer}>
												{[...Array(3)].map((_, index) => (
													<div style={styles.imageWrapper} key={`productImage${index + 1}`}>
														<DragsAndDrop
															imgKey={typeof formik.values[`productImage${index + 1}`] === "string" ? formik.values[`productImage${index + 1}`] : ""}
															uploadFile={(file) => {
																// Send both the file and the field name
																formik.setFieldValue(`productImage${index + 1}`, file);
															}}
														/>

														<div style={{ marginTop: "5px" }}>
															Product Image {index + 1}
															{/* Show the remove button only for productImage2 and productImage3 */}
															{index > 0 && ( // index 1 and 2 correspond to productImage2 and productImage3
																<button
																	type="button"
																	className="btn btn-danger btn-sm ms-2 m-2"
																	onClick={() => {
																		handleRemoveImage(`productImage${index + 1}`);
																	}}
																	disabled={!formik.values[`productImage${index + 1}`]} // Disable button if no image exists
																	style={{ marginLeft: "10px" }} // Add margin here
																>
																	Remove Image
																</button>
															)}
														</div>
														{formik.errors[`productImage${index + 1}`] && formik.touched[`productImage${index + 1}`] && (
															<div className="invalid-feedback">{formik.errors[`productImage${index + 1}`]}</div>
														)}
													</div>
												))}

												{[
													{
														field: "publicLiabilityInsurance",
														title: "Public Liability Insurance",
														dateField: "insuranceExpiryDate",
													},
													{
														field: "foodRegistrationImage",
														title: "Food Registration Image",
														dateField: "expireLicenseDate",
													},
													{
														field: "liquorLicenseImage",
														title: "Liquor License Image",
														dateField: "liquorLicenseExpiryDate",
													},
													{
														field: "otherLicenseImage",
														title: "Other License Image",
														dateField: "otherLicenseExpiryDate",
													},
												].map(({ field, title, dateField }) => (
													<div style={styles.imageWrapper} key={field}>
														<DragsAndDrop
															aspect={1 / 1.414}
															imgKey={typeof formik.values[field] === "string" ? formik.values[field] : ""}
															uploadFile={(file) => {
																formik.setFieldValue(field, file); // Store the file object instead of file.name
															}}
														/>
														<div style={{ marginTop: "5px" }}>
															{title}
															{field !== "publicLiabilityInsurance" && (
																<button
																	type="button"
																	className="btn btn-danger mt-2 m-2"
																	disabled={!formik.values[field]}
																	onClick={() => {
																		handleRemoveImage(field, dateField);
																	}}
																>
																	Remove
																</button>
															)}
														</div>
														<DatePicker
															selected={formik.values[dateField] || null}
															onChange={(date) => formik.setFieldValue(dateField, date)}
															className={`form-control ${formik.errors[dateField] && formik.touched[dateField] ? "is-invalid" : ""}`}
															dateFormat="dd/MM/yyyy"
															placeholderText={formik.values[dateField] ? format(formik.values[dateField], "dd/MM/yyyy") : "Select a date"}
															minDate={today} // Restrict selection to today or later
														/>
														{formik.errors[dateField] && formik.touched[dateField] && <div className="invalid-feedback">{formik.errors[dateField]}</div>}
													</div>
												))}
											</div>
										</div>
									</div>
									<button type="submit" className="btn btn-primary">
										Save
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditVendor;
