import React, { useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Context } from "../context/Context";
import { useLocation } from "react-router-dom";
const useApiAxios = () => {
	axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
	axios.defaults.headers.common["Content-Type"] = "application/json";

	// You can also set headers for specific request methods
	axios.defaults.headers.get["Accept"] = "application/json";

	const { userData, setUserData, isAuthorized, setIsAuthorized, setIsLoading, openInfoSnackbar, openErrorSnackbar, setTrial } = useContext(Context);

	// const ENDPOINT = process.env.REACT_APP_ENDPOINT;
	const ENDPOINT = process.env.REACT_APP_ENDPOINT;
	const LOGIN = "/";
	const HOME = "/";
	const LOGOUT = "/logout";
	const CREATE_MARKET = "/create/market";
	const GET_ALL_MARKET = "/getall/market";
	const FORGET_PASSWORD = "/forget/password";
	const CHANGE_FORGET_PASSWORD = "/forget/password";

	const GET_ALL_VENDOR = "/getall/Vendor";
	const UPDATE_VENDOR = "/update/vendor/sub-admin";
	const DELETE_VENDOR = "/delete/Vendor";

	// const CREATE_SUBADMIN = "/create/subAdmin";
	// const GET_ALL_SUBADMIN = "/getAll/SubAdmin";
	const DELETE_MARKET = "/delete/market";
	const UPDATE_MARKET = "/update/market";
	const UPDATE_MARKET_IMAGE = "/update/market";

	const POST_MARKET_DATE = "/create/market/date";
	const CREATE_MARKET_CONTENT = "/create/market/content";
	const UPLOAD_SECTION_IMAGE = "/uploadMarketContentImage";
	const GET_MARKET_DATA_ID = "/get/market";
	const DELETE_DATE = "/delete/market/date";
	const UPDATE_DATE_DATA = "/update/market/date";
	const DELETE_MARKET_SECTION_DATA = "/delete/market/contentSection";
	const UPDATE_SECTION_DATA = "/update/market/contentSection/data";

	const ADD_SECTION_DATA = "/create/market/contentSection";
	const UPDATE_SECTION_IMAGE_DATA = "/update/market/contentSection/image";
	const UPDATE_MARKET_BANNER_IMAGE = "/update/market/contentBannerImage";

	const GET_PRICE_DATA = "/priceList";
	const GET_PAYMENT_URL = "/create/subscription";
	const UPDATE_SUBSCRIPTION = "/update/subscription";
	const UPDATE_APPROVAL_STATUS = "/approve/vendor";
	const DELETE_APPROVAL_STATUS = "/declined/vendor";

	const WAITlIST_THE_VENDOR_ON_VENDOR_LIST = "/declined/wait/list";

	const UPDATE_SUBADMIN_DETAIL = "/update/market/contentData";
	const CREATE_CATEGORY = "/create/category";
	const CREATE_SUB_CATEGORY = "/create/subCategory";

	const GET_ALL_CATEGORY = "/getall/category";
	const GET_ALL_CATEGORY_AND_SUBCATEGORY = "/get/category/subcategory";

	const UPDATE_CATEGORY = "/update/category";

	const GET_ALL_SUB_CATEGORY = "/getall/subCategory";
	const UPDATE_SUB_CATEGORY = "/updateSubCategory";

	const UPDATE_PROFILE_DATA = "/update/subAdminProfileUpdate";

	const UPDATE_PROFILE_SETTING_DATA = "/update/vendorProfileUpdate";

	const UPDATE_VENDOR_PROFILE_DATA = "/update/vendor";
	const POST_VENDOR_DATE = "/apply/vendor/date";

	const GET_VENDOR_DATE = "/getall/date";
	const UPDATE_TUTORIAL_WATCH = "/update/tutorial";

	const GET_ALL_INVOICE_ID = "/getAllInvoiceByVendorInvoiceId";
	const GET_VENDOR_PAYMENT_URL = "/getPayments";

	const POST_MARKET_APPLY_DATE = "/create/vendor/subscription";

	const GET_PAYMENT_HISTORY = "/vendor/subAdminPaymentList";

	const UPDATE_VENDOR_PAYMENT = "/update/vendor/date/invoice/payment";
	const UPDATE_VENDOR_SUBSCRIPTION = "/create/vendor/date/invoice/payment";

	const POST_MAP_IMAGE_ENDPOINT = "/vendor/uploadMarketMapImage";

	const SEARCH_VENDOR_API = "/getall/VendorSearch";

	const SEARCH_MARKET_API = "/getall/searchMarket";
	const SEARCH_MARKET_DATE_API = "/getall/market";
	const SEARCH_CATEGORY_API = "/getall/category";

	const SEND_MULTI_EMAIL = "/send/email";
	const GET_EMAIL_LOG = "/emailLogs";
	const RESEND_EMAIL_API = "/resend/email";

	const GET_DASHBOARD_DATA = "/Admindashboard";

	const POST_VENDOR_DATE_APPLY = "/apply/date/from/vendor";

	const APPLIED_VENDOR_LIST = "/get/date/approval/list";

	const APPROVE_VENDOR_DATE = "/approval/date";
	const REJECT_VENDOR_DATE = "/reject/date";
	const GET_VENDOR_ATTENDACE_LIST = "/get/date/attendance/list";
	const VENDOR_ATTENDACE_PRESENT = "/mark/present";

	const VENDOR_ATTENDACE_ABSENT = "/mark/absent";

	const GET_VENDOR_INVOICE_LIST = "/get/date/Invoice/list";

	const GENERATE_INVOICE = "/createPaymentInvoice";
	const GET_ALL_INVOICE = "/getInvoiceHistory";
	const GET_ALL_INVOICE_VENDOR = "/getInvoiceHistory/vendor";
	const VENDOR_WAITLIST = "/vendorWaitlist";

	const CREATE_VENDOR_DATE_INVOICE = "/create/vendor/date/invoice/subscription";

	const SEARCH_INVOICE_HISTORY_API = "/getInvoiceSearchHistory";
	const GET_VENDOR_LIST_MAP = "/get/vendor/list/map";
	const REMOVE_MARKET_SEQUENCE = "/vendor/removeMarketSequence";
	const GET_VENDOR_BY_ID = "/get/VendorById";

	const GET_ALL_VENDOR_APPROVAL_CHANGES = "/update/allVendorProfile";
	const UPDATE_APPROVAL_CHANGES = "/updateVendorApprovedProfile";
	const UPDATE_DISAPPROVAL_CHANGES = "/updateVendorDisApprovedProfile";

	const REMOVE_VENDOR_IMAGE = "/remove/vendor/profile/image";
	const REMOVE_VENDOR_IMAGE_BY_MARKET_OWNER = "/remove/vendor/profile/image/by/market";

	const GET_All_VENDOR_CATEGORIES = "/get/market";

	const GET_ALL_VENDOR_FILTER_LIST = "/filterVendor";

	const DELETE_VENDOR_APPY_DATE = "/delete/vendor/date";

	const GET_STRIPE_KEY_DATA = "/stripe-credentials";
	const CHECK_EMAIL = "/check/email";
	const CREATE_MARKET_MANAGER_API = "/market/manager";
	const GET_MARKET_MANAGER_API = "/market/manager";
	const GET_ALL_MARKET_MANAGER_API = "/get/all/market/manager";

	const GET_ALL_VENDOR_DATE = "/getall/vendorDateDetails";

	const CHANGE_VENDOR_DATE_API = "/getall/vendorDateDetails";
	const GET_ALL_CHANGE_REQUEST_DATE = "/getall/vendorChangeDateDetails";

	const UPDATE_DISAPPROVAL_DATE_CHANGE = "/removal/date";
	const UPDATE_APPROVAL_DATE_CHANGE = "/approveVendorDateChanges";

	const GET_VENDOR_WALLET = "/vendorWallet";

	const GET_EMAIL_TEMPLATES = "/getall/email/template";
	const CREATE_EMAIL_TEMPLATES = "/create/email/template";
	const UPDATE_EMAIL_TEMPLATES = "/update/email/template";
	const DELETE_EMAIL_TEMPLATES = "/delete/email/template";
	const COPY_EMAIL_TEMPLATES = "/copy/email/template";

	const POST_MARKET_ACCOUNT_DETAIL = "/save-account-details";

	const GET_MARKET_OWNER_BANK_DETAILS = "/get-account-details";

	const POST_VENDOR_MANUAL_TRANSACTION = "/save-manual-transaction";
	const UPDATE_VENDOR_MANUAL_TRANSACTION_STATUS = "/update-manual-transaction";

	const GET_PAYMENT_LIST_VIA_BANKTRANSFER = "/get-manual-transaction";

	const location = useLocation();

	const navigate = useNavigate();

	const loginApi = async ({ email, password, loggedIn }) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${LOGIN}`, {
				email,
				password,
				loggedIn,
				from: "subAdmin",
			});
			if (res.data.data.admin.role === "admin") {
				return openErrorSnackbar("Please use admin portal to login");
			}
			setUserData(res.data.data.admin);
			localStorage.setItem("token", res.data.data.token);
			setIsAuthorized(true);
			console.log("Login successful:", res.data.data.token);
			navigate("/");
		} catch (error) {
			openErrorSnackbar(error.response?.data?.message || "Invalid Email or Password");
		} finally {
			setIsLoading(false);
		}
	};

	// const loginApi = async ({ email, password, loggedIn }) => {
	// 	try {
	// 		setIsLoading(true);
	// 		const res = await axios.post(`${ENDPOINT}${LOGIN}`, {
	// 			email,
	// 			password,
	// 			loggedIn,
	// 			from: "subAdmin",
	// 		});
	// 		if (res.data.data.admin.role === "admin") {
	// 			return openErrorSnackbar("Please use admin portal to login");
	// 		}
	// 		if (!(process.env.REACT_APP_DOMAIN === "local")) {
	// 			console.log(res.data.data.admin.role,process.env.REACT_APP_DOMAIN,)
	// 			if (process.env.REACT_APP_DOMAIN === "market"){
	// 				if ((!(res.data.data.admin.role === "subAdmin" || res.data.data.admin.role === "manager"))){
	// 					return openErrorSnackbar("Please use vendor portal to login ");
	// 				}
	// 			}
	// 			if (process.env.REACT_APP_DOMAIN === "vendor"){
	// 				if ((!(res.data.data.admin.role === "vendor" ))){
	// 					return openErrorSnackbar("Please use market portal to login ");
	// 				}
	// 			}
				
				
				
	// 		}

	// 		setUserData(res.data.data.admin);
	// 		localStorage.setItem("token", res.data.data.token);
	// 		setIsAuthorized(true);
	// 		console.log("Login successful:", res.data.data.token);
	// 		navigate("/");
	// 	} catch (error) {
	// 		openErrorSnackbar(error.response?.data?.message || "Invalid Email or Password");
	// 	} finally {
	// 		setIsLoading(false);
	// 	}
	// };

	const homeApi = async () => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${HOME}`);
			setUserData(res.data.data.user);
			setIsAuthorized(true);
			setTrial(new Date(res?.data?.data?.trialStartedAt) >= new Date());
			// navigate("/");
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			if (location?.pathname.includes("public") || location?.pathname === "/vendor/subscription/success") {
			} else {
				navigate("/");
			}
		}
	};

	const logOutApi = async () => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${LOGOUT}`);
			localStorage.removeItem("token");
			setUserData({});
			setIsAuthorized(false);
			navigate("/");
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			navigate("/");
		}
	};

	const createMarketApi = async (formData) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${CREATE_MARKET}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			setIsLoading(false);

			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const forgetPasswordApi = async ({ email, loggedIn }) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${FORGET_PASSWORD}`, { email });
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data.data };
		} catch (error) {
			openErrorSnackbar(error.response.data.message);
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const changePasswordOTP = async ({ otp, password, passwordConfirm, email }) => {
		try {
			setIsLoading(true);
			const res = await axios.patch(`${ENDPOINT}${CHANGE_FORGET_PASSWORD}`, {
				otp,
				password,
				passwordConfirm,
				email,
			});
			setIsLoading(false);

			openInfoSnackbar(res.data.message);
			navigate("/");
		} catch (error) {
			openErrorSnackbar(error.response.data.message);
			setIsLoading(false);
		}
	};

	// const createsubAdminApi = async (formData) => {
	//   try {
	//     setIsLoading(true);
	//     const res = await axios.post(`${ENDPOINT}${CREATE_SUBADMIN}`, formData, {
	//       headers: {
	//         "Content-Type": "application/json",

	//       },
	//     });
	//     setIsLoading(false);

	//     openInfoSnackbar(res.data.message);
	//     return { error: false, data: res.data };
	//   } catch (error) {
	//     setIsLoading(false);
	//     openErrorSnackbar(error.response.data.message);
	//     return { error: true, data: error.response.data };
	//   }
	// };

	const getAllVendor = async (page = 1, limit = 10) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_ALL_VENDOR}`, {
				params: {
					page: page,
					limit: limit,
				},
			});
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};
	const updateVendorApi = async (id, data) => {
		try {
			setIsLoading(true);
			const res = await axios.patch(
				`${ENDPOINT}${UPDATE_VENDOR}?id=${id}`, // Appending courseId to the URL
				data,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};
	const deleteVendorApi = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.delete(`${ENDPOINT}${DELETE_VENDOR}?id=${id}`);
			openInfoSnackbar(res.data.message);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};
	const getAllMarket = async (page = 1, limit = 10) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_ALL_MARKET}`, {
				params: {
					page: page,
					limit: limit,
				},
			});
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const deleteMarketApi = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.delete(`${ENDPOINT}${DELETE_MARKET}?id=${id}`);
			openInfoSnackbar(res.data.message);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const updateMarketApi = async (id, data) => {
		try {
			setIsLoading(true);
			const res = await axios.patch(`${ENDPOINT}${UPDATE_MARKET}?id=${id}`, data, {});
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};
	const updateMarketImage = async (id, formData) => {
		try {
			setIsLoading(true);
			const res = await axios.put(`${ENDPOINT}${UPDATE_MARKET_IMAGE}?id=${id}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			setIsLoading(false);
			// openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const postModalDate = async (formData) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${POST_MARKET_DATE}`, formData);
			console.log("Data posted successfully:", res.data);
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			console.error("Error posting data:", error);
			return { error: true, message: "Error posting data" };
		}
		setIsLoading(false);
	};

	const createSubAdminDetailApi = async (formData) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${CREATE_MARKET_CONTENT}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};
	const UploadSectionImageApi = async (formData) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${UPLOAD_SECTION_IMAGE}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			setIsLoading(false);

			console.log("Image uploaded successfully:", res.data);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);

			console.error("Error uploading image:", error);
			return { error: true, message: "Error uploading image" };
		}
	};
	const getMarketById = async (id) => {
		try {
			setIsLoading(true);

			const res = await axios.get(`${ENDPOINT}${GET_MARKET_DATA_ID}?id=${id}`);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);

			return { error: true, data: error.response.data };
		}
	};
	const deleteDateApi = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.delete(`${ENDPOINT}${DELETE_DATE}?id=${id}`);
			openInfoSnackbar(res.data.message);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const updateDateDataApi = async (id, data) => {
		try {
			setIsLoading(true);
			const res = await axios.patch(`${ENDPOINT}${UPDATE_DATE_DATA}?id=${id}`, data, {});
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const deleteMarketSectionApi = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.delete(`${ENDPOINT}${DELETE_MARKET_SECTION_DATA}?id=${id}`);
			openInfoSnackbar(res.data.message);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const updateSectionApi = async (sectionId, newSectionData) => {
		try {
			setIsLoading(true);

			const response = await axios.patch(`${ENDPOINT}${UPDATE_SECTION_DATA}?id=${sectionId}`, newSectionData);
			setIsLoading(false);

			return response.data; // Assuming the response contains relevant data
		} catch (error) {
			setIsLoading(false);

			console.error("Error updating section:", error);
			return { success: false, error: error.message }; // Handle error cases
		}
	};

	const updateSectionImageApi = async (sectionId, formData) => {
		try {
			setIsLoading(true);

			const response = await axios.patch(`${ENDPOINT}${UPDATE_SECTION_IMAGE_DATA}?id=${sectionId}`, formData, {
				headers: { "Content-Type": "multipart/form-data" },
			});
			setIsLoading(false);

			return response.data; // Assuming the response contains relevant data
		} catch (error) {
			setIsLoading(false);

			console.error("Error updating section image:", error);
			return { success: false, error: error.message }; // Handle error cases
		}
	};

	const updateMarketBannerImageApi = async (id, imageData) => {
		try {
			setIsLoading(true);

			const response = await axios.put(`${ENDPOINT}${UPDATE_MARKET_BANNER_IMAGE}?id=${id}`, imageData, {
				headers: { "Content-Type": "multipart/form-data" },
			});
			setIsLoading(false);

			return response.data;
		} catch (error) {
			setIsLoading(false);

			console.error("Error updating section image:", error);
			return { success: false, error: error.message };
		}
	};

	const getPrice = async () => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_PRICE_DATA}`);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);

			return { error: true, data: error.res.data };
		}
	};

	const getPaymentUrl = async (data) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${GET_PAYMENT_URL}`, data);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.res.data };
		}
	};

	const updateSubscription = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${UPDATE_SUBSCRIPTION}`, {
				session_id: id,
			});
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const updateApprovalStatus = async (id, data) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${UPDATE_APPROVAL_STATUS}?id=${id}`, data, {});
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const declineVendorStatus = async (id, data) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${DELETE_APPROVAL_STATUS}?id=${id}`, data, {});
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const updateSubAdminLandingPageContent = async (id, updatedData) => {
		try {
			setIsLoading(true);
			const response = await axios.patch(`${ENDPOINT}${UPDATE_SUBADMIN_DETAIL}?id=${id}`, updatedData);
			setIsLoading(false);

			return response.data;
		} catch (error) {
			setIsLoading(false);
			console.error("Error updating section:", error);
			return { success: false, error: error.message }; // Handle error cases
		}
	};

	const createCategoryApi = async (data) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${CREATE_CATEGORY}`, data, {});
			setIsLoading(false);
			console.log(res, "sdfsdfsd");
			openInfoSnackbar(res.data.message);

			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response?.data?.message || "SubCategory Already Exist");

			return { error: true, data: error.response.data };
		}
	};

	const getAllCategoryApi = async (page, limit) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_ALL_CATEGORY}`, {
				params: { page, limit },
			});
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const getAllCategorySubCategoryApi = async () => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_ALL_CATEGORY_AND_SUBCATEGORY}`);
			setIsLoading(false);
			console.warn("this is the cateorya nd subcategory", res);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const updateCategoryApi = async (id, data) => {
		try {
			setIsLoading(true);
			const res = await axios.patch(
				`${ENDPOINT}${UPDATE_CATEGORY}?id=${id}`,

				data,
				{}
			);
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response?.data?.message || "SubCategory Already Exist");

			return { error: true, data: error.response.data };
		}
	};
	const updateProfileApi = async (id, userData) => {
		try {
			setIsLoading(true);

			const response = await axios.patch(`${ENDPOINT}${UPDATE_PROFILE_DATA}?id=${id}`, userData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			openInfoSnackbar(response.data.message);
			setIsLoading(false);
			await homeApi();
			return response.data;
		} catch (error) {
			setIsLoading(false);

			throw error;
		}
	};

	const updateProfileSettingApi = async (id, userData) => {
		try {
			setIsLoading(true);

			const response = await axios.patch(`${ENDPOINT}${UPDATE_PROFILE_SETTING_DATA}?id=${id}`, userData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			openInfoSnackbar(response.data.message);
			setIsLoading(false);
			await homeApi();
			return response.data;
		} catch (error) {
			setIsLoading(false);

			throw error;
		}
	};

	const addSectionApi = async (id, formData) => {
		try {
			setIsLoading(true);

			const res = await axios.post(`${ENDPOINT}${ADD_SECTION_DATA}?id=${id}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			setIsLoading(false);

			console.log("Data posted successfully:", res.data);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);

			console.error("Error posting data:", error);
			return { error: true, message: "Error posting data" };
		}
	};

	const updateVendorProfileApi = async (id, userData) => {
		try {
			setIsLoading(true);

			const response = await axios.patch(`${ENDPOINT}${UPDATE_VENDOR_PROFILE_DATA}?id=${id}`, userData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			openInfoSnackbar(response.data.message);
			setIsLoading(false);
			// await homeApi()

			return response.data;
		} catch (error) {
			setIsLoading(false);

			throw error;
		}
	};

	const postVendorDate = async (formData) => {
		try {
			setIsLoading(true);

			const res = await axios.post(`${ENDPOINT}${POST_VENDOR_DATE}`, formData);
			console.log("Data posted successfully:", res.data);
			setIsLoading(false);

			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);

			console.error("Error posting data:", error);
			return { error: true, message: "Error posting data" };
		}
	};
	const getDate = async () => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_VENDOR_DATE}`);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const updateTutorialWatchApi = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.patch(`${ENDPOINT}${UPDATE_TUTORIAL_WATCH}?id=${id}`, {});
			setIsLoading(false);
			navigate("/");
			// openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const postmarketData = async (formData) => {
		try {
			setIsLoading(true);

			const res = await axios.post(`${ENDPOINT}${POST_MARKET_APPLY_DATE}`, formData);
			setIsLoading(false);

			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);

			console.error("Error posting data:", error);
			return { error: true, message: "Error posting data" };
		}
	};

	const paymentHistory = async ({ marketDateId }) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_PAYMENT_HISTORY}`, {
				params: { marketDateId },
			});
			setIsAuthorized(true);
			setIsLoading(false);
			return res.data;
		} catch (error) {
			setIsLoading(false);
			console.error(error);
			return null;
		}
	};

	const updateVendorSubscription = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.patch(`${ENDPOINT}${UPDATE_VENDOR_SUBSCRIPTION}`, {
				session_id: id,
			});
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const postMapImage = async (formData) => {
		try {
			// Post the form data to the server
			setIsLoading(true);

			const res = await axios.post(`${ENDPOINT}${POST_MAP_IMAGE_ENDPOINT}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			setIsLoading(false);

			console.log("Image posted successfully:", res.data);
			openInfoSnackbar(res.data.message);

			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);

			openErrorSnackbar(error.response.data.message);

			console.error("Error posting image:", error);
			return { error: true, message: "Error posting image" };
		}
	};

	const searchVendorApi = async (searchQuery) => {
		try {
			// setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${SEARCH_VENDOR_API}?search=${searchQuery}`);
			// setIsLoading(false);
			console.log(res.data);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			navigate("/");
		}
	};

	const searchMarketApi = async (searchQuery) => {
		try {
			// setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${SEARCH_MARKET_API}?search=${searchQuery}`);
			// setIsLoading(false);
			console.log(res.data);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
		}
	};

	const searchMarketDateApi = async (searchQuery) => {
		try {
			// setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${SEARCH_MARKET_API}?search=${searchQuery}`);
			// setIsLoading(false);
			console.log(res.data);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
		}
	};

	const searchCategoryApi = async (searchQuery) => {
		try {
			// setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${SEARCH_CATEGORY_API}?search=${searchQuery}`);
			// setIsLoading(false);
			console.log(res.data);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
		}
	};

	const sendMultiEmailApi = async (data) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${SEND_MULTI_EMAIL}`, { ...data, cc: userData.email }, {});
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const getEmailLogs = async (page, limit) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_EMAIL_LOG}`, {
				params: { page, limit }, // Send page and limit as query parameters
			});
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const reSendEmailApi = async (emailData) => {
		try {
			setIsLoading(true);

			// Assuming ENDPOINT and RESEND_EMAIL_API are defined somewhere
			const res = await axios.post(`${ENDPOINT}${RESEND_EMAIL_API}`, emailData);

			setIsLoading(false);
			openInfoSnackbar(res.data.message); // Assuming openInfoSnackbar is defined to show success messages
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response?.data?.message || "Failed to resend email."); // Assuming openErrorSnackbar is defined to show error messages
			return { error: true, data: error.response?.data || "Network error." };
		}
	};

	const getDashboardDataApi = async () => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_DASHBOARD_DATA}`);
			setIsLoading(false);

			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);

			return { error: true, data: error.response.data };
		}
	};

	const postVendorDateApply = async (formData) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${POST_VENDOR_DATE_APPLY}`, formData);
			console.log("Data posted successfully:", res.data);
			openInfoSnackbar(res.data.message); // Assuming openInfoSnackbar is defined to show success messages
			setIsLoading(false);

			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			console.error("Error posting data:", error);
			return { error: true, message: "Error posting data" };
		}
	};

	const appliedVendorList = async (marketId) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${APPLIED_VENDOR_LIST}?marketId=${marketId}`);
			setIsLoading(false);
			console.log(res.data);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
		}
	};

	const approveVendorDate = async (id) => {
		try {
			setIsLoading(true);

			const res = await axios.get(`${ENDPOINT}${APPROVE_VENDOR_DATE}?id=${id}`);
			setIsLoading(false);

			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);

			return { error: true, data: error.response.data };
		}
	};

	const rejectVendorDate = async (id) => {
		try {
			setIsLoading(true);

			const res = await axios.get(`${ENDPOINT}${REJECT_VENDOR_DATE}?id=${id}`);
			setIsLoading(true);

			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);

			return { error: true, data: error.response.data };
		}
	};

	const vendorAttendanceList = async (marketId, dateId, status) => {
		try {
			setIsLoading(true);

			const res = await axios.get(`${ENDPOINT}${GET_VENDOR_ATTENDACE_LIST}`, {
				params: {
					marketId,
					dateId,
					status,
				},
			});

			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const vendorAttendancePresent = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${VENDOR_ATTENDACE_PRESENT}`, {
				params: {
					id,
				},
			});
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};
	const vendorAttendanceAbsent = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${VENDOR_ATTENDACE_ABSENT}`, {
				params: {
					id,
				},
			});
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const vendorInvoiceList = async (marketId, { startDate, endDate }) => {
		try {
			setIsLoading(true);

			const res = await axios.get(`${ENDPOINT}${GET_VENDOR_INVOICE_LIST}`, {
				params: {
					marketId,
					startDate: startDate.toISOString(),
					endDate: endDate.toISOString(),
				},
			});

			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const generateInvoice = async (data) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${GENERATE_INVOICE}`, data);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.res.data };
		}
	};
	const getAllInvoice = async (page, limit) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_ALL_INVOICE}`, {
				params: { page, limit },
			});
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const getAllInvoiceVendor = async (page, limit) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_ALL_INVOICE_VENDOR}`, {
				params: { page, limit },
			});
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};
	const getAllInvoiceByVendorInvoiceId = async (vendorInvoiceId) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_ALL_INVOICE_ID}?vendorInvoiceId=${vendorInvoiceId}`, {});
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const getAllSubCategoryApi = async (page, limit) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_ALL_SUB_CATEGORY}`, {
				params: { page, limit },
			});
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const createSubCategoryApi = async (data) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${CREATE_SUB_CATEGORY}`, data, {});
			setIsLoading(false);
			console.log(res, "sdfsdfsd");
			openInfoSnackbar(res.data.message);

			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response?.data?.message || "Category Already Exist");

			return { error: true, data: error.response.data };
		}
	};

	const updateSubCategoryApi = async (id, data) => {
		try {
			setIsLoading(true);
			const res = await axios.patch(
				`${ENDPOINT}${UPDATE_SUB_CATEGORY}?id=${id}`,

				data,
				{}
			);
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response?.data?.message || "Category Already Exist");

			return { error: true, data: error.response.data };
		}
	};

	const VendorWaitList = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.put(`${ENDPOINT}${VENDOR_WAITLIST}?id=${id}`, {});
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const getVendorDateInvoicePaymentUrl = async (data) => {
		try {
			setIsLoading(true);

			// Pass the data (which now includes walletAmount) in the POST request
			const res = await axios.post(`${ENDPOINT}${CREATE_VENDOR_DATE_INVOICE}`, data);

			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			// If there's an error, you can log the error response here
			console.error("Error during payment API call:", error);
			return { error: true, data: error.response?.data || "Unknown error" };
		}
	};

	const updateVendorPaymentSubscriptionApi = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.patch(`${ENDPOINT}${UPDATE_VENDOR_PAYMENT}`, {
				session_id: id,
			});
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const searchgetInvoiceSearchHistoryApi = async (searchQuery) => {
		try {
			// setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${SEARCH_INVOICE_HISTORY_API}?search=${searchQuery}`);
			// setIsLoading(false);
			console.log(res.data);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
		}
	};
	const waitListVendorListStatus = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${WAITlIST_THE_VENDOR_ON_VENDOR_LIST}?id=${id}`);
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const getVendorListForMapApi = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_VENDOR_LIST_MAP}?id=${id}`);
			setIsLoading(false);
			return { error: false, data: res.data };
			// navigate("/");
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const getVendorByIdApi = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_VENDOR_BY_ID}?id=${id}`);
			setIsLoading(false);
			return { error: false, data: res.data };
			// navigate("/");
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const getAllVendorApprovalChanges = async (page = 1, limit = 10) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_ALL_VENDOR_APPROVAL_CHANGES}`, {
				params: {
					page: page,
					limit: limit,
				},
			});
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const updateApprovalChanges = async (id, data) => {
		try {
			setIsLoading(true);
			const res = await axios.patch(`${ENDPOINT}${UPDATE_APPROVAL_CHANGES}?id=${id}`);
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};
	const updateDisApprovalChanges = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.patch(`${ENDPOINT}${UPDATE_DISAPPROVAL_CHANGES}?id=${id}`);
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const removeVendorImageApi = async (imageName, dateField) => {
		try {
			setIsLoading(true); // Assuming you have this state in your context or component

			const res = await axios.delete(`${ENDPOINT}${REMOVE_VENDOR_IMAGE}?&imageName=${imageName}&dateField=${dateField}`);

			openInfoSnackbar(res.data.message); // Notification for successful removal
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message); // Notification for error
			return { error: true, data: error.response.data };
		}
	};

	const removeVendorImageApiByMarketOwner = async (imageName, dateField, id) => {
		try {
			setIsLoading(true); // Assuming you have this state in your context or component
			const res = await axios.delete(`${ENDPOINT}${REMOVE_VENDOR_IMAGE_BY_MARKET_OWNER}?vendorId=${id}&imageName=${imageName}&dateField=${dateField}`);
			openInfoSnackbar(res.data.message); // Notification for successful removal
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message); // Notification for error
			return { error: true, data: error.response.data };
		}
	};

	const getVendorAllCategoryApi = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_All_VENDOR_CATEGORIES}?id=${id}`);
			setIsLoading(false);
			return { error: false, data: res.data };
			// navigate("/");
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const getVendorDetailsfilterApi = async (params) => {
		try {
			setIsLoading(true);

			const queryString = new URLSearchParams(params).toString();
			const res = await axios.get(`${ENDPOINT}${GET_ALL_VENDOR_FILTER_LIST}?${queryString}`);

			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response?.data || "An error occurred" };
		}
	};

	const deleteVendorDateApi = async (dateId) => {
		try {
			setIsLoading(true);
			const res = await axios.delete(`${ENDPOINT}${DELETE_VENDOR_APPY_DATE}?id=${dateId}`);
			openInfoSnackbar(res.data.message);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const getStripeKeyApi = async () => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_STRIPE_KEY_DATA}`);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);

			return { error: true, data: error.res.data };
		}
	};

	const postStripeKey = async (stripeKey) => {
		try {
			setIsLoading(true);

			// Sending the stripeKey in the request body
			const res = await axios.post(`${ENDPOINT}${GET_STRIPE_KEY_DATA}`, {
				stripeKey,
			});
			console.log("Stripe key posted successfully:", res.data);
			setIsLoading(false);

			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			console.error("Error posting stripe key:", error);
			return { error: true, message: "Error posting stripe key" };
		}
	};

	const checkEmailApi = async ({ email }) => {
		try {
			const res = await axios.post(`${ENDPOINT}${CHECK_EMAIL}`, { email });
			return { error: false, data: res.data.message };
		} catch (error) {
			return { error: true, data: error.response.data.message };
		} finally {
			setIsLoading(false);
		}
	};

	const createMarketManagerApi = async (data) => {
		try {
			setIsLoading(true);
			const res = await axios.post(
				`${ENDPOINT}${CREATE_MARKET_MANAGER_API}`,

				data,
				{}
			);
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response?.data?.message || "Category Already Exist");

			return { error: true, data: error.response.data };
		}
	};

	const updateMarketManagerApi = async (data) => {
		try {
			setIsLoading(true);
			const res = await axios.patch(
				`${ENDPOINT}${CREATE_MARKET_MANAGER_API}`,

				data,
				{}
			);
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response?.data?.message || "Category Already Exist");

			return { error: true, data: error.response.data };
		}
	};

	const getAllMarketManagerApi = async () => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_MARKET_MANAGER_API}`, {});
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const getAllMarketManagerSingleApi = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.put(`${ENDPOINT}${GET_MARKET_MANAGER_API}?id=${id}`);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};
	const deleteMarketManagerApi = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.delete(`${ENDPOINT}${GET_MARKET_MANAGER_API}?id=${id}`);
			openInfoSnackbar(res.data.message);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const getAllMarketForManagerApi = async () => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_ALL_MARKET_MANAGER_API}`, {});
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const getAllVendorDate = async () => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_ALL_VENDOR_DATE}`);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};
	const changeVendorDateApi = async (addMarketDateIds, removeVendorDateIds, reason) => {
		try {
			setIsLoading(true); // Assuming `setIsLoading` is available in the scope
			const res = await axios.put(`${ENDPOINT}${CHANGE_VENDOR_DATE_API}`, {
				addMarketDateIds,
				removeVendorDateIds,
				reason,
			});
			setIsLoading(false); // Stop loading after the response
			openInfoSnackbar(res.data.message); // Assuming `openInfoSnackbar` is available
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false); // Stop loading if error occurs
			openErrorSnackbar(error.response?.data?.message || "An error occurred"); // Assuming `openErrorSnackbar` is available
			return { error: true, data: error.response?.data };
		}
	};

	const getAllChangeDateRequeat = async () => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_ALL_CHANGE_REQUEST_DATE}`);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const approveDateChangeApi = async (id, reason) => {
		try {
			setIsLoading(true);

			// Prepare the request payload as per the required format
			const requestData = {
				vendorDateIds: [id], // The ID is wrapped inside an array
				action: "approve", // The action is hardcoded to "approve"
				reason: reason, // Reason passed from the component
			};

			// Sending the POST request with the formatted data
			const res = await axios.post(`${ENDPOINT}${UPDATE_APPROVAL_DATE_CHANGE}`, requestData);

			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response?.data?.message || "An error occurred");
			return { error: true, data: error.response?.data };
		}
	};
	const disapproveDateChangeApi = async (id, reason) => {
		try {
			setIsLoading(true);

			// Prepare the request payload as per the required format
			const requestData = {
				vendorDateIds: [id], // The ID is wrapped inside an array
				action: "rejected", // The action is hardcoded to "approve"
				reason: reason, // Reason passed from the component
			};

			// Sending the POST request with the formatted data
			const res = await axios.post(`${ENDPOINT}${UPDATE_APPROVAL_DATE_CHANGE}`, requestData);

			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response?.data?.message || "An error occurred");
			return { error: true, data: error.response?.data };
		}
	};

	// const getVendorWalletAmount = async (vendorId, page , limit ) => {
	//   try {
	//     setIsLoading(true);

	//     // Prepare the payload to send in the body
	//     const payload = {
	//       vendorId: vendorId,
	//       page: page,
	//       limit: limit,
	//     };

	//     console.warn("Sending Payload:", payload);

	//     // Make the GET request with the payload in the body
	//     const config = {
	//       method: 'get',  // Specify the method as 'get'
	//       url: `${ENDPOINT}${GET_VENDOR_WALLET}`, // Your endpoint here
	//       data: payload,  // Send the payload in the body
	//       headers: {
	//         'Content-Type': 'application/json',  // Make sure it's JSON
	//       }
	//     };

	//     // Send the GET request with custom config
	//     const res = await axios(config);

	//     setIsLoading(false);
	//     return { error: false, data: res.data };
	//   } catch (error) {
	//     setIsLoading(false);
	//     return { error: true, data: error.response.data };
	//   }
	// };

	const getVendorWalletAmount = async (vendorId, page, limit) => {
		console.warn("))))))))))((((((((((((", vendorId);
		try {
			setIsLoading(true);

			const res = await axios.post(`${ENDPOINT}${GET_VENDOR_WALLET}`, {
				vendorId: vendorId,
				page: page,
				limit: limit,
			});

			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			// Return the error response data if available
			return { error: true, data: error.response ? error.response.data : error.message };
		}
	};

	const getEmailTemplates = async (page, limit, userType) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_EMAIL_TEMPLATES}?userType=${userType}`, {
				params: { page, limit }, // Send page and limit as query parameters
			});
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const copyEmailTemplates = async (updatedContent) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${COPY_EMAIL_TEMPLATES}`, updatedContent);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const createEmailTemplates = async (updatedContent) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${CREATE_EMAIL_TEMPLATES}`, updatedContent);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const updateEmailTemplates = async (id, updatedContent) => {
		try {
			setIsLoading(true);
			const res = await axios.patch(`${ENDPOINT}${UPDATE_EMAIL_TEMPLATES}?id=${id}`, updatedContent);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const deleteEmailTemplates = async (id) => {
		try {
			setIsLoading(true);
			const res = await axios.delete(`${ENDPOINT}${DELETE_EMAIL_TEMPLATES}?id=${id}`);
			setIsLoading(false);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const removeMarketSequenceApi = async (ids) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${REMOVE_MARKET_SEQUENCE}`, ids);
			setIsLoading(false);
			return { error: false, data: res.data };
			// navigate("/");
		} catch (error) {
			setIsLoading(false);
			return { error: true, data: error.response.data };
		}
	};

	const postMarketOwnerBankDetailApi = async (data) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${POST_MARKET_ACCOUNT_DETAIL}`, data, {});
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};
	const getMarketOwnerBankDetail = async (marketId) => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_MARKET_OWNER_BANK_DETAILS}?id=${marketId}`);
			setIsLoading(false);
			console.log(res.data);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
		}
	};
	const postVendorManualTransaction = async (data) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${POST_VENDOR_MANUAL_TRANSACTION}`, data, {});
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};
	const updateVendorTransactionStatus = async (data) => {
		try {
			setIsLoading(true);
			const res = await axios.post(`${ENDPOINT}${UPDATE_VENDOR_MANUAL_TRANSACTION_STATUS}`, data, {});
			setIsLoading(false);
			openInfoSnackbar(res.data.message);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
			openErrorSnackbar(error.response.data.message);
			return { error: true, data: error.response.data };
		}
	};

	const getMarketOwnerPaymentList = async () => {
		try {
			setIsLoading(true);
			const res = await axios.get(`${ENDPOINT}${GET_PAYMENT_LIST_VIA_BANKTRANSFER}`);
			setIsLoading(false);
			console.log(res.data);
			return { error: false, data: res.data };
		} catch (error) {
			setIsLoading(false);
		}
	};
	return {
		homeApi,
		loginApi,
		logOutApi,
		createMarketApi,
		getAllMarket,
		deleteMarketApi,
		updateMarketApi,
		updateMarketImage,
		forgetPasswordApi,
		changePasswordOTP,
		getAllVendor,
		updateVendorApi,
		deleteVendorApi,
		postModalDate,
		createSubAdminDetailApi,
		UploadSectionImageApi,
		getMarketById,
		deleteDateApi,
		updateDateDataApi,
		deleteMarketSectionApi,
		updateSectionApi,
		updateSectionImageApi,
		getPrice,
		getPaymentUrl,
		updateSubscription,
		updateApprovalStatus,
		updateMarketBannerImageApi,
		updateSubAdminLandingPageContent,
		createCategoryApi,
		getAllCategoryApi,
		updateCategoryApi,
		updateProfileApi,
		addSectionApi,
		updateVendorProfileApi,
		postVendorDate,
		getDate,
		updateTutorialWatchApi,
		postmarketData,
		paymentHistory,
		updateVendorSubscription,
		postMapImage,
		searchVendorApi,
		searchMarketApi,
		searchMarketDateApi,
		searchCategoryApi,
		sendMultiEmailApi,
		getEmailLogs,
		reSendEmailApi,
		getDashboardDataApi,
		declineVendorStatus,
		postVendorDateApply,
		appliedVendorList,
		approveVendorDate,
		rejectVendorDate,
		vendorAttendanceList,
		vendorAttendancePresent,
		vendorAttendanceAbsent,
		vendorInvoiceList,
		generateInvoice,
		getAllInvoice,
		getAllSubCategoryApi,
		createSubCategoryApi,
		updateSubCategoryApi,
		getAllCategorySubCategoryApi,
		VendorWaitList,
		updateMarketManagerApi,
		getVendorDateInvoicePaymentUrl,
		getAllInvoiceByVendorInvoiceId,
		updateVendorPaymentSubscriptionApi,
		searchgetInvoiceSearchHistoryApi,
		waitListVendorListStatus,
		getVendorListForMapApi,
		getAllInvoiceVendor,
		getVendorByIdApi,

		getAllVendorApprovalChanges,
		updateApprovalChanges,
		updateDisApprovalChanges,
		removeVendorImageApi,
		removeVendorImageApiByMarketOwner,

		getVendorAllCategoryApi,
		removeMarketSequenceApi,
		getVendorDetailsfilterApi,
		updateProfileSettingApi,
		deleteVendorDateApi,
		getStripeKeyApi,
		changeVendorDateApi,
		postStripeKey,
		checkEmailApi,
		createMarketManagerApi,
		getAllMarketManagerApi,
		getAllMarketManagerSingleApi,
		deleteMarketManagerApi,
		getAllMarketForManagerApi,
		getAllVendorDate,
		getAllChangeDateRequeat,
		disapproveDateChangeApi,
		approveDateChangeApi,
		getVendorWalletAmount,
		getEmailTemplates,
		createEmailTemplates,
		updateEmailTemplates,
		deleteEmailTemplates,
		copyEmailTemplates,
		postMarketOwnerBankDetailApi,
		getMarketOwnerBankDetail,
		postVendorManualTransaction,
		updateVendorTransactionStatus,
		getMarketOwnerPaymentList,
		// createsubAdminApi,
		// getAllSubAdmingetPrice
	};
};

export default useApiAxios;
