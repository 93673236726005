import React, { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link } from "react-router-dom";
import moment from "moment"; // Import moment
import { Context } from "../../context/Context";

const VendorPaymentList = () => {
  document.title = "Eventease360 | Payment Requests";
  const {
    getAllInvoiceVendor,
    getAllVendor,
    searchgetInvoiceSearchHistoryApi,
    getVendorDateInvoicePaymentUrl,
    updateVendorPaymentSubscriptionApi,
    getMarketOwnerBankDetail,
    postVendorManualTransaction
  } = useApiAxios();
  const { userData } = useContext(Context);
  console.warn("USER DATA", userData);
  const ownerId = userData.vendor.owner;
  console.warn("owner id ", ownerId);

  const [payments, setPayments] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const [useWallet, setUseWallet] = useState(false);
  const [manualTransactionId, setManualTransactionId] = useState(""); // State to store entered transaction ID
  const [transactionIdError, setTransactionIdError] = useState("");

  const [bankPaymentDetails, setBankPaymentDetails] = useState({
    bankAccount: "",
    ifscCode: "",
    customerName: "",
    paymentAmount: 0
  });

  const [ownerBankDetails, setOwnerBankDetails] = useState(null); // To hold the fetched bank details

  const handleWalletChange = (event) => {
    setUseWallet(event.target.checked);
  };

  const fetchData = async (page = 1, limit = 1000) => {
    try {
      const vendorResponse = await getAllVendor();
      setVendors(vendorResponse.data.data);

      const invoiceResponse = await getAllInvoiceVendor(page, limit);

      setPayments(invoiceResponse.data.data);
      setTotalPages(invoiceResponse.data.data.totalPages);
      setLoading(false);
    } catch (error) {
      setError("Error fetching data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handleSearch = async (event) => {
    try {
      const query = event.target.value;
      setSearchQuery(query);
      const response = await searchgetInvoiceSearchHistoryApi(query);
      setPayments(response.data.data);
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const openPaymentModal = async (payment) => {
    setSelectedPayment(payment);
    setModalVisible(true);

    // Fetch bank details when the modal is opened
    try {
      const bankResponse = await getMarketOwnerBankDetail(ownerId);
      setOwnerBankDetails(bankResponse.data.data); // Store the fetched bank details
    } catch (error) {
      console.error("Error fetching bank details:", error);
      setErrorMessage("Error fetching bank details.");
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedPayment(null);
    setBankPaymentDetails({
      bankAccount: "",
      ifscCode: "",
      customerName: "",
      paymentAmount: 0
    });
    setOwnerBankDetails(null); // Clear bank details when closing the modal
  };

  const DateComponent = (data) => {
    const formatDate = (dateString) => {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return data.map((item) => formatDate(item.dateId.date)).join(", ");
  };

  const makePaymentHandler = async (id, walletAmount) => {
    try {
      const totalAmount =
        selectedPayment.price * selectedPayment.vendorDates.length;
      let finalWalletAmount = 0;

      if (walletAmount >= totalAmount) {
        finalWalletAmount = totalAmount;
        const remainingWalletAmount =
          selectedPayment.vendorId.walletAmount - finalWalletAmount;
        if (remainingWalletAmount < 0) {
          finalWalletAmount = selectedPayment.vendorId.walletAmount;
        }
        await updateVendorPaymentSubscriptionApi(id, remainingWalletAmount);
      } else {
        finalWalletAmount = walletAmount;
      }

      const { data, error } = await getVendorDateInvoicePaymentUrl({
        vendorInvoiceId: id,
        walletAmount: finalWalletAmount
      });

      if (error || !data || !data.data.sessionUrl) {
        setErrorMessage("Failed to initiate payment. Please try again.");
        return;
      }

      window.location.href = data.data.sessionUrl;
    } catch (err) {
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };

  const handleManualPayment = async (
    marketOwnerId,
    transactionId,
    accountId,
    amount,
    vendorInvoiceId
  ) => {
    try {
      // Validate if transaction ID is entered
      if (!transactionId) {
        setTransactionIdError("Transaction ID is required.");
        return; // Stop the payment process if no transaction ID is entered
      }

      // Reset the error if the field is filled
      setTransactionIdError("");

      // Proceed with the payment if the transaction ID is valid
      const payload = {
        marketOwnerId,
        transactionId, // Use the transaction ID provided by the user
        accountId, // This is the account number
        amount,
        vendorInvoiceId
      };

      // Call the API to post the manual transaction
      const response = await postVendorManualTransaction(payload);

      // Check for the response and handle success or failure
      if (response.error) {
        setErrorMessage("Error posting manual transaction. Please try again.");
      } else {
        // Handle successful payment submission
        console.log("Manual transaction successful:", response.data);

        // Close the modal after successful payment
        closeModal();

        // Optionally, you can add a success message or handle further UI updates
        // setSuccessMessage("Payment successfully processed.");
      }
    } catch (error) {
      console.error("Error calling postVendorManualTransaction:", error);
      setErrorMessage("An error occurred while processing the transaction.");
    }
  };

  return (
    <div>
      <div className="panel-header">
        <div className="page-inner pb-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Payment Requests</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/vendor/paymenthistory">Payment Requests</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {errorMessage && (
        <div
          className="mt-3 alert alert-danger"
          style={{ padding: "15px 30px" }}
        >
          {errorMessage}
        </div>
      )}

      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Sub Admin Name</th>
                      <th>Sub Admin Email</th>
                      <th>Vendor Name</th>
                      <th>Payment Date</th>
                      <th>Price</th>
                      <th>Date</th>
                      <th>Payment Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.length > 0 ? (
                      payments.map((payment) => {
                        const vendorDatesLength =
                          payment?.vendorDates?.length || 0;
                        const calculatedPrice =
                          payment?.price * vendorDatesLength;
                        return (
                          <tr key={payment._id}>
                            <td>{payment?.subAdmin?.name}</td>
                            <td>{payment?.subAdmin?.email}</td>
                            <td>{payment?.vendorId?.businessName}</td>
                            <td>
                              {new Date(
                                payment?.vendorId?.updatedAt
                              ).toLocaleDateString("en-US", {
                                weekday: "short",
                                year: "numeric",
                                month: "short",
                                day: "numeric"
                              })}
                            </td>
                            <td>${calculatedPrice}</td>
                            <td>{DateComponent(payment?.vendorDates)}</td>
                            <td>
                              {payment.status === "InvoiceSend" ? (
                                <button
                                  onClick={() => openPaymentModal(payment)}
                                  className="btn btn-primary btn-sm"
                                >
                                  Pay
                                </button>
                              ) : (
                                payment.status
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6">No payments found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalVisible && selectedPayment && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Payment Details</h5>
                {/* <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                ></button> */}

                <button type="button" className="close" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <p>
                      <strong>Vendor Name:</strong>{" "}
                      {selectedPayment.vendorId.businessName}
                    </p>
                    <p>
                      <strong>Sub Admin:</strong>{" "}
                      {selectedPayment.subAdmin.name} (
                      {selectedPayment.subAdmin.email})
                    </p>
                    <p>
                      <strong>Vendor Price:</strong> ${selectedPayment.price}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      <strong>Payment Date:</strong>{" "}
                      {new Date(
                        selectedPayment.vendorId.updatedAt
                      ).toLocaleDateString()}
                    </p>
                    <p>
                      <strong>Payment Status:</strong> {selectedPayment.status}
                    </p>
                  </div>
                </div>

                <div className="mb-3">
                  <strong>Wallet Amount:</strong> $
                  {selectedPayment.vendorId.walletAmount}
                  <input
                    type="checkbox"
                    id="useWallet"
                    checked={useWallet}
                    onChange={handleWalletChange}
                    className="ms-2"
                  />
                  <label className="form-check-label" htmlFor="useWallet">
                    Use Wallet Amount
                  </label>
                </div>

                <div className="mb-3">
                  <strong>Total Price:</strong> $
                  {selectedPayment.price * selectedPayment.vendorDates.length}
                </div>

                {useWallet && selectedPayment.vendorId.walletAmount > 0 && (
                  <div className="mb-3">
                    <strong>Remaining Payment After Wallet Deduction:</strong> $
                    {selectedPayment.price *
                      selectedPayment.vendorDates.length >
                    selectedPayment.vendorId.walletAmount
                      ? (
                          selectedPayment.price *
                            selectedPayment.vendorDates.length -
                          selectedPayment.vendorId.walletAmount
                        ).toFixed(2)
                      : "0.00"}
                  </div>
                )}

                {/* Transaction ID Input Field */}
                <div className="mb-3">
                  <label htmlFor="transactionId" className="form-label">
                    Transaction ID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="transactionId"
                    value={manualTransactionId}
                    onChange={(e) => setManualTransactionId(e.target.value)} // Update state as user types
                    placeholder="Enter transaction ID"
                  />
                  {transactionIdError && (
                    <div className="text-danger mt-1">{transactionIdError}</div>
                  )}
                </div>

                {/* Bank Account Details - Always visible now */}
                {ownerBankDetails && (
                  <div className="row mb-4 py-3 border rounded-3 shadow-sm bg-light">
                    <div className="col-md-3 mb-3">
                      <p className="text-muted mb-1">
                        <strong>Account Number:</strong>
                      </p>
                      <p className="mb-0 text-primary fw-semibold">
                        {ownerBankDetails.accountNumber}
                      </p>
                    </div>
                    <div className="col-md-3 mb-3">
                      <p className="text-muted mb-1">
                        <strong>BSB Code:</strong>
                      </p>
                      <p className="mb-0 text-primary fw-semibold">
                        {ownerBankDetails.ifscCode}
                      </p>
                    </div>
                    <div className="col-md-3 mb-3">
                      <p className="text-muted mb-1">
                        <strong>Bank Name:</strong>
                      </p>
                      <p className="mb-0 text-primary fw-semibold">
                        {ownerBankDetails.bankName}
                      </p>
                    </div>
                    <div className="col-md-3 mb-3">
                      <p className="text-muted mb-1">
                        <strong>Account Holder Name:</strong>
                      </p>
                      <p className="mb-0 text-primary fw-semibold">
                        {ownerBankDetails.customerName}
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>
                    makePaymentHandler(
                      selectedPayment._id,
                      useWallet ? selectedPayment.vendorId.walletAmount : 0
                    )
                  }
                >
                  Proceed with Payment
                </button>
                {/* <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => handleManualPayment(
              ownerId, // marketOwnerId
              manualTransactionId, // user-entered transactionId
              ownerBankDetails?.accountNumber, // accountId (account number from bank details)
              selectedPayment.price * selectedPayment.vendorDates.length // amount
            )}
          >
            Pay Via Bank Account
          </button> */}

                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    // Call handleManualPayment only if the transactionId is valid
                    if (!manualTransactionId) {
                      setTransactionIdError("Transaction ID is required.");
                    } else {
                      handleManualPayment(
                        ownerId, // marketOwnerId
                        manualTransactionId, // user-entered transactionId
                        ownerBankDetails?.accountNumber, // accountId (account number from bank details)
                        selectedPayment.price *
                          selectedPayment.vendorDates.length, // amount
                        selectedPayment._id
                      );
                    }
                  }}
                >
                  Pay Via Bank Account
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorPaymentList;
