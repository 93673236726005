import React, { useContext, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Context } from "../../context/Context";
import useApiAxios from "../../api/useApiAxios";
import "./Tutorial.css"; // Import custom CSS for styling

const Tutorial = () => {
	document.title = "Eventease360 | Tutorial";
	const [currentStep, setCurrentStep] = useState(0);
	const { userData } = useContext(Context);
	const { updateTutorialWatchApi, homeApi } = useApiAxios();
	const navigate = useNavigate();
	const location = useLocation();
	const [user, setUser] = useState();

	const tutorial = {
		title: "Welcome! to Eventease360",
		steps: [
			{
				step_number: 1,
				title: "Create a Category!",
				description: "Thank you for visiting our website. Please create a category before creating any market.",
				image_url: "assets/img/tutorial/category.PNG",
			},
			{
				step_number: 2,
				title: "Create Market",
				description: "Once you create a category, create a market by clicking the market tab in the sidebar, then click on the 'Create Market' button.",
				image_url: "assets/img/tutorial/marketCreate.PNG",
			},
			{
				step_number: 3,
				title: "Payment for the Market",
				description: "After creating the market, market details will be displayed. Click the 'Date & Content' tab and make the payment by clicking the 'Make Payment' button.",
				image_url: "assets/img/tutorial/marketDate.PNG",
			},
			{
				step_number: 4,
				title: "Add Content and Edit Content for Vendor",
				description: "Add content for the market landing page by clicking the 'Add Content' button. Similarly, you can edit the content by clicking the 'Edit Content' button.",
				image_url: "assets/img/tutorial/addContent.png",
			},
			{
				step_number: 5,
				title: "Create a Date",
				description: "Add the date by clicking the 'Add Date' button. This date is used further when the vendor chooses a date for their stall.",
				image_url: "assets/img/tutorial/createDate.PNG",
			},
			{
				step_number: 6,
				title: "Download HTML",
				description:
					"Click the 'Download HTML' button to download an HTML file containing the content added via the 'Add Content' button. Market owners can add this file to their domain to publish their market for marketing. On the market landing page, vendors can sign up and add their slot for a stall on a specific date using the 'Apply Here' form on the website.",
				image_url: "assets/img/tutorial/downloadHtml.png",
			},
		],
	};

	const { title, steps } = tutorial;

	const handleNextStep = () => setCurrentStep(currentStep + 1);
	const handlePrevStep = () => setCurrentStep(currentStep - 1);
	const handleFinish = async () => {
		if (location.pathname !== "/tutorial") {
			await updateTutorialWatchApi(userData._id);
			try {
				const res = await homeApi();
				setUser(res.data);
				navigate("/");
			} catch (error) {
				console.error("Error fetching user data:", error);
			}
		}
	};

	const isLastStep = currentStep === steps.length - 1;
	const isFirstStep = currentStep === 0;
	const stepsLeft = steps.length - currentStep - 1;

	return (
		<>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Tutorial</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<Link to="#">Tutorial</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="text-center">
									<h2 className="tutorial-title">Hi, {title}</h2>
									<h3 className="strong">{steps[currentStep].title}</h3>
									<p>{steps[currentStep].description}</p>
								</div>
								<div>
									<div className="container shadow p-0">
										{steps[currentStep].image_url && <img src={steps[currentStep].image_url} alt={`Step ${currentStep + 1}`} className="tutorial-image" />}
									</div>
									<div className="container p-0 mt-2">
										<p className="tutorial-step-info">{stepsLeft > 0 ? `Step ${currentStep + 1} of ${steps.length}` : "Last step"}</p>
									</div>
									<div className="tutorial-actions mb-3">
										{!isFirstStep && (
											<button className="tutorial-button" onClick={handlePrevStep}>
												<i className="fa fa-arrow-left" /> Back
											</button>
										)}
										{!isLastStep && location.pathname !== "/tutorial" && (
											<button className="tutorial-button skip-button" onClick={handleFinish}>
												Skip
											</button>
										)}
										{!isLastStep && (
											<button className="tutorial-button next-button" onClick={handleNextStep}>
												Next <i className="fa fa-arrow-right" />
											</button>
										)}
										{isLastStep && (
											<button className="tutorial-button" onClick={handleFinish}>
												Finish
											</button>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Tutorial;
