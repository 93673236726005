import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import { format } from "date-fns"; // Import format from date-fns
import ReactPaginate from "react-paginate";

const ChangeDateRequest = () => {
	document.title = "Eventease360 | Date Change Request";
	const { getAllChangeDateRequeat, disapproveDateChangeApi, approveDateChangeApi } = useApiAxios();
	const [combinedMarketDates, setCombinedMarketDates] = useState([]);

	// Fetch and combine data
	const fetchChangeDateRequestData = async () => {
		try {
			const { error, data } = await getAllChangeDateRequeat();
			console.warn("Fetched data:", data); // Debugging: check API response

			if (!error && data) {
				// Combine added and removed market dates into one array
				const addedData = data?.data?.addedMarketDates.map((request) => ({
					...request,
					type: "Added", // Identify the type (added)
				}));
				const removedData = data?.data?.removedMarketDates.map((request) => ({
					...request,
					type: "Removed", // Identify the type (removed)
				}));

				// Combine both arrays
				setCombinedMarketDates([...addedData, ...removedData]);
			} else {
				console.error("Error fetching market data:", data);
			}
		} catch (error) {
			console.error("Error fetching market data:", error);
		}
	};

	useEffect(() => {
		fetchChangeDateRequestData();
	}, []);

	const handleApprove = async (request) => {
		const requestId = request._id; // Extract the request ID
		const reason = "Approved for processing"; // The reason can be dynamic, but it's hardcoded here

		console.log("Approved request: ", request);

		try {
			// Call approveDateChangeApi with the ID and reason
			const { error, data } = await approveDateChangeApi(requestId, reason);

			if (!error && data) {
				console.log("Successfully approved request:", data);

				// Refetch data after successful approval
				fetchChangeDateRequestData();
			} else {
				console.error("Error approving request:", data);
			}
		} catch (error) {
			console.error("Error during approve API call:", error);
		}
	};

	const handleDecline = async (request) => {
		const requestId = request._id; // Extract the request ID
		const reason = "Declined for processing"; // The reason can be dynamic, but it's hardcoded here

		try {
			// Call disapproveDateChangeApi with the ID and reason
			const { error, data } = await disapproveDateChangeApi(requestId, reason);

			if (!error && data) {
				console.log("Successfully rejected request:", data);

				// Refetch data after successful rejection
				fetchChangeDateRequestData();
			} else {
				console.error("Error rejecting request:", data);
			}
		} catch (error) {
			console.error("Error during decline API call:", error);
		}
	};

	useEffect(() => {
		// Set page title dynamically
		fetchChangeDateRequestData();
	}, []);

	return (
		<>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Date Change Request</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<Link to="/changedaterequest">Date Change Request</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								{combinedMarketDates.length > 0 ? (
									<div>
										<table className="table table-hover">
											<thead>
												<tr>
													<th>Date</th>
													<th>Market Name</th>
													<th>Vendor Name</th>
													<th>Reason</th>
													<th>Status</th>
													<th>Actions</th>
												</tr>
											</thead>
											<tbody>
												{combinedMarketDates.map((request, index) => (
													<tr key={index}>
														<td>
															{request.dateId.date
																? format(new Date(request.dateId.date), "dd MMMM yyyy") // Format as "23 March 2024"
																: "N/A"}
														</td>
														<td>{request.market.name}</td>
														<td>{request.vendor.businessName}</td>
														<td>{request.reason || "No reason provided"}</td>
														<td>{request.status === "addRequest" ? "Date Add Request" : "Date Remove Request"}</td>

														<td>
															<button className="btn btn-success btn-sm mr-1" onClick={() => handleApprove(request)}>
																Approve
															</button>
															<button className="btn btn-danger btn-sm" onClick={() => handleDecline(request)}>
																Decline
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
										<div className="d-flex justify-content-between align-items-center pagination-container">
											<ReactPaginate
												previousLabel={"Previous"}
												nextLabel={"Next"}
												breakLabel={"..."}
												pageCount={0}
												marginPagesDisplayed={2}
												pageRangeDisplayed={5}
												onPageChange=""
												containerClassName={"pagination m-0"}
												pageClassName={"page-item"}
												pageLinkClassName={"page-link"}
												previousClassName={"page-item"}
												previousLinkClassName={"page-link"}
												nextClassName={"page-item"}
												nextLinkClassName={"page-link"}
												breakClassName={"page-item"}
												breakLinkClassName={"page-link"}
												activeClassName={"active"}
											/>
										</div>
									</div>
								) : (
									<p>No change date requests available.</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ChangeDateRequest;
