import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import useApiAxios from "../../api/useApiAxios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import DragsAndDrop from "../../image/DragsAndDrop";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS for styling

const VendorProfile = () => {
	document.title = "Eventease360 | Vendor Profile";
	const { updateVendorProfileApi, removeVendorImageApi, getVendorAllCategoryApi, getVendorAllSubCategoryApi } = useApiAxios();

	const [showPassword, setShowPassword] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [imageRemoved, setImageRemoved] = useState(false);

	const { userData } = useContext(Context);

	console.warn("IT is USerdata", userData);

	const [categories, setCategories] = useState([]);

	const [subCategories, setSubCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedSubCategory, setSelectedSubCategory] = useState("");

	const [imageSelected, setImageSelected] = useState({
		productImage1: false,
		productImage2: false,
		productImage3: false,
	});

	const [imageLicenseSelected, setImageLicenseSelected] = useState({
		otherLicenseImage: false,
		publicLiabilityInsurance: false,
		foodRegistrationImage: false,
		liquorLicenseImage: false,
	});

	const [userInfo, setUserInfo] = useState({
		businessName: userData.vendor.businessName,
		email: userData.vendor.email,
		contactPerson: userData.vendor.contactPerson,
		addProductMessage: userData.vendor.addProductMessage,
		businessDescription: userData.vendor.businessDescription,
		contactNumber: userData.vendor.contactNumber,
		// password: "",
		instagram: userData.vendor.instagram,
		facebook: userData.vendor.facebook,
		twitter: userData.vendor.twitter,
		tikTok: userData.vendor.tikTok,
		productImage1: userData.vendor.productImage1 || "", // Ensure it's a string
		productImage2: userData.vendor.productImage2 || "",
		productImage3: userData.vendor.productImage3 || "",
		publicLiabilityInsurance: userData.vendor.publicLiabilityInsurance,
		foodRegistrationImage: userData.vendor.foodRegistrationImage,
		liquorLicenseImage: userData.vendor.liquorLicenseImage,
		otherLicenseImage: userData.vendor.otherLicenseImage,
		insuranceExpiryDate: userData.vendor.insuranceExpiryDate || null,
		expireLicenseDate: userData.vendor.expireLicenseDate || null,
		liquorLicenseExpiryDate: userData.vendor.liquorLicenseExpiryDate || null,
		otherLicenseExpiryDate: userData.vendor.otherLicenseExpiryDate || null,
	});

	const validationSchema = Yup.object({
		businessName: Yup.string().required("Business Name is required"),
		email: Yup.string().email("Invalid email format").required("Email is required"),
		addProductMessage: Yup.string().required("Product Message is required"),
		businessDescription: Yup.string().required("Business Description is required"),
		contactPerson: Yup.string().required("Contact Person is required"),
		contactNumber: Yup.string()
			.matches(/^[0-9]+$/, "Contact Number must contain only numeric digits")
			.min(10, "Contact Number must be exactly 10 digits")
			.max(10, "Contact Number must be exactly 10 digits")
			.required("Contact Number is required"),
		instagram: Yup.string().url("Invalid URL"),
		facebook: Yup.string().url("Invalid URL"),
		twitter: Yup.string().url("Invalid URL"),
		tikTok: Yup.string().url("Invalid URL"),
		// password: Yup.string().min(6, "Password must be at least 6 characters"),
	});

	useEffect(() => {
		if (userData) {
			setUserInfo({
				businessName: userData.vendor.businessName,
				email: userData.vendor.email,
				contactPerson: userData.vendor.contactPerson,
				businessDescription: userData.vendor.businessDescription,
				addProductMessage: userData.vendor.addProductMessage,
				contactNumber: userData.vendor.contactNumber,

				instagram: userData.vendor.instagram,
				facebook: userData.vendor.facebook,
				twitter: userData.vendor.twitter,
				tikTok: userData.vendor.tikTok,

				productImage1: userData.vendor.productImage1,
				productImage2: userData.vendor.productImage2,
				productImage3: userData.vendor.productImage3,
				publicLiabilityInsurance: userData.vendor.publicLiabilityInsurance,
				foodRegistrationImage: userData.vendor.foodRegistrationImage,
				liquorLicenseImage: userData.vendor.liquorLicenseImage,
				otherLicenseImage: userData.vendor.otherLicenseImage,
				insuranceExpiryDate: userData.vendor.insuranceExpiryDate || null,
				expireLicenseDate: userData.vendor.expireLicenseDate || null,
				liquorLicenseExpiryDate: userData.vendor.liquorLicenseExpiryDate || null,
				otherLicenseExpiryDate: userData.vendor.otherLicenseExpiryDate || null,
			});
		}
	}, [userData]);

	const handleImageUpload = (file, imageKey) => {
		const newUserInfo = { ...userInfo, [imageKey]: file };
		setUserInfo(newUserInfo);
		setImageRemoved(true);
	};

	const handleDateChange = (date, dateKey) => {
		console.warn("Selected date:", date);
		if (date) {
			const newUserInfo = { ...userInfo, [dateKey]: date };
			console.warn("Updating userInfo:", newUserInfo); // Log before updating

			setUserInfo(newUserInfo);
		}
	};

	const handleRemoveImage = async (imageName, dateField) => {
		await removeVendorImageApi(imageName, dateField);
		setImageRemoved(true);
	};

	const handleSubmit = async (values, { setSubmitting }) => {
		console.log(values);
		let imageRemoved = false; // Track if an image was removed
		try {
			setSubmitting(true);
			const formData = new FormData();
			// if (values.publicLiabilityInsurance) {
			//   // if (values[key] && values[key] !== userInfo[key]) {
			//   formData.append(
			//     "publicLiabilityInsurance",
			//     values.publicLiabilityInsurance
			//   ); // Send the new binary file
			//   // }
			// }

			// if (values.foodRegistrationImage) {
			//   // if (values[key] && values[key] !== userInfo[key]) {
			//   formData.append("foodRegistrationImage", values.foodRegistrationImage); // Send the new binary file
			//   // }
			// }
			// if (values.liquorLicenseImage) {
			//   // if (values[key] && values[key] !== userInfo[key]) {
			//   formData.append("liquorLicenseImage", values.liquorLicenseImage); // Send the new binary file
			//   // }
			// }

			// if (values.otherLicenseImage) {
			//   // if (values[key] && values[key] !== userInfo[key]) {
			//   formData.append("otherLicenseImage", values.otherLicenseImage); // Send the new binary file
			//   // }
			// }

			for (const key in values) {
				// Check if the image is removed
				if (userInfo[key] === null && key.endsWith("Image")) {
					imageRemoved = true; // Set imageRemoved to true if an image is removed
					continue; // Don't append anything for removed images
				}

				// Append new image if it exists
				if (key.startsWith("productImage") || key.endsWith("Image")) {
					if (values[key] && values[key] !== userInfo[key]) {
						formData.append(key, values[key]); // Send the new binary file
					}
					continue; // Skip to the next iteration
				}

				console.log(formData);
				// console.log (values)

				// formData.append("otherLicenseImage" , values.otherLicenseImage);
				// Append other values if they have changed
				if (values[key] !== userInfo[key]) {
					formData.append(key, values[key]);
				}
			}

			// Append category and subCategory only if they are selected
			if (selectedCategory !== userData.vendor?.category[0]) {
				formData.append("category", selectedCategory);
			}

			if (selectedSubCategory !== userData.vendor?.subCategory[0]) {
				formData.append("subCategory", selectedSubCategory);
			}

			// Ensure there are changes before making the API call
			if ([...formData.keys()].length > 0) {
				const response = await updateVendorProfileApi(userData.vendor._id, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});

				setShowModal(true);
				console.log("Response:", response);
			}
		} catch (error) {
			console.error("Error updating profile:", error);
			alert("Failed to update profile. Please try again.");
		} finally {
			setSubmitting(false);
		}
	};

	const handleModalClose = () => {
		setShowModal(false);
	};

	const today = new Date(); // Get today's date

	const marketId = userData.vendor.market;

	const defaultSubCategory = userData.vendor.subCategory[0]; // Assuming it's an ID
	const defaultCategory = userData.vendor.category[0]; // Assuming it's an ID

	useEffect(() => {
		const fetchCategories = async () => {
			try {
				const categoryResponse = await getVendorAllCategoryApi(marketId); // Pass the market ID to the API call
				console.warn("SubadminData", categoryResponse);

				// Set categories and subcategories from the response
				const fetchedCategories = categoryResponse.data.data.category;
				const fetchedSubCategories = categoryResponse.data.data.subCategory;

				setCategories(fetchedCategories);
				setSubCategories(fetchedSubCategories);

				// Set the default subcategory if it matches one from the API
				if (defaultSubCategory && fetchedSubCategories.some((sub) => sub._id === defaultSubCategory)) {
					setSelectedSubCategory(defaultSubCategory);
				}

				// Set the default category if it matches one from the API
				if (defaultCategory && fetchedCategories.some((cat) => cat._id === defaultCategory)) {
					setSelectedCategory(defaultCategory); // Assuming you have setSelectedCategory
				}
			} catch (error) {
				console.error("Error fetching categories:", error);
			}
		};

		fetchCategories();
	}, []);

	return (
		<>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center                                                                                             flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">My Profile</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<a href="#">My Profile</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title mb-0">Profile</h5>
							</div>
							<div className="card-body">
								<Formik initialValues={userInfo} onSubmit={handleSubmit} validationSchema={validationSchema}>
									{({ setFieldValue, values }) => (
										<Form>
											<div className="row">
												<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
													<label htmlFor="inputUsername">
														Business Name <span style={{ color: "red" }}>*</span>
													</label>
													<Field type="text" className="form-control" id="businessName" name="businessName" placeholder="businessName" />
													<ErrorMessage name="businessName" component="div" className="text-danger" />
												</div>
												<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
													<label htmlFor="inputUsername">Email</label>
													<Field type="email" className="form-control" id="email" name="email" placeholder="Email" readOnly />
												</div>
												<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
													<label htmlFor="inputUsername">
														Product Message <span style={{ color: "red" }}>*</span>
													</label>
													<Field type="text" className="form-control" id="addProductMessage" name="addProductMessage" placeholder="addProductMessage" />
													<ErrorMessage name="addProductMessage" component="div" className="text-danger" />
												</div>
												<div className="col-12 mb-4">
													<label htmlFor="about">
														Business Description <span style={{ color: "red" }}>*</span>
													</label>
													<Field as="textarea" className="form-control" id="businessDescription" name="businessDescription" placeholder="businessDescription" rows="8" />
													<ErrorMessage name="businessDescription" component="div" className="text-danger" />
												</div>

												{/* <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
													<label htmlFor="subCategory">
														Category <span style={{ color: "red" }}>*</span>
													</label>
													<Field
														as="select"
														className="form-control"
														name="subCategory"
														id="subCategory"
														onChange={(e) => setSelectedSubCategory(e.target.value)}
														value={selectedSubCategory || ""} // Ensure the selected value is controlled
													>
														<option value="">Select a Category</option>
														{subCategories.map((subCategory) => (
															<option key={subCategory._id} value={subCategory._id}>
																{subCategory.name}
															</option>
														))}
													</Field>
												</div> */}

												<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
													<label htmlFor="category">
														Category <span style={{ color: "red" }}>*</span>
													</label>
													<Field
														as="select"
														className="form-control"
														name="category"
														id="category"
														onChange={(e) => setSelectedCategory(e.target.value)}
														value={selectedCategory || ""} // Ensure the selected value is controlled
													>
														<option value="">Select a Category</option>
														{categories.map((category) => (
															<option key={category._id} value={category._id}>
																{category.name}
															</option>
														))}
													</Field>
												</div>

												<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
													<label htmlFor="phone">
														Contact Person <span style={{ color: "red" }}>*</span>
													</label>
													<Field type="text" className="form-control" id="contactPerson" name="contactPerson" placeholder="Contact Person" />
													<ErrorMessage name="contactPerson" component="div" className="text-danger" />
												</div>
												<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
													<label htmlFor="phone">
														Contact Number <span style={{ color: "red" }}>*</span>
													</label>
													<Field type="text" className="form-control" id="contactNumber" name="contactNumber" placeholder="Contact Number " />
													<ErrorMessage name="contactNumber" component="div" className="text-danger" />
												</div>
												<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
													<label htmlFor="instagram">Instagram</label>
													<Field type="url" className="form-control" id="instagram" name="instagram" placeholder="Instagram URL" />
													<ErrorMessage name="instagram" component="div" className="text-danger" />
												</div>
												<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
													<label htmlFor="facebook">Facebook</label>
													<Field type="url" className="form-control" id="facebook" name="facebook" placeholder="Facebook URL" />
													<ErrorMessage name="facebook" component="div" className="text-danger" />
												</div>
												<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
													<label htmlFor="twitter">Twitter</label>
													<Field type="url" className="form-control" id="twitter" name="twitter" placeholder="Twitter URL" />
													<ErrorMessage name="twitter" component="div" className="text-danger" />
												</div>
												<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
													<label htmlFor="tikTok">TikTok</label>
													<Field type="url" className="form-control" id="tikTok" name="tikTok" placeholder="TikTok URL" />
													<ErrorMessage name="tikTok" component="div" className="text-danger" />
												</div>
											</div>

											<div className="row m-2">
												{/* Product Images Row */}
												<div className="col-md-12">
													<div className="row mb-4">
														{[
															{
																field: "productImage1",
																title: "Product Image 1",
																isRequired: true, // Mark this as required
															},
															{
																field: "productImage2",
																title: "Product Image 2",
															},
															{
																field: "productImage3",
																title: "Product Image 3",
															},
														].map(({ field, title, isRequired }) => (
															<div className="col-md-4" key={field}>
																<div className="form-group">
																	<label htmlFor={field}>
																		{title}
																		{isRequired && <span style={{ color: "red" }}>*</span>} {/* Add star here */}
																	</label>
																	<DragsAndDrop
																		imgKey={typeof userInfo[field] === "string" ? userInfo[field] : ""}
																		uploadFile={(file) => {
																			setFieldValue(field, file);
																			setImageSelected((prev) => ({
																				...prev,
																				[field]: true,
																			}));
																		}}
																		heading={`Upload ${title}`}
																		aspect={1}
																	/>
																	{(imageSelected[field] || (userInfo[field] && userInfo[field] !== "removed")) && field !== "productImage1" && (
																		<button
																			type="button"
																			className="btn btn-danger m-2"
																			onClick={() => {
																				handleRemoveImage(field);
																			}}
																		>
																			Remove
																		</button>
																	)}
																</div>
															</div>
														))}
													</div>
												</div>

												{/* Additional Images Row */}
												<div className="col-md-12">
													<div className="row mb-4">
														{[
															{
																field: "publicLiabilityInsurance",
																title: "Public Liability Insurance",
																dateField: "insuranceExpiryDate",
																isRequired: true, // Mark this as required
															},
															{
																field: "foodRegistrationImage",
																title: "Food Registration Image",
																dateField: "expireLicenseDate",
															},
															{
																field: "liquorLicenseImage",
																title: "Liquor License Image",
																dateField: "liquorLicenseExpiryDate",
															},
															{
																field: "otherLicenseImage",
																title: "Other License Image",
																dateField: "otherLicenseExpiryDate",
															},
														].map(({ field, title, dateField, isRequired }) => (
															<div className="col-md-4" key={field}>
																<div className="form-group">
																	<label htmlFor={field}>
																		{title}
																		{isRequired && <span style={{ color: "red" }}>*</span>} {/* Add star here */}
																	</label>
																	<DragsAndDrop
																		imgKey={typeof userInfo[field] === "string" ? userInfo[field] : ""}
																		uploadFile={(file) => setFieldValue(field, file)}
																		heading={`Upload ${title}`}
																		aspect={1 / 1.414}
																	/>

																	{(imageLicenseSelected[field] || (userInfo[field] && userInfo[field] !== "removed")) && field !== "publicLiabilityInsurance" && (
																		<button
																			type="button"
																			className="btn btn-danger m-3"
																			onClick={() => {
																				handleRemoveImage(field, dateField);
																			}}
																		>
																			Remove
																		</button>
																	)}
																	<DatePicker
																		selected={values[dateField]}
																		// onChange={(date) =>
																		//   handleDateChange(date, dateField)
																		// }

																		onChange={(date) => setFieldValue(dateField, date)}
																		// onChange={(date) => handleDateChange(date, dateField)} // Use your existing handler

																		dateFormat="dd/MM/yyyy"
																		className="form-control m-2"
																		placeholderText="Select License Date"
																		minDate={today}
																	/>
																</div>
															</div>
														))}
													</div>
													<button type="submit" className="btn btn-primary">
														Save changes
													</button>
												</div>
											</div>
										</Form>
									)}
								</Formik>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showModal && (
				<div className="modal fade show" style={{ display: "block" }} tabIndex="-1" role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">{imageRemoved ? "Image Removed" : "Changes Submitted"}</h5>
								<button type="button" className="close" onClick={() => setShowModal(false)}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								{imageRemoved ? <p>The image has been removed.</p> : <p>Your changes have been submitted. Please wait for the market owner to approve them.</p>}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
export default VendorProfile;
