import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { useNavigate, Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import ReactPaginate from "react-paginate";

const ManagerAccount = () => {
	document.title = "Eventease360 | Market Managers";
	const { getAllMarketManagerApi, deleteMarketManagerApi, searchMarketApi } = useApiAxios();
	const [adminData, setAdminData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [marketToDelete, setMarketToDelete] = useState(null);
	const emailPerPageOptions = [1, 10, 20, 50];

	const navigate = useNavigate();
	const fetchMarketData = async () => {
		try {
			const response = await getAllMarketManagerApi();
			console.warn("))))))))",response)
			if (!response.error) {
				setAdminData(response.data.data);

			} else {
				console.error("Error fetching data:", response.data);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		fetchMarketData();
	}, []);

	const handleEdit = (user) => {
		navigate(`/edit/manager_account/${user._id}`);
	};

	const handleOpenDeleteModal = (userId) => {
		setMarketToDelete(userId);
		setDeleteModalOpen(true);
		const backdrop = document.createElement("div");
		backdrop.classList.add("modal-backdrop", "fade", "show");
		document.body.appendChild(backdrop);
	};

	const handleCloseDeleteModal = () => {
		setDeleteModalOpen(false);
		setMarketToDelete(null);
		const backdrop = document.querySelector(".modal-backdrop");
		if (backdrop) {
			document.body.removeChild(backdrop);
		}
	};

	const handleDeleteMarket = async () => {
		if (marketToDelete) {
			setLoading(true);
			try {
				await deleteMarketManagerApi(marketToDelete);
				await fetchMarketData();
				handleCloseDeleteModal();
			} catch (error) {
				console.error("Error deleting market:", error);
			}
			setLoading(false);
		}
	};

	return (
		<>
			<div className="">
				<div className="panel-header">
					<div className="page-inner pb-5">
						<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
							<div>
								<h2 className="pb-2 fw-bold">Market Managers</h2>
								<ul className="breadcrumbs">
									<li className="nav-home">
										<Link to="/">
											<i className="flaticon-home" />
										</Link>
										<li className="separator">
											<i className="flaticon-right-arrow" />
										</li>
										<li className="nav-item">
											<Link to="#">Market Managers</Link>
										</li>
									</li>
								</ul>
							</div>
							<div className="ml-md-auto py-2 py-md-0">
								<div className="row">
									<div className="col-6">
										<div className="row">
											<div className="col-6">
												<Link to="/add/manager_account" className="btn btn-primary btn-round">
													<span className="btn-label"></span>
													Add Market Manager
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="page-inner mt--5">
					<div className="row">
						<div className="col-md-12">
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-hover">
											<thead>
												<tr>
													<th>Name</th>
													<th>Email</th>
													<th>Mobile No</th>
													<th>Markets</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{adminData.map((user, index) => (
													<tr key={index}>
														<td>{user.name || "N/A"}</td>
														<td>{user.email || "N/A"}</td>
														<td>{user.phone || "N/A"}</td>


														<td>{user.managerMarket?.map((obj) => obj.name).join(", ")}</td>

														<td>
															<div className="form-button-action">
																<button
																	type="button"
																	className="btn btn-primary btn-sm mr-1"
																	onClick={() => handleEdit(user)}
																	data-tooltip-id="edit-tooltip"
																	data-tooltip-content="Edit"
																>
																	<i className="fa fa-edit" />
																</button>
																<button
																	type="button"
																	className="btn btn-danger btn-sm"
																	onClick={() => handleOpenDeleteModal(user._id)}
																	disabled={loading}
																	data-tooltip-id="delete-tooltip"
																	data-tooltip-content="Delete"
																>
																	{loading ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-trash" />}
																</button>
																<Tooltip id="edit-tooltip" />
																<Tooltip id="delete-tooltip" />
															</div>
														</td>
													</tr>
												))}
											</tbody>
										</table>
										<div className="d-flex justify-content-between align-items-center pagination-container">
											<ReactPaginate
												previousLabel={"Previous"}
												nextLabel={"Next"}
												breakLabel={"..."}
												pageCount={0} // Use pageCount here
												marginPagesDisplayed={2}
												pageRangeDisplayed={5}
												onPageChange=""
												containerClassName={"pagination m-0"}
												pageClassName={"page-item"}
												pageLinkClassName={"page-link"}
												previousClassName={"page-item"}
												previousLinkClassName={"page-link"}
												nextClassName={"page-item"}
												nextLinkClassName={"page-link"}
												breakClassName={"page-item"}
												breakLinkClassName={"page-link"}
												activeClassName={"active"}
											/>
											<div>
												<span>Records Per Page: </span>

												<select className="form-control m-2" style={{ width: "auto", display: "inline-block" }}>
													{emailPerPageOptions.map((option) => (
														<option key={option} value={option}>
															Show {option}
														</option>
													))}
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{deleteModalOpen && (
				<div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Delete Market Owner</h5>
								<button type="button" className="close" onClick={handleCloseDeleteModal}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p>Are you sure you want to delete this Market Owner?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-danger btn-sm" onClick={handleDeleteMarket}>
									Yes
								</button>
								<button type="button" className="btn btn-secondary btn-sm" onClick={handleCloseDeleteModal}>
									No
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ManagerAccount;
