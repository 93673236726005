import * as React from "react";
import styles from "./loader.module.css";

export default function Loader({ open }) {
  return (
    // <div
    //   style={{ visibility: open ? "visible" : "hidden", zIndex: 1000000000 }}
    //   className={styles.posCenter}
    // >
    //   <div className={styles.loader}></div>
    // </div>
    // <div
    //   style={{ visibility: open ? "visible" : "hidden", zIndex: 1000000000 }}
    //   class="d-flex justify-content-center"
    // >
    //   <div class="spinner-border" role="status">
    //     <span class="sr-only"></span>
    //   </div>
    // </div>
    <div
      style={{ visibility: open ? "visible" : "hidden", zIndex: 1000000000 }}
      class={styles.loading}
    >
      Loading&#8230;
    </div>
  )
}
