import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DragsAndDrop from "../../image/DragsAndDrop";

const SectionInput = ({
  index,
  section,
  onChange,
  onRemove,
  handleUploadImage,
}) => {
  const [localTitle, setLocalTitle] = useState(section.title || "");
  const [localDescription, setLocalDescription] = useState(
    section.description || ""
  );
  const [imageURL, setImageURL] = useState("");
  useEffect(() => {
    setLocalTitle(section.title || "");
  }, [section.title]);

  useEffect(() => {
    setLocalDescription(section.description || "");
  }, [section.description]);

  const handleTitleChange = (content) => {
    setLocalTitle(content);
    onChange(index, { ...section, title: content });
  };

  const handleDescriptionChange = (content) => {
    setLocalDescription(content);
    onChange(index, { ...section, description: content });
  };

  const handleImageChange = (file) => {
    // const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = () => {
      setImageURL(reader.result);
    };
    reader.readAsDataURL(file);

    handleUploadImage(file).then((image) => {
      if (image) {
        onChange(index, { ...section, image });
      } else {
        console.error("Failed to upload image");
      }
    });
  };

  return (
    <div key={index}>
      <div className="form-group col-md-6">
        <label htmlFor={`title-${index}`}>Section Title</label>
        <ReactQuill
          value={localTitle}
          onChange={handleTitleChange}
          placeholder="Section Title"
          theme="snow"
          modules={{
            toolbar: [
              [{ header: "1" }, { header: "2" }],
              [{ font: [] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["bold", "italic", "underline"],
              [{ align: [] }],
              ["link", "image"],
              ["clean"],
            ],
          }}
        />
      </div>
      <div className="form-group">
        <label htmlFor={`description-${index}`}>Section Description</label>
        <ReactQuill
          value={localDescription}
          onChange={handleDescriptionChange}
          placeholder="Section Description"
          theme="snow"
          modules={{
            toolbar: [
              [{ header: "1" }, { header: "2" }],
              [{ font: [] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["bold", "italic", "underline"],
              [{ align: [] }],
              ["link", "image"],
              ["clean"],
            ],
          }}
        />
      </div>
      <div className="form-group col-md-6">
        <label htmlFor={`image-${index}`}>Upload Image</label>
        <div className="custom-file">
          

          <DragsAndDrop
            heading="Upload Image"
            inputName="Image"
            aspect={1}
            uploadFile={(x) => {
              handleImageChange(x);
            }}
          />
          {/* {imageURL && (
            <div style={{ marginTop: "1rem", overflow: "hidden" }}>
              <img
                src={imageURL}
                alt={"Uploaded preview"}
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "500px",
                  objectFit: "cover",
                }}
              />
            </div>
          )} */}
        </div>
      </div>
     
      <button
        className="btn btn-danger mt-5 mb-4"
        onClick={() => onRemove(index)}
      >
        Remove
      </button>
    </div>
  );
};

export default SectionInput;
