import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Modal, Card, Container, Row, Col } from "react-bootstrap";
import useApiAxios from "../../api/useApiAxios";
import { format } from "date-fns";

const ViewVendor = () => {
	document.title = "Eventease360 | View Vendors";
	const location = useLocation();
	const { vendor } = location.state;
	console.warn("OPOPOPOPOPOPO", vendor);
	const IMAGEENDPOINT = process.env.REACT_APP_IMG_URL;
	const { updateApprovalStatus, declineVendorStatus } = useApiAxios();
	const [showApprovalModal, setShowApprovalModal] = useState(false);
	const [showImageModal, setShowImageModal] = useState(false);
	const [selectedImage, setSelectedImage] = useState("");

	const handleApprove = async (vendorId) => {
		const data = { approvalStatus: "approved" };
		try {
			const response = await updateApprovalStatus(vendorId, data);

			vendor.approvalStatus = "approved"; // Update the vendor's approval status locally
			setShowApprovalModal(false);
		} catch (error) {
			console.error("Error approving vendor:", error);
		}
	};

	const handleImageClick = (imageUrl) => {
		setSelectedImage(imageUrl);
		setShowImageModal(true);
	};
	const formatDate = (date) => (date ? format(new Date(date), "dd/MM/yyyy") : "N/A");
	console.log(vendor.vendorDate);
	return (
		<>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Vendor Details</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<Link to="/vendorlist">Vendors</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">Vendor Details</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<Card.Body>
								<Row>
									<Col md={6}>
										<Card.Title>{vendor.businessName}</Card.Title>
										<Card.Text>
											<strong>Contact Person:</strong> {vendor.contactPerson}
										</Card.Text>
										<Card.Text>
											<strong>Contact Number:</strong> {vendor.contactNumber}
										</Card.Text>
										<Card.Text>
											<strong>Email:</strong> {vendor.email}
										</Card.Text>
										<Card.Text>
											<strong>Role:</strong> {vendor.role}
										</Card.Text>
										{/* <Card.Text>
											<strong>Category:</strong> {vendor.subCategory[0].name}
										</Card.Text> */}
										<Card.Text>
											<strong>Category:</strong> {vendor.category[0].name}
										</Card.Text>

										<Card.Text>
											<strong>Instagram:</strong>
											{vendor.instagram && vendor.instagram !== "N/A" ? (
												<a href={vendor.instagram} target="_blank" rel="noopener noreferrer">
													{vendor.instagram}
												</a>
											) : (
												"N/A"
											)}
										</Card.Text>

										<Card.Text>
											<strong>Facebook:</strong>
											{vendor.facebook && vendor.facebook !== "N/A" ? (
												<a href={vendor.facebook} target="_blank" rel="noopener noreferrer">
													{vendor.facebook}
												</a>
											) : (
												"N/A"
											)}
										</Card.Text>

										<Card.Text>
											<strong>TikTok:</strong>
											{vendor.tikTok && vendor.tikTok !== "N/A" ? (
												<a href={vendor.tikTok} target="_blank" rel="noopener noreferrer">
													{vendor.tikTok}
												</a>
											) : (
												"N/A"
											)}
										</Card.Text>

										<Card.Text>
											<strong>Twitter:</strong>
											{vendor.twitter && vendor.twitter !== "N/A" ? (
												<a href={vendor.twitter} target="_blank" rel="noopener noreferrer">
													{vendor.twitter}
												</a>
											) : (
												"N/A"
											)}
										</Card.Text>
										<Card.Text>
											<strong>Liquor License Expiry Date:</strong> {formatDate(vendor.liquorLicenseExpiryDate)}
										</Card.Text>
										<Card.Text>
											<strong>Expire License Date:</strong> {formatDate(vendor.expireLicenseDate)}
										</Card.Text>
										<Card.Text>
											<strong>Insurance Expiry Date:</strong> {formatDate(vendor.insuranceExpiryDate)}
										</Card.Text>
										<Card.Text>
											<strong>Other License Expiry Date:</strong> {formatDate(vendor.otherLicenseExpiryDate)}
										</Card.Text>
									</Col>
									<Col md={6}>
										<Card.Text>
											<strong>Created At:</strong> {format(new Date(vendor.createdAt), "dd/MM/yyyy HH:mm:ss")}
										</Card.Text>
										<Card.Text>
											<strong>Updated At:</strong> {format(new Date(vendor.updatedAt), "dd/MM/yyyy HH:mm:ss")}
										</Card.Text>
										<Card.Text>
											<strong>Business Description:</strong> {vendor.businessDescription}
										</Card.Text>
										<Card.Text>
											<strong>Add Product Message:</strong> {vendor.addProductMessage}
										</Card.Text>
										<strong>Date Applied:</strong>
										{/* {vendor.vendorDate && vendor.vendorDate.length > 0
                ? vendor.vendorDate.map((item, index) =>
                    <span key={item._id}>
                      {item.dateId && item.dateId.date
                        ? format(new Date(item.dateId.date), "dd/MM/yyyy")
                        : "Invalid date"}
                      {index < vendor.vendorDate.length - 1 ? ", " : ""}
                    </span>
                  )
                : <span>Not available</span>} */}

										<div style={{ display: "flex", flexWrap: "wrap", gap: "10px", margin: "20px 0" }}>
											{vendor.vendorDate && vendor.vendorDate.length > 0 ? (
												vendor.vendorDate.map(
													(item, index) =>
														item.dateId ? ( // Check if dateId is present
															<span
																key={item._id}
																style={{
																	padding: "8px 12px",
																	border: "1px solid #ddd",
																	borderRadius: "5px",
																	backgroundColor: "#f9f9f9",
																	fontSize: "16px",
																	color: "#333",
																}}
															>
																{format(new Date(item.dateId.date), "dd/MM/yyyy")}
																{index < vendor.vendorDate.length - 1 && <span style={{ marginLeft: "10px", color: "#999" }}></span>}
															</span>
														) : null // Do not render anything if dateId is not present
												)
											) : (
												<span style={{ color: "#999" }}>Not available</span>
											)}
										</div>
									</Col>
								</Row>
							</Card.Body>

							<h2 className="text-center mb-4">Images</h2>
							<Row>
								{["productImage1", "productImage2", "productImage3", "publicLiabilityInsurance", "foodRegistrationImage", "liquorLicenseImage", "otherLicenseImage"].map(
									(imageKey, idx) => {
										const imageUrl = vendor[imageKey];
										// Check if the image URL is valid, not empty, and not equal to "removed"
										if (imageUrl && imageUrl.trim() !== "" && imageUrl !== "removed") {
											return (
												<Col md={4} key={idx} className="m-2">
													<Card className="image-card">
														<Card.Img
															variant="top"
															src={`${IMAGEENDPOINT}/${imageUrl}`}
															onClick={() => handleImageClick(`${IMAGEENDPOINT}/${imageUrl}`)}
															style={{ cursor: "pointer" }}
														/>
														<Card.Body>
															<Card.Text>
																<strong>
																	{imageKey
																		.replace(/([A-Z])/g, " $1")
																		.replace(/^./, (str) => str.toUpperCase())
																		.replace(" Image", "")}
																</strong>
															</Card.Text>
														</Card.Body>
													</Card>
												</Col>
											);
										}
										return null; // Do not render anything if the image value is invalid
									}
								)}
							</Row>

							<div className="text-center mb-4">
								<Button
									variant={vendor.approvalStatus.toLowerCase() === "approved" || vendor.waitList === true ? "success" : "primary"}
									onClick={() => setShowApprovalModal(true)}
									disabled={vendor.approvalStatus.toLowerCase() === "approved" || vendor.waitList === true || vendor.approvalStatus.toLowerCase() === "reject"}
								>
									{vendor.approvalStatus.toLowerCase() === "approved" ? "Approved" : "Approve Vendor"}
								</Button>
							</div>

							<Modal show={showApprovalModal} onHide={() => setShowApprovalModal(false)}>
								<Modal.Header>
									<Modal.Title>Confirm Approval</Modal.Title>
									<button type="button" class="close" data-dismiss="modal" style={{ fontSize: "36px" }} aria-label="Close" onClick={() => setShowApprovalModal(false)}>
										<span aria-hidden="true">&times;</span>
									</button>
								</Modal.Header>
								<Modal.Body>Are you sure you want to approve this vendor?</Modal.Body>
								<Modal.Footer>
									<Button variant="secondary" onClick={() => setShowApprovalModal(false)}>
										No
									</Button>
									<Button variant="primary" onClick={() => handleApprove(vendor._id)}>
										Yes
									</Button>
								</Modal.Footer>
							</Modal>

							<Modal show={showImageModal} onHide={() => setShowImageModal(false)} size="lg">
								<Modal.Header>
									<Modal.Title>Image Preview</Modal.Title>
									<button type="button" class="close" data-dismiss="modal" style={{ fontSize: "36px" }} aria-label="Close" onClick={() => setShowImageModal(false)}>
										<span aria-hidden="true">&times;</span>
									</button>
								</Modal.Header>
								<Modal.Body>
									<img src={selectedImage} alt="Selected" style={{ width: "100%", height: "auto" }} />
								</Modal.Body>
								<Modal.Footer>
									<Button variant="secondary" onClick={() => setShowImageModal(false)}>
										Close
									</Button>
								</Modal.Footer>
							</Modal>

							<style>
								{`
          .image-card {
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            max-width: 100%; /* Ensures card does not exceed container width */
            height: auto; /* Automatically adjusts height based on content */
          }

          .image-card .card-img-top {
            height: 400px; 
            width: 100%;
            object-fit: cover; 
            cursor: pointer; /* Indicates image is clickable */
          }

          .image-card .card-body {
            padding: 1rem;
          }

          .text-center {
            text-align: center;
          }

          .mb-4 {
            margin-bottom: 1.5rem;
          }

          .my-5 {
            margin: 3rem 0;
          }

          .modal-header .close {
            padding: 0.75rem 1.25rem;
            margin: -0.75rem -1.25rem -0.75rem auto;
            border: 0;
            background: none;
            font-size: 1.5rem;
            color: #000;
          }
        `}
							</style>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ViewVendor;
