import React, { useState, useEffect, useContext } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Context } from "../../context/Context";

const StripConnect = () => {
  document.title = "Eventease360 | Stripe Connect";

  const [stripeData, setStripeData] = useState("");
  const [responseMessage, setResponseMessage] = useState(null);
  const [accountDetails, setAccountDetails] = useState({
    customerName: "",
    ifscCode: "",
    accountNumber: "",
    bankName: "",
  });
  const { userData } = useContext(Context);

  const [errors, setErrors] = useState({}); // Track validation errors
  const { getStripeKeyApi, postStripeKey, postMarketOwnerBankDetailApi, getMarketOwnerBankDetail } = useApiAxios();


  const validationSchema = Yup.object().shape({
    // Bank Name must contain only alphabetic characters
    bankName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Bank Name must only contain letters and spaces")
      .required("Bank Name is required"),
    
      customerName: Yup.string()
      .matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Bank Holder Name must only contain letters and spaces (no leading or trailing spaces)")
      .required("Bank Holder Name is required"),
    
    // Australian BSB Code (6 digits)
    ifscCode: Yup.string()
      .matches(/^[0-9]{6}$/, "Invalid BSB Code, must be 6 digits")
      .required("BSB Code is required"),
    
    // Australian Account Number (6 to 9 digits)
    accountNumber: Yup.string()
      .matches(/^[0-9]{6,9}$/, "Invalid Account Number, must be between 6 and 9 digits")
      .required("Account Number is required"),
  });
  // Validate form using Yup
  const validateForm = async () => {
    try {
      await validationSchema.validate(accountDetails, { abortEarly: false });
      return true; // If no errors
    } catch (err) {
      const newErrors = err.inner.reduce((acc, error) => {
        acc[error.path] = error.message;
        return acc;
      }, {});
      setErrors(newErrors); // Set the error messages
      return false; // If validation fails
    }
  };

  const connectToStripe = async () => {
    try {
      const res = await postStripeKey("");
      if (res && res.data && res.data.status === "success") {
        window.location.href = res.data.data.onboarding_url;
      } else {
        setResponseMessage("Unable to connect to Stripe. Please try after some time.");
      }
    } catch (error) {
      setResponseMessage("Unable to connect to Stripe. Please try after some time.");
      console.error("Error:", error);
    }
  };

  const getStripeConnect = async () => {
    try {
      const response = await getStripeKeyApi();
      if (response && response.data && response.data.data && response.data.data) {
        setStripeData(response.data.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Fetch market owner bank details on component mount
  const fetchMarketOwnerBankDetails = async () => {
    if (userData && userData._id) {
      try {
        const response = await getMarketOwnerBankDetail(userData._id);
        if (response && response.data && response.data.data) {
          const { bankName, customerName, ifscCode, accountNumber } = response.data.data;
          setAccountDetails({
            bankName,
            customerName,
            ifscCode,
            accountNumber,
          });
        }
      } catch (error) {
        console.error("Error fetching bank details:", error);
      }
    }
  };

  useEffect(() => {
    getStripeConnect();
    fetchMarketOwnerBankDetails(); // Call to fetch the bank details
  }, [userData]); // Re-run this if userData changes

  // Handle changes in the account details form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // Reset error when the user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error for the field that is being changed
    }));
  
    setAccountDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const isValid = await validateForm(); // Validate before saving
  
    if (isValid) {
      const bankData = {
        bankName: accountDetails.bankName,
        customerName: accountDetails.customerName,
        ifscCode: accountDetails.ifscCode,
        accountNumber: accountDetails.accountNumber,
      };
  
      const userId = userData._id; // Get the user ID separately
      console.warn("User ID:", userId);
  
      try {
        // Sending data to the API using postMarketOwnerBankDetailApi
        const response = await postMarketOwnerBankDetailApi(bankData); // Corrected the order of parameters here
        if (response && response.data && response.data.status === "success") {
          setResponseMessage("Account details saved successfully.");
          setErrors({}); // Clear errors after successful save
        } else {
          setResponseMessage("Unable to save account details. Please try again.");
        }
      } catch (error) {
        console.error("Error:", error);
        setResponseMessage("Unable to save account details. Please try again.");
      }
    }
  };
  

  return (
    <>
      <div className="panel-header">
        <div className="page-inner pb-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Stripe Connect</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="#">Stripe Connect</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h2 className="text-center mb-4">Connect Your Account To Stripe</h2>
                <div className="text-center mt-4">
                  {stripeData.status && stripeData.status === "active" ? (
                    <p className="text-success text-center">
                      Your Stripe account successfully connected.
                    </p>
                  ) : (
                    <button onClick={connectToStripe} className="btn btn-primary">
                      {stripeData.status && stripeData.status === "onboarding"
                        ? "Please Complete Account Onboarding"
                        : "Connect"}
                    </button>
                  )}
                </div>
                {responseMessage && <p className="text-danger text-center">{responseMessage}</p>}
              </div>
            </div>

            {/* New Card for Account Details */}
            <div className="card mt-4">
              <div className="card-body">
                <h3 className="text-center mb-4">Add Your Account Details</h3>
                <form className="form">
                  <div className="form-group">
                    <label htmlFor="bankName">Bank Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="bankName"
                      name="bankName"
                      value={accountDetails.bankName}
                      onChange={handleInputChange}
                      placeholder="Enter Bank Name"
                    />
                    {errors.bankName && <small className="text-danger">{errors.bankName}</small>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="customerName">Bank Holder Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="customerName"
                      name="customerName"
                      value={accountDetails.customerName}
                      onChange={handleInputChange}
                      placeholder="Bank Holder Name"
                    />
                    {errors.customerName && <small className="text-danger">{errors.customerName}</small>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="ifscCode">BSB Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="ifscCode"
                      name="ifscCode"
                      value={accountDetails.ifscCode}
                      onChange={handleInputChange}
                      placeholder="Enter BSB Code"
                    />
                    {errors.ifscCode && <small className="text-danger">{errors.ifscCode}</small>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="accountNumber">Account Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="accountNumber"
                      name="accountNumber"
                      value={accountDetails.accountNumber}
                      onChange={handleInputChange}
                      placeholder="Enter Account Number"
                    />
                    {errors.accountNumber && <small className="text-danger">{errors.accountNumber}</small>}
                  </div>

                  <div className="text-center">
                    <button type="button" className="btn btn-success" onClick={handleSave}>
                      Save Account Details
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StripConnect;
