import React, { useCallback, useEffect, useState } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import MapModal from "./MapModal";

const localizer = momentLocalizer(moment);

const MapMapping = () => {
  document.title = "Eventease360 | Market Map";
  const { getAllMarket } = useApiAxios();
  const [adminData, setAdminData] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState("");
  const [selectedMarketData, setSelectedMarketData] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [OpenModal, setOpenModal] = useState(false);

  // Fetch market data
  const fetchData = async () => {
    const res = await getAllMarket();
    if (!res.error) {
      setAdminData(res.data.data);
      setMarkets(res.data.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [myEvents, setEvents] = useState([]);
  const handleMarketChange = (e) => {
    const market = markets.find((market) => market.name === e.target.value);
    console.warn("PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP", market);

    if (market) {
      setSelectedMarketData(market);
      const event = [];
      for (let index = 0; index < market?.marketDate.length; index++) {
        const element = market?.marketDate[index];
        console.warn("OOOOOOOOOO", element);
        event.push({
          id: element._id,
          start: new Date(element.date),
          end: new Date(element.date),
          allDay: true,
          title: "Market Day",
          marketMapAddress: element.marketMapAddress
        });
      }
      setEvents(event);
    } else {
      setSelectedMarketData(null);
      setEvents([]);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSelectEvent = (e) => {
    const date = selectedMarketData.marketDate.find(
      (market) => market._id === e.id
    );
    setCurrentDate({
      ...date,
      marketMapImage: selectedMarketData?.mapImage,
      marketMapAddress: e.marketMapAddress
    });
    setOpenModal(true);
  };

  // Custom event rendering for the day box
  const Event = ({ event }) => (
    <div className="d-flex">

      <p className="text">
        {event.marketMapAddress || "Address not available"}
      </p>
    </div>
  );

  return (
    <>
      <div className="panel-header">
        <div className="page-inner pb-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Map</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Map</a>
                </li>
              </ul>
            </div>
            <div className="ml-md-auto py-2 py-md-0">
              <select
                className="custom-select"
                id="marketSelect"
                onChange={handleMarketChange}
              >
                <option value="">Select a market</option>
                {markets.map((market, index) => (
                  <option key={index} value={market.name}>
                    {market.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                {myEvents.length !== 0 ? (
                  <div style={{ height: "600px" }} className="height600">
                    <Calendar
                      localizer={localizer}
                      defaultDate={new Date()}
                      defaultView={Views.MONTH}
                      view={Views.MONTH}
                      events={myEvents}
                      onSelectEvent={handleSelectEvent}
                      scrollToTime={new Date()}
                      components={{
                        toolbar: CustomToolbar,
                        event: Event // Custom event rendering here
                      }}
                    />
                  </div>
                ) : selectedMarketData ? (
                  <p>No Date found</p>
                ) : (
                  <p className="text-center m-0">
                    Please select a market to view map.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <MapModal
        show={OpenModal}
        handleClose={handleCloseModal}
        date={currentDate}
      />
    </>
  );
};

const CustomToolbar = (toolbar) => {
  return (
    <div className="rbc-toolbar">
      <span className="rbc-toolbar-label">{`${toolbar.label}`}</span>
      <span className="rbc-btn-group">
        <button onClick={() => toolbar.onNavigate("PREV")}>Back </button>
        <button onClick={() => toolbar.onNavigate("TODAY")}>Today</button>
        <button onClick={() => toolbar.onNavigate("NEXT")}>Next</button>
      </span>
    </div>
  );
};

export default MapMapping;
