import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context"; // Context to get user data
import useApiAxios from "../../api/useApiAxios";

const WalletAmount = () => {
	const { getAllInvoiceVendor } = useApiAxios();

	const [transactions, setTransactions] = useState([]); // Store transaction data
	const [isLoading, setIsLoading] = useState(true); // Loading state
	const [error, setError] = useState(null); // Error state

	const fetchData = async () => {
		try {
			const invoiceResponse = await getAllInvoiceVendor(1, 1000); // Adjust the page and limit as needed
			console.warn("vendor data transaxction", invoiceResponse?.data?.data[0]?.vendorId);
			const vendorData = invoiceResponse?.data?.data[0]?.vendorId; // Assuming you're getting the vendorId data from the response

			if (vendorData) {
				setTransactions(vendorData.transactions); // Store the transactions from the vendor
			}
			setIsLoading(false);
		} catch (error) {
			setError("Error fetching data");
			setIsLoading(false);
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		document.title = "Transaction Logs";
		fetchData();
	}, []);

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>{error}</div>;
	}
	const formatDate = (date) => {
		return new Date(date).toLocaleDateString("en-US", {
			weekday: "short", // "Mon"
			year: "numeric", // "2024"
			month: "short", // "Jan"
			day: "numeric", // "21"
		});
	};

	return (
		<div>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Transaction Logs</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<Link to="/transaction-logs">Transaction Logs</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="table-responsive">
									<table className="table table-hover">
										<thead className="table-light">
											<tr>
												<th>Date</th>
												<th>Amount</th>
												<th>Type</th>
												<th>Description</th>
											</tr>
										</thead>
										<tbody>
											{transactions.length > 0 ? (
												transactions.map((transaction) => (
													<tr key={transaction._id}>
														<td>{formatDate(transaction.date)}</td>
														<td>
															<span className={`${transaction.type === "credit" ? "text-success" : "text-danger"}`}>
																{transaction.type === "credit" ? "+" : "-"}
																{transaction.amount}
															</span>
														</td>
														<td>
															<span className={`badge ${transaction.type === "credit" ? "bg-success" : "bg-danger"}`}>{transaction.type === "credit" ? "Credit" : "Debit"}</span>
														</td>
														<td>{transaction.description || "No description"}</td>
													</tr>
												))
											) : (
												<tr>
													<td colSpan="4" className="text-center text-muted">
														No transactions found
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WalletAmount;
