import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import useApiAxios from "../../api/useApiAxios";
import * as Yup from "yup";

const EditSubCategory = () => {
	document.title = "Eventease360 | Edit Category";
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const { updateSubCategoryApi } = useApiAxios();
	const navigate = useNavigate();
	const user = location.state?.user;
	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Name is required"),
	});
	const initialValues = {
		name: user ? user.name : "",
	};
	useEffect(() => {}, [user]);
	const onSubmit = async (values, { setSubmitting }) => {
		try {
			await updateSubCategoryApi(user._id, values);
			navigate("/category");
		} catch (error) {
			console.error("Error updating category:", error);
		}
		setSubmitting(false);
	};
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
	});

	return (
		<>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Edit Category</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<Link to="/category"> Category</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<a href="#">Edit Category</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<form onSubmit={formik.handleSubmit}>
									<div className="col-md-12 col-xl-6">
										<div className="form-group">
											<label htmlFor="name"> Category Name:</label>
											<input className="form-control" type="text" id="name" name="name" value={formik.values.name} onChange={formik.handleChange} />
											{formik.errors.name && formik.touched.name && <div className="text-danger">{formik.errors.name}</div>}
										</div>
									</div>
									<div>
										<button type="submit" disabled={loading} className="btn btn-primary m-2">
											{loading ? "Updating..." : "Update"}
										</button>
										<Link to="/category">
											<button type="button" className="btn btn-danger">
												Cancel
											</button>
										</Link>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditSubCategory;
