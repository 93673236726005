import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import useApiAxios from "../../api/useApiAxios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import DragsAndDrop from "../../image/DragsAndDrop.jsx";

const Profile = () => {
  const { updateProfileApi } = useApiAxios();
  const { userData } = useContext(Context);
  // const [showPassword, setShowPassword] = useState(false);

  const imageEndPoint = process.env.REACT_APP_IMG_URL; // Adjust according to your setup

  const [userInfo, setUserInfo] = useState({
    name: userData.name,
    organization: userData.organization,
    phone: userData.phone,
    email: userData.email,
    about: userData.about,
    // password: "",
    profileImage: null
  });

  useEffect(() => {
    if (userData) {
      setUserInfo({
        name: userData.name,
        organization: userData.organization,
        email: userData.email,
        phone: userData.phone,
        about: userData.about,
        profileImage: userData.profileImage || null // Set the initial profile image if exists
      });
    }
  }, [userData]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const formData = new FormData();

      for (const key in values) {
        if (key === "profileImage" && userInfo.profileImage instanceof File) {
          formData.append("profileImage", userInfo.profileImage);
        } else {
          formData.append(key, values[key]);
        }
      }

      const response = await updateProfileApi(userData._id, formData);
      console.warn("Response:", response);
      // Handle success
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  // const togglePasswordVisibility = () => {
  //   setShowPassword(!showPassword);
  // };

  const handleImageUpload = (file) => {
    if (file && file instanceof File) {
      setUserInfo((prevState) => ({
        ...prevState,
        profileImage: file // Store the File object directly
      }));
    }
  };

  return (
    <>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">My Profile</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <Link to="/profile">My Profile</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="col-md-8 col-xl-9">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Public info</h5>
            </div>
            <div className="card-body">
              <Formik
                initialValues={userInfo}
                onSubmit={handleSubmit}
                validationSchema={Yup.object({
                  name: Yup.string().required("Required"),
                  organization: Yup.string(),
                  about: Yup.string(),
                  phone: Yup.string()
                    .matches(
                      /^[0-9]+$/,
                      "Phone number must contain only numeric digits"
                    )
                    .min(10, "Phone number must be exactly 10 digits")
                    .max(10, "Phone number must be exactly 10 digits"),
                  password: Yup.string().min(
                    6,
                    "Password must be at least 6 characters"
                  )
                })}
              >
                {({ setFieldValue }) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label htmlFor="inputUsername">Username</label>
                          <Field
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Name"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="inputUsername">Email</label>
                          <Field
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Email"
                            readOnly
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="inputUsername">Organization</label>
                          <Field
                            type="text"
                            className="form-control"
                            id="organization"
                            name="organization"
                            placeholder="Organization"
                          />
                          <ErrorMessage
                            name="organization"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="about">About</label>
                          <Field
                            as="textarea"
                            className="form-control"
                            id="about"
                            name="about"
                            placeholder="About"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="phone">Contact Number</label>
                          <Field
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            placeholder="Contact Number"
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* <div className="form-group">
                          <label htmlFor="inputNewPassword">Password</label>
                          <div className="input-group">
                            <Field
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              id="password"
                              name="password"
                              placeholder="Password"
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i
                                  className={`toggle-password ${
                                    showPassword
                                      ? "fa fa-eye"
                                      : "fa fa-eye-slash"
                                  }`}
                                  onClick={togglePasswordVisibility}
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </span>
                            </div>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-danger"
                          />
                        </div> */}

                        <div className="form-group">
                          <label>Profile Image</label>
                          <DragsAndDrop
                            imgKey={
                              userInfo.profileImage instanceof File
                                ? URL.createObjectURL(userInfo.profileImage) // Create an object URL for the uploaded file
                                : userInfo.profileImage
                                ? `/${userInfo.profileImage}` // Use the existing image URL
                                : "" // No image to display
                            }
                            uploadFile={handleImageUpload}
                            heading="Upload Profile Image"
                          />
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Save changes
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
