import React, { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";
import { Link, useNavigate } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ReactPaginate from "react-paginate"; // Import ReactPaginate

const AppliedVendorList = () => {
	document.title = "Eventease360 | Applied Vendors";
	const { appliedVendorList, getAllMarket, approveVendorDate, rejectVendorDate, VendorWaitList } = useApiAxios();
	const { userData } = useContext(Context);
	const [marketList, setMarketList] = useState([]);
	const [selectedMarketId, setSelectedMarketId] = useState(null);
	const [vendorList, setVendorList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const navigate = useNavigate();

	const [currentPage, setCurrentPage] = useState(0);
	const [appliedVendorPerPage, setAppliedVendorPerPage] = useState(10);
	const appliedVEndorPerPageOptions = [5, 10, 20, 50];

	useEffect(() => {
		const fetchMarketList = async () => {
			try {
				const response = await getAllMarket();
				setMarketList(response.data.data);
				setLoading(false);
			} catch (err) {
				setError(err.message);
				setLoading(false);
			}
		};

		fetchMarketList();
	}, []);

	const fetchVendorList = async (marketId) => {
		try {
			setLoading(true);
			const response = await appliedVendorList(marketId);
			if (!response.error) {
				console.log(response.data.data); // Debugging line
				setVendorList(response.data.data);
				setError(null);
			} else {
				setError("Failed to fetch vendor list");
			}
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	};

	const handleMarketChange = (e) => {
		const marketId = e.target.value;
		setSelectedMarketId(marketId);
		if (marketId) {
			fetchVendorList(marketId);
		}
	};

	const handleApprove = async (dateId) => {
		try {
			setLoading(true);
			const response = await approveVendorDate(dateId);
			if (!response.error) {
				fetchVendorList(selectedMarketId);
				setError(null);
			} else {
				setError("Failed to approve vendor");
			}
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	};

	const handleReject = async (dateId) => {
		try {
			setLoading(true);
			const response = await rejectVendorDate(dateId);
			if (!response.error) {
				fetchVendorList(selectedMarketId);
				setError(null);
			} else {
				setError("Failed to reject vendor");
			}
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	};
	const handleWaitlist = async (dateId) => {
		try {
			setLoading(true);
			const response = await VendorWaitList(dateId);
			if (!response.error) {
				fetchVendorList(selectedMarketId);
				setError(null);
			} else {
				setError("Failed to reject vendor");
			}
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	};

	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error}</div>;

	const formatDate = (dateString) => {
		if (!dateString) return "N/A";
		const date = new Date(dateString);
		const options = { year: "numeric", month: "long", day: "numeric" };
		return new Intl.DateTimeFormat("en-US", options).format(date);
	};

	const indexOfLastVendor = (currentPage + 1) * appliedVendorPerPage;
	const indexOfFirstVendor = indexOfLastVendor - appliedVendorPerPage;
	const currentVendors = vendorList.slice(indexOfFirstVendor, indexOfLastVendor);

	const handlePageClick = (event) => {
		setCurrentPage(event.selected);
	};

	const handleVendorsPerPageChange = (event) => {
		setAppliedVendorPerPage(Number(event.target.value));
		setCurrentPage(0); // Reset to the first page when changing the number of vendors per page
	};

	const handleViewDetails = (vendorData) => {
		navigate(`/vendor-details`, { state: { vendor: vendorData } });
	};

	return (
		<div>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Applied Vendors</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<a href="#">Applied Vendors </a>
								</li>
							</ul>
						</div>
						<div className="ml-md-auto py-2 py-md-0">
							<select onChange={handleMarketChange} value={selectedMarketId || ""} className="custom-select">
								<option value="" disabled>
									Select a market
								</option>
								{marketList.map((market) => (
									<option key={market._id} value={market._id}>
										{market.name}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="table-responsive">
									<table id="basic-datatables" className="table table-hover">
										<thead>
											<tr>
												<th>Business Name</th>
												<th>Date</th>
												<th>Contact Person</th>
												<th>Stall</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{currentVendors.map((vendorData, index) => (
												<tr key={index}>
													<td>{vendorData.vendor?.businessName || "N/A"}</td>
													<td>{formatDate(vendorData.dateId?.date) || "N/A"}</td>
													<td>{vendorData.vendor?.contactPerson || "N/A"}</td>
													<td>{vendorData.dateId?.totalStall || "N/A"}</td>
													<td>
														<button className="btn btn-success btn-sm mr-1" title="Approved" onClick={() => handleApprove(vendorData?._id)}>
															Approve
														</button>

														<button className="btn btn-info btn-sm mr-1" title="View Detail" onClick={() => handleViewDetails(vendorData)}>
															View Detail
														</button>

														<button className="btn btn-danger btn-sm mr-1" title="Declined" onClick={() => handleReject(vendorData?._id)}>
															Decline
														</button>

														<button className="btn btn-warning btn-sm" title="Waitlist" onClick={() => handleWaitlist(vendorData?._id)} disabled={vendorData?.waitList}>
															{vendorData?.waitList ? "On Waitlist" : "WaitList"}
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>

									<div className="d-flex justify-content-between align-items-center pagination-container">
										<ReactPaginate
											previousLabel={"Previous"}
											nextLabel={"Next"}
											breakLabel={"..."}
											pageCount={Math.ceil(vendorList.length / appliedVendorPerPage)}
											marginPagesDisplayed={2}
											pageRangeDisplayed={5}
											onPageChange={handlePageClick}
											containerClassName={"pagination m-0"}
											pageClassName={"page-item"}
											pageLinkClassName={"page-link"}
											previousClassName={"page-item"}
											previousLinkClassName={"page-link"}
											nextClassName={"page-item"}
											nextLinkClassName={"page-link"}
											breakClassName={"page-item"}
											breakLinkClassName={"page-link"}
											activeClassName={"active"}
										/>
										<div>
											<span>Records Per Page: </span>
											<select value={appliedVendorPerPage} onChange={handleVendorsPerPageChange} className="form-control m-2" style={{ width: "auto", display: "inline-block" }}>
												{appliedVEndorPerPageOptions.map((option) => (
													<option key={option} value={option}>
														Show {option}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppliedVendorList;
