import React, { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactPaginate from "react-paginate";

const InVoiceList = () => {
	document.title = "Eventease360 | Invoices";
	const { getAllMarket, vendorInvoiceList, generateInvoice  ,getStripeKeyApi ,getMarketOwnerBankDetail} = useApiAxios();
	const { userData } = useContext(Context);
	const [marketList, setMarketList] = useState([]);
	const [attendanceData, setAttendanceData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [selectedMarketId, setSelectedMarketId] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [selectedVendors, setSelectedVendors] = useState(new Set());
	const [visibleDates, setVisibleDates] = useState(new Set());
	const [selectedDates, setSelectedDates] = useState({});

	const [currentPage, setCurrentPage] = useState(0);
	const [invoicePerPage, setInvoicePerPage] = useState(10);
	const invoicePerPageOptions = [5, 10, 20, 50];

	useEffect(() => {
		const fetchMarketList = async () => {
			try {
				const response = await getAllMarket();
				setMarketList(response.data.data);
				setLoading(false);
			} catch (err) {
				setError(err.message);
				setLoading(false);
			}
		};

		fetchMarketList();
	}, []);

	useEffect(() => {
		if (selectedMarketId && startDate && endDate) {
			const adjustedStartDate = new Date(startDate);
			adjustedStartDate.setHours(0, 0, 0, 0); // Set to the beginning of the day

			const adjustedEndDate = new Date(endDate);
			adjustedEndDate.setHours(23, 59, 59, 999); // Set to the end of the day

			const fetchAttendanceData = async () => {
				try {
					const response = await vendorInvoiceList(selectedMarketId, {
						startDate: adjustedStartDate,
						endDate: adjustedEndDate,
					});
					setAttendanceData(response.data.data);
					console.warn("data of the venodr need to send the invoice", response.data);
					setSelectedDates(
						response.data.data.reduce((acc, entry) => {
							acc[entry._id] = new Set();
							return acc;
						}, {})
					);
				} catch (err) {
					setError(err.message);
				}
			};

			fetchAttendanceData();
		}
	}, [selectedMarketId, startDate, endDate]);

	const handleMarketChange = (e) => {
		setSelectedMarketId(e.target.value);
		setStartDate(null);
		setEndDate(null);
		setAttendanceData([]);
	};

	const handleDateChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);

		// Close the date picker once the range is selected
		if (start && end) {
			setTimeout(() => document.activeElement.blur(), 0);
		}
	};

	const handleCheckboxChange = (vendorId) => {
		const updatedSelectedVendors = new Set(selectedVendors);
		const updatedSelectedDates = { ...selectedDates };

		if (updatedSelectedVendors.has(vendorId)) {
			updatedSelectedVendors.delete(vendorId); // Deselect vendor
			delete updatedSelectedDates[vendorId]; // Deselect all dates for this vendor
		} else {
			updatedSelectedVendors.add(vendorId); // Select vendor
			const vendorEntry = attendanceData.find((entry) => entry._id === vendorId);
			// Select all dates for the vendor
			updatedSelectedDates[vendorId] = new Set(vendorEntry.applyDate.map((applyDate) => applyDate._id));
		}

		setSelectedVendors(updatedSelectedVendors);
		setSelectedDates(updatedSelectedDates);
	};

	const handleDateCheckboxChange = (vendorId, dateId) => {
		const updatedSelectedDates = { ...selectedDates };
		const vendorDates = updatedSelectedDates[vendorId] || new Set();

		if (vendorDates.has(dateId)) {
			vendorDates.delete(dateId); // Deselect date
		} else {
			vendorDates.add(dateId); // Select date
		}

		updatedSelectedDates[vendorId] = vendorDates;
		setSelectedDates(updatedSelectedDates);

		// If any date is selected, check the vendor checkbox
		const vendorEntry = attendanceData.find((entry) => entry._id === vendorId);
		if (vendorDates.size > 0) {
			// At least one date is selected, so check the vendor checkbox
			setSelectedVendors((prev) => new Set(prev.add(vendorId)));
		} else {
			// No dates are selected, so uncheck the vendor checkbox
			setSelectedVendors((prev) => {
				const updatedVendors = new Set(prev);
				updatedVendors.delete(vendorId);
				return updatedVendors;
			});
		}
	};

	const handleGenerateAllInvoices = async () => {
		try {
		  // Check if either stripe key or bank details are available
		  const stripeResponse = await getStripeKeyApi();
		  const bankDetailResponse = await getMarketOwnerBankDetail(userData._id);
		  console.warn("SSSSSS",bankDetailResponse)
	  
		  // Check if stripeAccountId is null or undefined
		  const isStripeValid = stripeResponse?.data?.data?.stripeAccountId;
		  // Check if both bankName and accountNumber are valid
		  const isBankDetailsValid = bankDetailResponse?.data?.data?.bankName && bankDetailResponse?.data?.data?.accountNumber;
	  
		  // If neither of the details is valid, show an alert
		  if (!isStripeValid && !isBankDetailsValid) {
			alert("Please add bank details or a Stripe account before generating invoices.");
			return; // Stop the function if both are missing
		  }
	  
		  // Proceed with generating invoices if either of the required details is available
		  const selectedData = attendanceData.filter((entry) => selectedVendors.has(entry._id));
	  
		  const invoiceData = selectedData.map((vendor) => {
			return {
			  vendorId: vendor._id,
			  price: vendor.price.unit_amount,
			  dates: Array.from(selectedDates[vendor._id] || []),
			};
		  });
	  
		  // Call the API to generate invoices
		  await generateInvoice({ data: invoiceData });
		  alert("Invoices have been generated successfully.");
	  
		  // Reset state after generating invoices
		  setSelectedMarketId("");
		  setStartDate("");
		  setEndDate("");
		} catch (err) {
		  setError(err.message);
		  alert("Failed to generate invoices.");
		}
	  };
	  
	  

	const toggleDatesVisibility = (vendorId) => {
		const updatedVisibleDates = new Set(visibleDates);

		if (updatedVisibleDates.has(vendorId)) {
			updatedVisibleDates.delete(vendorId);
		} else {
			updatedVisibleDates.add(vendorId);
		}

		setVisibleDates(updatedVisibleDates);
	};

	const indexOfLastVendor = (currentPage + 1) * invoicePerPage;
	const indexOfFirstVendor = indexOfLastVendor - invoicePerPage;
	const currentVendors = attendanceData.slice(indexOfFirstVendor, indexOfLastVendor);

	const handlePageClick = (event) => {
		setCurrentPage(event.selected);
	};

	const handleVendorsPerPageChange = (event) => {
		setInvoicePerPage(Number(event.target.value));
		setCurrentPage(0); // Reset to the first page when changing the number of vendors per page
	};
	const doSomthistoWork = (price, item) => {
		const nextShapes = attendanceData.map((shape) => {
			if (shape._id === item._id) {
				// No change
				shape.price.unit_amount = price;
				return shape;
			} else {
				// Return a new circle 50px below
				return shape;
			}
		});
		// Re-render with the new array
		setAttendanceData(nextShapes);
		// setAttendanceData((pre))
	};

	return (
		<>
			<style>{`
        .dates-accordion {
          padding: 10px;
          border: 1px solid #ddd;
          background-color: #f2f2f2;
          border-radius: 5px;
        }
      `}</style>
			<div className="panel-header">
				<div className="page-inner pb-5">
					<div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
						<div>
							<h2 className="pb-2 fw-bold">Invoices</h2>
							<ul className="breadcrumbs">
								<li className="nav-home">
									<Link to="/">
										<i className="flaticon-home" />
									</Link>
								</li>
								<li className="separator">
									<i className="flaticon-right-arrow" />
								</li>
								<li className="nav-item">
									<a href="#">Invoices</a>
								</li>
							</ul>
						</div>
						<div className="ml-md-auto py-2 py-md-0">
							<div className="row">
								<div className={selectedMarketId ? "col-6" : "col-12"}>
									<select onChange={handleMarketChange} value={selectedMarketId || ""} className="custom-select">
										<option value="" disabled>
											Select a market
										</option>
										{marketList.map((market) => (
											<option key={market._id} value={market._id}>
												{market.name}
											</option>
										))}
									</select>
								</div>

								{selectedMarketId && (
									<div className="col-6">
										<DatePicker
											className="form-control"
											selected={startDate}
											onChange={handleDateChange}
											startDate={startDate}
											endDate={endDate}
											selectsRange
											inline={false} // Change to false if you want it as a pop-up
											placeholderText="Select date range"
											dateFormat="dd/MM/yyyy" // Set the date format here
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="page-inner mt--5">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								{attendanceData.length > 0 ? (
									<>
										<button className="btn btn-primary btn-sm mb-2" style={{ float: "right", marginLeft: "auto" }} onClick={() => handleGenerateAllInvoices()}>
											{" "}
											Send Invoice
										</button>

										<div className="table-responsive">
											<table className="table table-hover">
												<thead>
													<tr>
														<th>Select</th>
														<th>Vendor Name</th>
														<th>Category</th>
														<th>Price</th>
														<th>Contact Number</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{attendanceData.map((entry) => (
														<React.Fragment key={entry._id}>
															<tr>
																<td>
																	<input type="checkbox" checked={selectedVendors.has(entry._id)} onChange={() => handleCheckboxChange(entry._id)} />
																</td>
																<td>{entry.businessName}</td>
																<td>{entry.categories.name}</td>
																<td>
																	{" "}
																	<input type="number" value={entry.price.unit_amount} onChange={(e) => doSomthistoWork(e.target.value, entry)} />
																</td>

																<td>{entry.contactNumber}</td>
																<td>
																	<button className="btn btn-primary btn-sm" onClick={() => toggleDatesVisibility(entry._id)}>
																		{visibleDates.has(entry._id) ? "Hide Dates" : "View Dates"}
																	</button>
																</td>
															</tr>
															{visibleDates.has(entry._id) && (
																<tr>
																	<td colSpan="6">
																		<div className="dates-accordion">
																			<h4>Dates:</h4>
																			<ul>
																				{entry.applyDate.map((applyDate) => (
																					<li key={applyDate._id}>
																						<input
																							type="checkbox"
																							checked={selectedDates[entry._id]?.has(applyDate._id) || false}
																							onChange={() => handleDateCheckboxChange(entry._id, applyDate._id)}
																						/>
																						{new Date(applyDate.dateId.date).toLocaleDateString()}
																					</li>
																				))}
																			</ul>
																		</div>
																	</td>
																</tr>
															)}
														</React.Fragment>
													))}
												</tbody>
											</table>

											<div className="d-flex justify-content-between align-items-center pagination-container">
												<ReactPaginate
													previousLabel={"Previous"}
													nextLabel={"Next"}
													breakLabel={"..."}
													pageCount={Math.ceil(attendanceData.length / invoicePerPageOptions)}
													marginPagesDisplayed={2}
													pageRangeDisplayed={5}
													onPageChange={handlePageClick}
													containerClassName={"pagination m-0"}
													pageClassName={"page-item"}
													pageLinkClassName={"page-link"}
													previousClassName={"page-item"}
													previousLinkClassName={"page-link"}
													nextClassName={"page-item"}
													nextLinkClassName={"page-link"}
													breakClassName={"page-item"}
													breakLinkClassName={"page-link"}
													activeClassName={"active"}
												/>
												<div>
													<span>Records Per Page: </span>

													<select value={invoicePerPage} onChange={handleVendorsPerPageChange} className="form-control m-2" style={{ width: "auto", display: "inline-block" }}>
														{invoicePerPageOptions.map((option) => (
															<option key={option} value={option}>
																Show {option}
															</option>
														))}
													</select>
												</div>
											</div>
										</div>
									</>
								) : selectedMarketId && startDate && endDate ? (
									<p className="text-center m-0">No vendor data available for the selected date range.</p>
								) : (
									<p className="text-center m-0">Please select a market and date range to view vendor data.</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default InVoiceList;
