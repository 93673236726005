import { Outlet } from "react-router-dom";
import { useState } from "react";
import Header from "../header/Header";
import SideBar from "../sidebar/SideBar";
import Footer from "../footer/Footer";
import "./backend.css"; // Import the CSS file

const Backend = () => {
	const [sideBarEnable, setSideBarEnable] = useState(true);

	return (
		<div data-sidebar="light" data-layout-mode="light" className={sideBarEnable ? "sidebar-enable" : "vertical-collapsed"}>
			<div id="layout-wrapper">
				<Header setSideBarEnable={setSideBarEnable} />
				<SideBar isCollapsed={!sideBarEnable} /> {/* Pass the state to SideBar */}
				<div className={`container-fluid ${sideBarEnable ? "expanded" : "collapsed"}`}>
					<div className={`wrapper`}>
						<div className={`main-panel ${sideBarEnable ? "expanded" : "collapsed"}`}>
							<div className="content">
								<Outlet />
							</div>
							<Footer />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Backend;
